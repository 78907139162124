import React, { useEffect, useState } from 'react';
import {
  AppTable,
  IColumn,
  IMultiSelectionConfig,
} from '../../../components/grid/app-table';
import {
  IOrderDetails,
  IOrderItem,
  Reasons,
} from '../../../models/order-models';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';
import Counter from '../../../components/counter';
import { DropdownList } from 'react-widgets';
import { orderProcessingColumns } from './order-items-grid';
import { isNullOrEmpty, translate } from '../../../utils/text-utils';
import { CurrencyFormatter } from '../../../components/currency-formatter';

export const CancelOrderItemsGrid = (props: {
  data: IOrderDetails;
  status: string;
  isEdit?: boolean;
  onItemsChanged?: any;
  reasons?: Reasons[];
  selectedReason?: Reasons;
  setSelectedReason: (data: Reasons) => void;
}) => {
  const isEdit = props.isEdit ?? false;

  const assignInitialApprovedQty = () => {
    const clone = { ...props.data };
    clone?.items?.forEach((p) => {
      p.approvedQty = parseInt(p.requestedQty, 10);
    });
    return clone;
  };

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  useEffect(() => {
    setOrder(assignInitialApprovedQty());
  }, []);

  const [order, setOrder] = useState(props.data);
  useEffect(() => {
    const key = getMultiSelectConfig()?.key;
    if (key && props.onItemsChanged) {
      const selectedOrderItems = order?.items.filter(
        (p) => selectedItems.indexOf((p as any)[key]) > -1
      );

      props.onItemsChanged(selectedOrderItems);
    }
  }, [selectedItems, order]);

  if (props?.data?.items?.length === 0) {
    return <></>;
  }
  let columns: IColumn[] = [
    orderProcessingColumns().productOptionName,
    orderProcessingColumns().optionSKU,
    orderProcessingColumns().requestedQty,
    orderProcessingColumns().notAvailable,
    orderProcessingColumns().immediatelyAvailable,
    orderProcessingColumns().backOrderQty,
    orderProcessingColumns().orderItemQuantity,
    orderProcessingColumns().orderItemPrice,
    orderProcessingColumns().orderItemSubtotal,
    // orderProcessingColumns().itemDiscountValue,
    // orderProcessingColumns().amtExcludeVat,
    orderProcessingColumns().vat,
    orderProcessingColumns().itemTotalAmount,
  ];
  if (!isEdit) {
    columns = columns.filter((p) => p.key !== 'approvedQty');
    columns = columns.filter((p) => p.key !== 'rejectReason');
  }

  if (isEdit) {
    columns = columns.filter((p) => p.key !== 'orderItemSubtotal');
    columns = columns.filter((p) => p.key !== 'amtExcludeVat');
    columns = columns.filter((p) => p.key !== 'itemTaxValue');
    columns = columns.filter((p) => p.key !== 'itemTotalAmount');
  }

  const getMultiSelectConfig = () => {
    const multiSelectConfig: IMultiSelectionConfig = {
      key: 'orderItemID',
      onChange: (items: string[]) => {
        setSelectedItems(items);
      },
    };
    if (isEdit) {
      return multiSelectConfig;
    }
    return undefined;
  };

  return (
    <>
      {order?.items?.length > 0 && (
        <>
          <div className="table-block grid-table">
            <AppTable
              // multiSelectionConfig={getMultiSelectConfig()}
              onRowClick={(data: IOrderItem) => {
                //  history.push(`order-detail/${data.masterOrderID}`);
              }}
              data={order.items ?? []}
              columns={columns}
              vatPercent={order?.taxPercent}
            ></AppTable>
          </div>
          <div className="py-2 bg-gray border-left border-bottom border-right">
            <div className="col-lg-3 col-md-4 col-sm-6 col- select-reason py-1">
              {isEdit ? (
                <DropdownList
                  disabled={!props.isEdit}
                  data={props.reasons}
                  textField={'value'}
                  valueField={'id'}
                  placeholder={translate('customerRequest.selectReason')}
                  value={props.selectedReason}
                  onChange={(val: Reasons) => {
                    props.setSelectedReason(val);
                  }}
                />
              ) : (
                <div className="font-weight-medium py-2">
                  {translate('customerRequest.reason')}:{' '}
                  {props.selectedReason?.value ?? ''}
                </div>
              )}
            </div>
          </div>
          {!isEdit && <PriceSummary data={order}></PriceSummary>}
        </>
      )}
    </>
  );
};

export const PriceSummary = (props: { data: IOrderDetails }) => {
  const order = props.data;
  return (
    <div className="row justify-content-end mt-3">
      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 font-weight-medium">
        <SummaryItem
          label={translate('cart.subTotal')}
          amount={order?.orderSubtotal ?? ''}
        ></SummaryItem>
        <SummaryItem
          label={translate('cart.shippingFee')}
          amount={order?.shippingAmount ?? ''}
        ></SummaryItem>
        <SummaryItem
          label={translate('orders.discount')}
          amount={order?.orderPromoDiscountAmount ?? ''}
        ></SummaryItem>
        <SummaryItem
          label={showTaxPercent(order?.taxPercent)}
          amount={order?.orderTaxAmount ?? ''}
        ></SummaryItem>

        <div className="row pb-2 mt-3 text-right font-weight-bold">
          <div className="col-6">{translate('orders.grandTotal')}</div>
          <div className="col-6">
            <CurrencyFormatter quantity={order?.orderTotalAmount} />
          </div>
        </div>
      </div>
    </div>
  );
};
export const SummaryItem = (props: { label: string; amount: string }) => {
  let amount = 0;
  try {
    amount = parseFloat(props.amount);
  } catch (err) {
    amount = 0;
  }
  if (amount === 0) {
    return <></>;
  }
  return (
    <div className="row gutter-8 pb-1 font-md text-right">
      <div className="col-6">{props.label}</div>
      <div className="col-6">
        <CurrencyFormatter quantity={amount.toFixed(2)} />
      </div>
    </div>
  );
};

export const showTaxPercent = (data: any) => {
  const show = true;
  return `${translate('cart.VAT')} ${
    !show ? '' : `${(data ?? 0)?.toString()?.toNumber()} %`
  }`;
};
