// import MyOrders from "../orders";
// import Checkout from "../orders/Checkout";

export enum RouteKeys {
  Login = '/auth/login',
  PasswordForm = '/auth/password',
  SetNewPassword = '/auth/newpassword',
  SignUp = '/auth/signup',
  ForgotPassword = '/auth/forgot-password',
  ChangePassword = '/change-password',
  Home = '/',
  Dashboard = '/dashboard',
  NotFound = '/page-not-found',
  Categories = '/categories',
  SearchResult = '/search',
  Parts = '/parts',
  MyAccount = '/my-account',
  ConfirmCart = '/confirm-cart',
  ConfirmQuote = '/confirm-quote',
  StaffPersonalInfo = '/staff-personal-info',
  Quotation = '/quotation',
  ViewQuotations = '/view-quotations',
  QuotationDetails = '/QuotationDetails',
  ConfirmQuotationOrder = '/confirm-quotation-order',
  catalogue = '/catalogue',
  Orders = '/orders',
  ViewOrders = '/orders',
  Higher = '/higher',
  MyOrders = '/MyOrders',
  MyOrdersDetails = '/MyOrdersDetails',
  Checkout = '/checkout',
  OrderDetail = '/order-detail',
  GeneralSettings = '/general-settings',
  CustomerDetails = '/customer-details',
  Terms = '/terms-of-use',
  StaffMember = '/staff-member',
  ManageSalesPerson = '/manage-sales-persons',
  DuePayments = '/due-payments',
  DuePaymentDetails = '/due-payment-details',
  ToDo = '/to-do',
  PaymentHistory = '/payment-history',
  CreditNote = '/creditnote',

  DueCustomers = '/due-payment-customers',
  Faq = '/faq',
  BulkOrder = '/bulk-order',
  Customers = '/customers',
  ScheduleManagement = '/schedule-management',
  CustomerRequests = '/customer-requests',
  ViewCustomerRequests = '/customer-requests',
  Schedules = '/schedules',
  NewVisitationRequests = '/visitation-requests',
  VisitationRequestDetails = '/visitation-requests-details',
  CustomerRequestDetails = '/customer-request-details',
  SalespersonAccountSettings = '/account-settings',
  Returns = '/returns',
  ViewReturns = '/view-returns',
  ReturnDetails = '/return-details',
  CreditRequests = '/credit-requests',
  CreditDetails = '/credit-request-details',
  CreditlineExtensionRequestScreen = '/creditline-extension-request',
  CreditlineExtensionRequestDetails = '/creditline-extension-request-details',
  PermissionNeeded = '/permission-needed',
  Offers = '/offers',

  Reports = '/Reports',
  Sales = '/sales',
  ReportsPayment = '/reports-payment',
  CreditExtensionDetail = '/credit-extension-detail',
  PaymentDueDetail = '/payment-due-detail',
  PaymentCollectionDetail = '/payment-collection-detail',
  ReportsCustomers = '/reports-customers',
  // CustomerUsage = '/customer-usage-detail',
  PartsGroupCustomer = '/parts-group-customer-detail',
  ProductsDeletedList = '/products-deleted-list',
  CustomerConversionDetails = '/conversion-rate-detail',
  CustomerSalesConversionDetails = '/conversion-rate-sale-detail',
  CustomerConversionOrderDetail = '/conversion-rate-order-detail',
  CustomerSalesConversionOrderDetail = '/conversion-rate-sale-order-detail',
  CustomerUsageDetailPage = '/customer-usage-detail',
  CustomerProductDeletedCart = '/customer-deleted-cart-products',
  SalesPersonReport = '/sales-person-report',
  StatusReportDetail = '/status-report-detail',
  StatusOrderDetail = '/status-customer-order',
  StatusCustomerOrderDetail = '/status-customer-order-detail',
  CustomerGroupDetail = '/customer-buisness-type',
  YearPerformanceDetails = '/year-performance-detail',
  VisitReportDetail = '/visit-report-details',
  IframeGateway = '/payment-gateway',
  IframeGatewayDuePayment = '/due-payments/payment-gateway',
  PaymentGatewayStatus = '/payment-status',
  DuePaymentStatus = '/due-payments/payment-status',
  SalesPersonActivityTrack = '/sales-person/sales-person-activity-track',
  SalesPersonActivityView = '/sales-person/sales-person-activity-view',
  CustomerActivityView = '/sales-person/customer-activity-view',
  FrequentlyBoughtList = '/frequently-bought-orders-list',
  SavedSearch = '/saved-search',
  SaveForLater = '/save-for-later',
  SaveLaterDetail = '/save-later-detail',
  PrivacyPolicy = '/privacy-policy',
}
