import * as React from 'react';
import { IListFilterProps } from './props';
import { FilterValue } from '../../core/api/models/saved-filters';
import { translate } from '../../utils/text-utils';
import { decodeHtmlEntities } from '../../utils/common';

interface ISingleSelectFilterProps<T = any> extends IListFilterProps<T> {
  allowSearch?: boolean;
  options: any[];
  labelField: string;
  valueField: string;
  value?: FilterValue;
  keyPrefix?: string;
}

const SingleSelectFilter: React.FunctionComponent<ISingleSelectFilterProps> = (
  props
) => {
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState<FilterValue | undefined>(
    props.value || undefined
  );

  React.useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  const onSelect = (value: FilterValue, sel: boolean) => {
    if (sel) {
      setSelected(value);
    } else {
      setSelected(undefined);
    }
  };

  const onApply = () => {
    setSearch('');
    props.applyFilter?.(selected);
  };

  const onClose = () => {
    setSearch('');
    props.onClose?.();
  };

  const filteredOptions = search
    ? props.options?.filter((op) =>
        op[props.labelField]?.match(new RegExp(search, 'i'))
      )
    : props.options;

  return (
    <>
      <div className="pb-3">
        <div className="d-flex align-items-center justify-content-between bg-body p-3">
          <h6 className="m-0 font-md text-gray-700">
            {translate('common.filterBy')} {props.label}
          </h6>
          <span
            className="cursor-pointer d-inline-flex text-center rounded-circle sub-dropdown-back ml-3"
            onClick={onClose}
          >
            <i className="icon-close font-xs text-gray-700"></i>
          </span>
        </div>
        {props.allowSearch && (
          <div className="search">
            <div className="input-group border-bottom">
              <input
                type="text"
                className="form-control border-0 pr-0"
                placeholder={translate('Home.Search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append">
                <span className="form-control-search btn btn-link border-0 bg-transparent px-3 py-0" />
              </div>
            </div>
          </div>
        )}
        <ul className="list-unstyled p-2 m-0">
          {filteredOptions.map((option, index) => {
            const label = option[props.labelField];
            const value = option[props.valueField];

            return (
              <li key={index} className="py-2">
                <input
                  type="checkbox"
                  id={value.toString() + props.keyPrefix ?? ''}
                  onChange={(e) => onSelect({ label, value }, e.target.checked)}
                  checked={selected?.value === value}
                />
                <label
                  htmlFor={value.toString() + props.keyPrefix ?? ''}
                  className="font-sm font-weight-semibold"
                >
                  {decodeHtmlEntities(label)}
                </label>
              </li>
            );
          })}
        </ul>
        <div className="px-3 text-right">
          <button
            type="button"
            className="btn btn-primary py-2 px-4 font-weight-semibold"
            onClick={onApply}
          >
            <span className="px-2">{translate('orders.Apply')}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SingleSelectFilter;
