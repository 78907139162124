import React, { useState } from 'react';

import CopyClipboard from '../../../components/copy-to-clipboard';
import '../../../containers/search-tab/search-box.scss';
import { CartItem } from '../../../models/order-models';
import { CartCounter } from './cart-counter';
import { useCart } from '../../../core/hooks';
import { useConfirmationDialog } from '../../../components/confirm-popup/confirm-popup';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../../utils/text-utils';
import Currency from 'react-currency-formatter';
import { CurrencyFormatter } from '../../../components/currency-formatter';

export const CartItemComponent = (props: {
  item: CartItem;
  cartId: string;
  isViewOnly: boolean;
  onSelectionChange?: () => void;
  isChecked?: boolean;
  canEditCart?: boolean;
  cartUserStatus?: string;
}) => {
  const { getConfirmation } = useConfirmationDialog();
  const cartID = props.cartId;
  const cartItem = props.item;
  const [loading, setLoading] = useState(false);
  const { removeFromCart } = useCart();
  const removeItem = async () => {
    const granded = await getConfirmation({
      title: translate('common.areYouSureToDeleteThisItem'),
    });
    if (granded) {
      try {
        setLoading(true);
        await removeFromCart(cartItem, cartID);
      } catch (err) {
        toastr.error(translate('common.error'), 'Somethign went wrong');
      } finally {
        setLoading(false);
      }
    }
  };

  const getAvailabilityText = () => {
    let text = '';
    text += `${translate('orders.immediatelyAvailable')}: ${
      cartItem.branchStockQty
    }  `;
    text += ' / ';
    text += `${translate('orders.availableInDays')}: ${
      cartItem.centralStockQty
    }`;
    return text.trim();
  };
  return (
    <>
      <div className="cartItem border-bottom d-block">
        {/* <div
          className="cartItem__remove position-absolute cursor-pointer font-md zIndex-9"
          onClick={() => {
            removeItem();
          }}
        >
          <i className="icon-delete"></i>
        </div> */}
        <div
          className={`row gutter-10 ${props?.canEditCart ? 'py-px my-1' : ''}`}
        >
          <div className="col minw-0">
            <div
              onClick={() => {
                if (
                  !props?.canEditCart &&
                  props?.cartUserStatus !== 'Inactive'
                ) {
                  props.onSelectionChange?.();
                }
              }}
              className="font-weight-semibold cursor-pointer font-md"
            >
              {!props?.canEditCart && (
                <input
                  type="checkbox"
                  checked={props.isChecked}
                  id={cartItem.cartItemID}
                />
              )}
              <label className="m-0">
                {cartItem?.itemDetails?.productTitle ?? cartItem?.productTitle}
              </label>
            </div>
            <div
              className={`font-xs text-gray-600 w-100 pt-1 ${
                !props?.canEditCart ? 'px-4' : ''
              }`}
            >
              {Number(cartItem?.productOptionQuantity) > 0 ? (
                <div className="text-green font-xxs text-uppercase font-weight-medium">
                  {cartItem?.productOptionQuantity}{' '}
                  {translate('common.inStock')}
                </div>
              ) : (
                <div className="text-danger font-xxs text-uppercase font-weight-medium">
                  {translate('common.outOfStock')}
                </div>
              )}
            </div>
            <div
              className={`font-xs text-gray-600 w-100 pt-1 ${
                !props?.canEditCart ? 'px-4' : ''
              }`}
            >
              <CopyClipboard value={cartItem.optionSKU}>
                <div className="cursor-pointer copy text-nowrap d-inline-flex align-items-center copy--sm">
                  <span className="pt-px">{cartItem.optionSKU}</span>{' '}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="9"
                      viewBox="0 0 8 9"
                      fill="none"
                      stroke="#808080"
                      className="cursor-pointer position-relative"
                    >
                      <line x1="2.5" y1="2" x2="2.5" y2="9"></line>
                      <line x1="0.5" y1="1" x2="0.5" y2="7"></line>
                      <line x1="7.5" y1="2" x2="7.5" y2="9"></line>
                      <line x1="2" y1="2.5" x2="8" y2="2.5"></line>
                      <line y1="0.5" x2="6" y2="0.5"></line>
                      <line x1="2" y1="8.5" x2="8" y2="8.5"></line>
                    </svg>
                  </span>
                </div>
              </CopyClipboard>
            </div>
            {(cartItem?.fdNumber || cartItem?.mcNumber) && (
              <div
                className={`d-sm-flex align-items-center pt-2 font-sm font-weight-medium ${
                  !props?.canEditCart ? 'px-4' : ''
                }`}
              >
                {cartItem?.fdNumber && (
                  <div className="mr-2 pr-px">
                    FD :{' '}
                    <span className="text-gray-600">{cartItem?.fdNumber}</span>
                  </div>
                )}
                {cartItem?.mcNumber && (
                  <div>
                    MC :{' '}
                    <span className="text-gray-600">{cartItem?.mcNumber}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="col-auto text-right">
            <div className="font-md font-weight-medium line-height-normal">
              <CurrencyFormatter quantity={cartItem?.orderItemSubtotal} />
            </div>
            {cartItem?.savedPriceVal?.toNumber() > 0 &&
              cartItem?.customerPrice?.toNumber() === 0 && (
                <div className="font-xs text-gray-600 w-100">
                  {translate('cart.savedSAR')} {cartItem?.savedPriceVal}
                </div>
              )}
            {/* {cartItem?.customerPrice?.toNumber() > 0 && (
              <div>
                <del className="font-xs text-gray-600 font-weight-normal">
                  <CurrencyFormatter
                    quantity={
                      Number(cartItem?.actualPrice) * cartItem.orderItemQuantity
                    }
                  />
                </del>
              </div>
            )} */}
            <div className="font-xxs font-weight-medium text-muted w-100 py-px">
              <CurrencyFormatter
                quantity={
                  cartItem?.offerPrice?.toNumber() ??
                  cartItem?.itemDetails?.unitPrice ??
                  cartItem?.orderItemPrice?.toNumber() ??
                  0
                }
              />{' '}
              {translate('cart.EACH')}
            </div>
            {!props.isViewOnly && (
              <div className="tooltip position-relative sm d-flex justify-content-end mt-2">
                <CartCounter
                  commaSeparation={true}
                  cartItem={cartItem}
                  cartUserStatus={props?.cartUserStatus}
                ></CartCounter>
                {props?.cartUserStatus === 'Inactive' && (
                  <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                    <p className="font-weight-medium my-1 text-gray-900 font-sm">
                      {translate('common.inactive_user_message')}
                    </p>
                  </span>
                )}
              </div>
            )}
            {props.isViewOnly && (
              <div className="font-xs font-weight-medium line-height">
                <span className="text-muted">{translate('common.qty')}:</span>{' '}
                {cartItem?.orderItemQuantity ?? 0}
              </div>
            )}
          </div>
        </div>
        {/* {getAvailabilityText() !== '' && (
          <div className="font-xs text-gray-600 w-100 mt-2">
            <div className="bg-body rounded px-lg-3 px-2 py-1 line-height-normal d-inline-block">
              {getAvailabilityText()}
            </div>
          </div>
        )} */}
      </div>
      {loading && (
        <div className="numberField__loading">
          <img
            src={require('../../../components/counter/loading.gif')}
            alt="loading..."
          />
        </div>
      )}
    </>
  );
};
