import React, { Props, useState } from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import { DropdownList } from 'react-widgets';
import api from '../../../../api';
import Button from '../../../../components/button';
import { TextArea } from '../../../../components/form-group';
import Modal from '../../../../components/modal';
import { ICartDeleteReason } from '../../../../models/order-models';
import { translate } from '../../../../utils/text-utils';
import { useSelector } from 'react-redux';
import { State } from '../../../../core/store';
import { ApiResponse } from '../../../../core/api/models';

export const DeleteConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onDismiss,
  hideCancel,
}: any) => {
  const [reason, setReason] = useState();

  const authState = useSelector((state: State) => state.authUser);
  const {
    data: reasonList,
    loaded,
    reload, } = useDataLoader<ICartDeleteReason[]>
    (() =>
      api.model.getDeleteReasons(authState?.userToken, authState?.salesPersonToken)
    );


  const [showError, setShowError] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const submit = () => {
    if (reason == null) {
      setShowError(true);
      return;
    }
    if (reason === 'other' && otherReason.trim() === '') {
      setShowError(true);
      return;
    }
    onConfirm(reason, reason === 'other' ? otherReason : '');
  };
  return (
    <Modal
      isOpen={open}
      size="md"
      onClose={() => {
        onDismiss();
        setReason(null);
        return true;
      }}
    >
      <div className="delete-confirm-popup pt-4">
        <div className="Verification__content h6 mb-4">{title}</div>
        <label htmlFor="" className="mb-1 textField__label">
          {translate('common.reasonForDeleting')}
        </label>
        <div className="mb-4">
          <DropdownList
            containerClassName="sn"
            placeholder={translate('common.select')}
            data={reasonList}
            value={reason}
            valueField="reasonKey"
            textField="name"
            onChange={(val) => {
              setShowError(false);
              setReason(val.reasonKey);
            }}
          ></DropdownList>
          {showError && reason == null && (
            <span className="textField__validation">
              {translate('cart.pleaseSelectReason')}
            </span>
          )}
          {reason === 'other' && (
            <div className="mt-2">
              <TextArea
                default={otherReason}
                label={''}
                onChange={(val) => {
                  setOtherReason(val);
                  setShowError(false);
                }}
                placeholder={''}
                size="sm"
              />
              {showError && otherReason.trim() === '' && (
                <span className="textField__validation">
                  {translate('cart.pleaseSpecifyReason')}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="row gutter-5 justify-content-center pt-2">
          <div className="col-sm-4 col-12">
            <Button
              size="md"
              className=" Button__primary-outline Button--radius"
              expand
              onClick={() => {
                onDismiss();
                setReason(null);
              }}
            >
              {translate('common.cancel')}
            </Button>
          </div>
          <div className="col-sm-8 col-12 mt-sm-0 mt-2">
            <Button
              size="md"
              className=" Button__primary Button--radius"
              expand
              onClick={() => {
                submit();
              }}
            >
              {translate('common.confirmAndDeleteItem')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
