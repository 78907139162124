import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
// @ts-ignore
import { useCart } from '../../../core/hooks/useCart';
import empty from './empty.png';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
import { ICartData } from '../../../models/order-models';
import { useTranslation } from 'react-i18next';
import { translate } from '../../../utils/text-utils';
import { CurrencyFormatter } from '../../../components/currency-formatter';
import { DeleteConfirmationDialog } from './components/delete-confirmation';
import { toastr } from 'react-redux-toastr';
import { FullPageLoader } from '../../../components/loader';
import Modal from '../../../components/modal';
import { TextInput } from '../../../components/form-group';
import api from '../../../api';
import { useAuth } from '../../../core/hooks';
import { UserPermissionTypes, UserType } from '../../../core/api/models';
import OTPInput from '../../../components/form-group/otp-input/OtpInput';
import { useDataLoader } from 'react-remote-data-hooks';
import { useSelector } from 'react-redux';
import { State } from '../../../core/store';
import { InActiveUserPopup } from '../../../components/inactive-user-popup';

export const CartPriceDetails = (props: {
  cart?: ICartData;
  disableSubmit?: boolean;
  showHeader?: boolean;
  disableCounter?: boolean;
  canEditCart?: boolean;
}) => {
  const showHeader = props.showHeader ?? false;
  const [showConfirm, setShowConfirm] = useState(false);
  const authState = useSelector((state: State) => state.authUser);
  const [showSaveLater, setShowSaveLater] = useState(false);
  const [isClearCart, setIsClearCart] = useState(false);
  const [isHoliday, setIsHoliday] = useState(false);
  const cart = props.cart;
  const [saveLaterTitle, setSaveLaterTitle] = useState(
    cart?.saveLaterName ?? ''
  );
  const [holidayMessage, setHolidayMessage] = useState('');
  const disableCounter = props.disableCounter ?? false;
  const history = useHistory();
  const { hasRole } = useAuth();
  const cartItems = cart?.items ?? [];
  const disableSubmit = props.disableSubmit ?? false;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { t } = useTranslation();
  const { bulkDeleteFromCart, getCart } = useCart();
  const [loading, setLoading] = useState(false);

  const [showOtp, setShowOtp] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState('');

  const [submitOtpLoading, setSubmitOtpLoading] = useState(false);

  const [otpMessage, setOtpMessage] = useState<string>('');

  const [openModal, setOpenModal] = useState<{
    isOpen?: boolean;
    msg?: string;
  }>({ isOpen: false });

  const onClose = () => {
    setOpenModal({});
  };

  if (!!!cart?.items?.length) {
    return (
      <div className="cartBlock__item">
        <div className="cartBlock__itemHead">
          {translate('Home.Your_Cart_is_empty')}
        </div>
        <div className="cartBlock__img">
          <img src={empty} alt="" />
        </div>
      </div>
    );
  }
  const delteItems = async (reason: string, otherReason?: string) => {
    try {
      setLoading(true);
      await bulkDeleteFromCart(
        selectedItems.join(','),
        cart?.cartID,
        reason,
        otherReason,
        isClearCart
      )
        .then(() => {
          setSelectedItems([]);
        })
        .catch(() => {
          toastr.error(
            translate('common.error'),
            translate('toast.somethingWentWrong')
          );
        });
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      setLoading(false);
    }
  };

  const saveToLater = async () => {
    if (saveLaterTitle?.length != 0) {
      try {
        const response: any = await api.model.addSaveToLater({
          cartID: cart?.cartID,
          saveLaterName: saveLaterTitle,
        });
        if (response?.response === 'Success') {
          setShowSaveLater(false);
          setSaveLaterTitle(undefined);
          getCart(() => {
            history.push('/save-for-later');
          });
          toastr.success('Success', `${t('saveLater.save_success')}`);
        } else {
          toastr.error(translate('common.error'), response?.errorMsg);
        }
      } catch (err) {
        toastr.error(
          translate('common.error'),
          translate('toast.somethingWentWrong')
        );
      } finally {
        setShowSaveLater(false);
        setSaveLaterTitle(undefined);
      }
    } else {
      toastr.error('Error', `${t('saveLater.no_title')}`);
    }
  };

  const submitOtp = async () => {
    try {
      setSubmitOtpLoading(true);
      setDisableButton(true);
      const response: any = await api.model.cartGetOtp({
        cartID: cart?.cartID,
      });
      if (response?.response === 'Success') {
        toastr.success('Success', `${t('forgot_password.otp_success')}`);
        setSubmitOtpLoading(false);
        setShowOtp(true);
        setDisableButton(false);
        setOtpMessage(response?.result?.otpHeadText);
      } else {
        setSubmitOtpLoading(false);
        setOtpLoading(false);
        setDisableButton(false);
        toastr.error(t('common.error'), response?.errorMsg);
      }
    } catch (err) {
      setSubmitOtpLoading(false);
      setOtpLoading(false);
      setDisableButton(false);
      toastr.error(t('common.error'), t('common.something_wrong'));
    }
  };

  const verifyOtp = async () => {
    if (otp) {
      try {
        setOtpLoading(true);
        const response: any = await api.model.cartVerifyOtp({
          cartID: cart?.cartID,
          otp: otp,
        });
        if (response?.response === 'Success') {
          setOtpLoading(false);
          setShowOtp(false);
          setOtp(undefined);
          history.push(`${RouteKeys.Checkout}/${cart.cartID}`);
          // toastr.success('Success', `${t('forgot_password.otp_success')}`)
        } else {
          setOtpLoading(false);
          toastr.error(t('common.error'), response?.errorMsg);
        }
      } catch (err) {
        setOtpLoading(false);
        toastr.error(t('common.error'), t('common.something_wrong'));
      }
    } else {
      toastr.error('Error', `${t('forgot_password.otp_required')}`);
    }
  };

  const holidayCheck = async () => {
    try {
      setDisableButton(true);
      const response: any = await api.model.getBusinessHours({
        cartID: cart?.cartID,
      });
      if (response?.response === 'Success' && response?.result) {
        if (
          response?.result?.holidayMsg === ''
        ) {
          if (response?.result?.need_order_otp) {
            submitOtp();
          } else {
            history.push(`${RouteKeys.Checkout}/${cart.cartID}`);
          }
          setDisableButton(false);
        }
      } else if (
        response?.response === 'Failure' &&
        response.inactiveMsg !== '') {
        setOpenModal({
          isOpen: true,
          msg: response?.inactiveMsg,
        });
        setDisableButton(false);
      } else if (
        response?.response === 'Failure' &&
        response.result.holidayMsg !== ''
      ) {
        setIsHoliday(true);
        setHolidayMessage(response.result.holidayMsg);
        setDisableButton(false);
      } else {
        toastr.error(t('common.error'), response?.errorMsg);
      }
    } catch (err) {
      setOtpLoading(false);
      setDisableButton(false);
      toastr.error(t('common.error'), t('common.something_wrong'));
    }
  };

  return (
    <>
      {showHeader && (
        <h6 className="font-weight-bold pb-2">
          {t('cartDetail.priceDetails')}
        </h6>
      )}
      <div>
        <div className="font-md font-weight-medium pb-2 mb-1">
          <div className="row gutter-8 py-px">
            <div className="col minw-0">{translate('Home.Sub_Total')}</div>
            <div className="col-auto">
              <CurrencyFormatter quantity={cart?.orderSubtotal} />
            </div>
          </div>
          <div className="row gutter-8 py-px">
            <div className="col minw-0">{translate('Home.Shipping_Fee')}</div>
            <div className="col-auto">
              <CurrencyFormatter quantity={cart?.shippingAmount} />
            </div>
          </div>
          {Number(cart?.taxPercent) > 0 && (
            <div className="row gutter-8 py-px">
              <div className="col minw-0">
                {translate('Home.VAT')}{' '}
                {cart?.taxPercent ? cart?.taxPercent.toNumber() + ' % ' : ''}
              </div>
              <div className="col-auto">
                <CurrencyFormatter quantity={cart?.orderTaxAmount} />
              </div>
            </div>
          )}
        </div>
        {/* <div className="apply-promo-cart font-md font-weight-medium rounded-lg pb-1 mb-4">
          <label className="m-0 pb-px">Promocode</label>
          <div className="position-relative inner-block">
            <TextInput
              label=""
              placeholder="Promo Code"
              type="text"
              size="sm"
            />
            <button
              type="button"
              className="btn btn-sm font-md text-primary position-absolute right-0 top-0 px-2 mx-1"
            >
              {translate('Home.Apply')}
            </button>
          </div>
        </div> */}
        {hasRole(UserPermissionTypes.enable_promocode) && (
          <div className="row gutter-8">
            <div className="col">
              <div className="cart-p mb-4">
                <label className="mb-0 font-md">
                  {translate('cart.promo_code')}
                </label>
                <div className="position-relative pb-2">
                  <input
                    type="text"
                    placeholder={translate('cart.promo_code')}
                    className="form-control"
                  />
                  <div className="p-aply-btn position-absolute font-md">
                    <button className="btn p-0 text-primary">
                      {translate('cart.apply')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row gutter-8 font-weight-bold pb-3 mb-2">
          <div className="col minw-0">{translate('Home.Total')}</div>
          <div className="col-auto">
            <CurrencyFormatter quantity={cart?.orderTotalAmount} />
          </div>
        </div>

        {!disableSubmit && (
          <div>
            <div className="d-inline-block w-100 py-px my-1">
              <Button
                className="Button__primary Button--radius w-100 p-3"
                type="submit"
                disabled={disableButton}
                onClick={() => {
                  holidayCheck();
                  // if (hasRole(UserPermissionTypes.need_order_otp)) {
                  //   submitOtp();
                  // } else {
                  //   history.push(`${RouteKeys.Checkout}/${cart.cartID}`);
                  // }
                }}
              // disabled={cart?.userStatus === 'Inactive'}
              >
                {translate('cartDetail.proceedToCheckout')}
              </Button>
              {/* {cart?.userStatus === 'Inactive' && (
                <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                  <p className="font-weight-medium my-1 text-gray-900 font-sm">
                    {translate('common.inactive_user_message')}
                  </p>
                </span>
              )} */}
            </div>
            {hasRole(UserPermissionTypes.saved_later) && (
              <div className="d-inline-block w-100 py-px my-1">
                <Button
                  className="Button__primary-outline Button--radius w-100 p-3"
                  type="submit"
                  onClick={() => {
                    setShowSaveLater(true);
                  }}
                // disabled={cart?.userStatus === 'Inactive'}
                >
                  {translate('cartDetail.save_later')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {/* <DeleteConfirmationDialog
        title={
          !isClearCart
            ? `${translate('cart.deleteConfirm')} ${selectedItems.length === 1
              ? `${translate('cart.anItem')}`
              : selectedItems.length + ` ${translate('cart.items')}`
            } ${translate('cart.fromCart')}`
            : `${translate('cart.clearCartItems')}`
        }
        onDismiss={() => {
          setShowConfirm(false);
        }}
        onConfirm={(reason: string, otherReason?: string) => {
          setShowConfirm(false);
          delteItems(reason, otherReason);
        }}
        open={showConfirm}
      ></DeleteConfirmationDialog> */}
      {loading && (
        <div className="position-absolute top-0 left-0 h-100 w-100">
          <FullPageLoader></FullPageLoader>
        </div>
      )}
      <Modal
        isOpen={showSaveLater}
        contentClassName="bg-white rounded-lg px-3 px-md-4 pt-4 pb-5"
      >
        <div className="px-px mx-md-1 py-2">
          <h5 className="font-weight-bold pb-px">
            {t('cartDetail.save_later')}
          </h5>
          <div>
            <TextInput
              placeholder={t('saveLater.add_title')}
              label=""
              default={saveLaterTitle}
              onChange={(val: any) => setSaveLaterTitle(val)}
              type="text"
              size="sm"
            />
          </div>
          <div className="row pt-1">
            <div className="col-12 py-2 mb-2">
              <button
                type="button"
                onClick={() => saveToLater()}
                className="btn btn-primary btn-sm btn-block"
              >
                {t('common.save')}
              </button>
            </div>
            <div className="col-12 py-2 text-center">
              <span
                className="text-primary font-md font-weight-bold py-1 cursor-pointer"
                onClick={() => setShowSaveLater(false)}
              >
                {t('common.cancel')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showOtp}
        contentClassName="bg-white rounded-lg px-3 px-md-4 py-5"
      >
        <div className="px-px mx-md-1 py-2 text-center">
          <h5 className="text-dark font-weight-bold pb-px">
            {t('forgot_password.enter_otp')}
          </h5>
          <p className="font-md font-weight-medium">{otpMessage}</p>
          <OTPInput
            autoFocus
            length={4}
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={(otpInput) => setOtp(otpInput)}
          />
          <div className="text-right pb-4 pt-1">
            <span
              onClick={() => submitOtp()}
              className="text-primary cursor-pointer font-weight-semibold font-md"
            >
              {t('forgot_password.resendOtp')}
            </span>
          </div>
          <div className="row pt-1">
            <div className="col-12 mb-3 pb-1">
              <button
                type="button"
                disabled={otpLoading}
                onClick={() => verifyOtp()}
                className="btn btn-primary btn-sm btn-block"
              >
                {t('common.verify')}
              </button>
            </div>
            <div className="col-12 text-center">
              <span
                className="text-primary font-md font-weight-bold py-1 cursor-pointer"
                onClick={() => setShowOtp(false)}
              >
                {t('common.cancel')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isHoliday}
        contentClassName="bg-white rounded-lg px-3 px-md-4 py-5"
      >
        <div className="px-px mx-md-1 py-2 text-center">
          <h5 className="text-dark font-weight-bold pb-px">
            {t('others.sorry_for_inconvenience')}
          </h5>
          <p className="font-md font-weight-medium">{holidayMessage}</p>
          <div className="row pt-1">
            <div className="col-12 mb-3 pb-1">
              <button
                type="button"
                onClick={() => {
                  setIsHoliday(false);
                  setShowSaveLater(true);
                }}
                className="btn btn-primary btn-sm btn-block"
              >
                {t('others.confirm_save')}
              </button>
            </div>
            <div className="col-12 text-center">
              <span
                className="text-primary font-md font-weight-bold py-1 cursor-pointer"
                onClick={() => setIsHoliday(false)}
              >
                {t('common.cancel')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      {submitOtpLoading && (
        <FullPageLoader />
      )}
      <InActiveUserPopup
        isOpen={openModal.isOpen}
        content={openModal.msg || ''}
        onClose={onClose}
      />
    </>
  );
};
