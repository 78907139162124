import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, cartResetAction, logoutAction } from '../../core/store';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../../containers/routes/route-keys';
import { useAuth } from '../../core/hooks';

const LogoutTimer = () => {
  const [idleTime, setIdleTime] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUserActivity = () => {
    // Reset the idle time on user activity
    setIdleTime(0);
  };


  useEffect(() => {

    // Set up an interval to check idle time every minute
    const interval = setInterval(() => {
      setIdleTime(prevIdleTime => prevIdleTime + 1);
    }, 60000); // 1 minute

    // Attach event listeners to reset idle time on user activity
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    // Clean up event listeners and interval on component unmount
    return () => {
      clearInterval(interval);
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  // Logout the user after 1 hour of idle time
  useEffect(() => {
    if (idleTime >= 60) { // 60 minutes = 1 hour
      // Trigger logout action here (e.g., call a logout function)
      dispatch(logoutAction());
      dispatch(cartResetAction());
      history.push(RouteKeys.Login);
    }
  }, [idleTime]);

  return (
    // Your component rendering logic here
    <div>
      {/* Your application content */}
    </div>
  );
};

export default LogoutTimer;