import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import './cart-page.scss';
// @ts-ignore
import { useCart } from '../../../core/hooks/useCart';
import empty from './empty.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
import { ICartData, ICartDeleteReason } from '../../../models/order-models';
import { useTranslation } from 'react-i18next';
import { translate } from '../../../utils/text-utils';
import { CurrencyFormatter } from '../../../components/currency-formatter';
import { DeleteConfirmationDialog } from './components/delete-confirmation';
import { toastr } from 'react-redux-toastr';
import { FullPageLoader } from '../../../components/loader';
import { CartItemDetail } from './cart-item-detail';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import { ApiResponse } from '../../../core/api/models';
import { useDispatch, useSelector } from 'react-redux';
import { State, cartGetAction } from '../../../core/store';
import { useOrder } from '../../../core/hooks/useOrder';

export const CartDetails = (props: {
  cart?: ICartData;
  disableSubmit?: boolean;
  showHeader?: boolean;
  disableCounter?: boolean;
  canEditCart?: boolean;
}) => {
  const showHeader = props.showHeader ?? false;
  const [showConfirm, setShowConfirm] = useState(false);
  const [isClearCart, setIsClearCart] = useState(false);

  const disableCounter = props.disableCounter ?? false;
  const history = useHistory();
  const location = useLocation();
  const cart = props.cart;
  const cartItems = cart?.items ?? [];
  const disableSubmit = props.disableSubmit ?? false;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { t } = useTranslation();
  const { bulkDeleteFromCart } = useCart();
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const orderParam = searchParams.get('order');

  // const authState = useSelector((state: State) => state.authUser);

  // console.log("------", authState)


  // const {
  //   data: reasonList,
  //   loaded,
  //   reload, } = useDataLoader<
  //     ApiResponse<ICartDeleteReason[]>
  //   >(() =>
  //     api.model.getDeleteReasons(authState?.userToken, authState?.salesPersonToken)
  //   );



  if (!!!cart?.items?.length) {
    return (
      <>
        <div className="row gutter-5 pt-4 pb-2 d-none d-lg-flex">
          <h5 className="col-auto font-weight-bold m-0">{t('Header.Cart')}</h5>
          <div className="col-auto py-1">
            <div className="border-left h-100"></div>
          </div>
          <div className="col-auto align-self-center text-muted font-md">
            0 {translate('duePayments.items')}
          </div>
        </div>
        <div className="cartBlock__item border rounded shadow py-5">
          <div className="cartBlock__itemHead">
            {translate('Home.Your_Cart_is_empty')}
          </div>
          <div className="cartBlock__img">
            <img src={empty} alt="" />
          </div>
        </div>
      </>
    );
  }
  const delteItems = async (reason: string, otherReason?: string) => {
    try {
      setLoading(true);
      await bulkDeleteFromCart(
        selectedItems.join(','),
        cart?.cartID,
        reason,
        otherReason,
        isClearCart
      )
        .then(() => {
          setSelectedItems([]);
        })
        .catch(() => {
          toastr.error(
            translate('common.error'),
            translate('toast.somethingWentWrong')
          );
        });
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {showHeader && (
        <div className="row gutter-5 pt-4 pb-2 d-none d-lg-flex">
          <h5 className="col-auto font-weight-bold m-0">{t('Header.Cart')}</h5>
          <div className="col-auto py-1">
            <div className="border-left h-100"></div>
          </div>
          <div className="col-auto align-self-center text-muted font-md">
            {cartItems.length} {translate('duePayments.items')}
          </div>
        </div>
      )}
      {/* <div className="px-4 pb-2 d-flex flex-wrap align-items-center">
        <div>
          <input
            onClick={(event: any) => {
              if (event.target.checked) {
                setSelectedItems(cartItems?.map((p) => p.cartItemID) ?? []);
              } else {
                setSelectedItems([]);
              }
            }}
            type="checkbox"
            checked={cartItems.length === selectedItems.length}
            id="checkone1"
          />
          <label
            className="font-xs form-check-label text-muted checkbox-sm"
            htmlFor="checkone1"
          >
            Select all
          </label>
        </div>
        {!props?.canEditCart && (
          <div className="ml-auto pl-3">
            <span
              onClick={() => {
                if (cart?.userStatus !== 'Inactive') {
                  setIsClearCart(true);
                  setShowConfirm(true);
                }
              }}
              className={`font-xs d-inline-flex tooltip sm position-relative ${
                cart?.userStatus !== 'Inactive' && 'text-primary'
              } text-underline cursor-pointer`}
              style={{ color: cart?.userStatus === 'Inactive' ? '#999' : '' }}
            >
              {translate('cart.clearCart')}
              {cart?.userStatus === 'Inactive' && (
                <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                  <p className="font-weight-medium my-1 text-gray-900 font-sm">
                    {t('common.inactive_user_message')}
                  </p>
                </span>
              )}
            </span>
          </div>
        )}
      </div> */}
      <div className="bg-white border shadow rounded-lg overflow-hidden">
        <div className="overflow-auto">
          <table className="cart-table w-100 font-md">
            <thead>
              <tr>
                <th className="pr-0" style={{ width: '36px' }}>
                  <input
                    onClick={(event: any) => {
                      if (event.target.checked) {
                        setSelectedItems(
                          cartItems?.map((p) => p.cartItemID) ?? []
                        );
                      } else {
                        setSelectedItems([]);
                      }
                    }}
                    type="checkbox"
                    checked={cartItems.length === selectedItems.length}
                    id="checkone1"
                  />
                  <label htmlFor="checkone1" className="px-2">
                    &nbsp;
                  </label>
                </th>
                <th className="text-center" style={{ width: '74px' }}>
                  {translate('cartDetail.slNo')}
                </th>
                <th>{translate('cartDetail.part_number')}</th>
                <th>{translate('cartDetail.part_name')}</th>
                <th className="text-center">
                  {translate('cartDetail.quantity')}
                </th>
                <th className="text-right">
                  {translate('cartDetail.unitPrice')}
                </th>
                <th className="text-right">{translate('cartDetail.price')}</th>
                <th className="pl-0" style={{ width: '52px' }}>
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {cartItems?.map((cartItem, index) => {
                return (
                  <CartItemDetail
                    canEditCart={props?.canEditCart}
                    isChecked={
                      selectedItems.indexOf(cartItem.cartItemID) !== -1
                    }
                    onSelectionChange={() => {
                      let clone = [...selectedItems];
                      if (clone.indexOf(cartItem.cartItemID) === -1) {
                        clone.push(cartItem.cartItemID);
                      } else {
                        clone = clone.filter((p) => p !== cartItem.cartItemID);
                      }
                      setSelectedItems(clone);
                    }}
                    key={`${cartItem.cartID}_${index}`}
                    isViewOnly={disableCounter}
                    item={cartItem}
                    cartId={cart.cartID}
                    cartUserStatus={cart?.userStatus || ''}
                    itemIndex={index}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="border-top bg-white p-3 py-md-4 font-md">
          <div className="row py-px flex-row">
            <div className="col-12 col-md-auto ml-auto font-base font-weight-medium text-right order-md-last pb-3 pb-md-0">
              {translate('Home.Sub_Total')}
              {` (${cartItems?.length === 1
                ? `${cartItems?.length} ${t('orders.item')}`
                : `${cartItems?.length} ${t('Header.Items')}`
                })`}
              :
              <span className="font-weight-bold pl-1">
                <CurrencyFormatter quantity={cart?.orderSubtotal} />
              </span>
            </div>
            {orderParam !== 'yes' && (
              <div className="col-auto">
                <Link
                  to={'/'}
                  className="text-primary cursor-pointer font-weight-bold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="currentColor"
                    className="mr-2 position-relative flip-rtl"
                    style={{ top: '-1px' }}
                  >
                    <path d="M2.83698 7.09371L7.34643 11.6032L6.49998 12.4375L0.5625 6.49998L6.49998 0.5625L7.34643 1.39678L2.83698 5.90625H12.4375V7.09371H2.83698Z" />
                  </svg>
                  <span>{translate('cartDetail.continue_shopping')}</span>
                </Link>
              </div>
            )}
            {selectedItems.length > 0 && (
              <div className="col-auto d-none d-md-flex">
                <span
                  onClick={() => {
                    if (cart?.userStatus !== 'Inactive') {
                      setIsClearCart(false);
                      setShowConfirm(true);
                    }
                  }}
                  className={`text-primary d-inline-flex align-items-center tooltip left font-weight-bold sm position-relative cursor-pointer`}
                  style={{
                    color: cart?.userStatus === 'Inactive' ? '#999' : '',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="currentColor"
                    className="mr-2"
                  >
                    <path d="M0.933336 15V2.11842H0V0.785866H4.19999V0H9.80001V0.785866H14V2.11842H13.0667V15H0.933336ZM2.33332 13.6674H11.6667V2.11842H2.33332V13.6674ZM4.57694 11.8907H5.97692V3.8952H4.57694V11.8907ZM8.02308 11.8907H9.42306V3.8952H8.02308V11.8907Z" />
                  </svg>
                  {translate('cartDetail.remove')}
                  {cart?.userStatus === 'Inactive' && (
                    <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                      <p className="font-weight-medium my-1 text-gray-900 font-sm">
                        {t('common.inactive_user_message')}
                      </p>
                    </span>
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationDialog
        title={
          !isClearCart
            ? `${translate('cart.deleteConfirm')} ${selectedItems.length === 1
              ? `${translate('cart.anItem')}`
              : selectedItems.length + ` ${translate('cart.items')}`
            } ${translate('cart.fromCart')}`
            : `${translate('cart.clearCartItems')}`
        }
        onDismiss={() => {
          setShowConfirm(false);
        }}
        onConfirm={(reason: string, otherReason?: string) => {
          setShowConfirm(false);
          delteItems(reason, otherReason);
        }}
        open={showConfirm}
      ></DeleteConfirmationDialog>
      {loading && (
        <div className="position-absolute top-0 left-0 h-100 w-100">
          <FullPageLoader></FullPageLoader>
        </div>
      )}
    </>
  );
};
