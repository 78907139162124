import api from '../../../api';
import { FilterTypes } from '../../../containers/orders/components/order-filter-component';
import {
  IApproveRequestItem,
  IBulkImportResponse,
  ICartData,
  ICheckoutData,
  IOrder,
  IOrderDetails,
  IOrderFilterData,
  IOrderListParams,
  IPerformanceGraphData,
  IPerformanceGraphRequestParams,
  IPreviewData,
  IRejectRequestItem,
  IReturnDetails,
  IReturnListApiResponse,
  IReturnRequestFilterParams,
  IReturnRequestItem,
  ISampleLinks,
  Reasons,
  RejectReason,
  ReturnRequests,
  ToDoResult,
} from '../../../models/order-models';
import { ApiResponse, CreditExtendRequest, UserType } from '../models';
import * as http from './http';
import ApiService from './service';

export class OrderService extends ApiService {
  public async getOrderRequests(params: any): Promise<any> {
    const url = `${this.apiDomain}/order/request/`;

    const data = new FormData();
    data.append('status', params.status);
    // data.append('requestID', params.requestID );
    // data.append('fromDate', params.fromDate );
    // data.append('toDate', params.toDate );
    // data.append('searchUserID', params.searchUserID );
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('limitFrom', '0');
    data.append('limitTo', params.limit);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data;
  }

  public async getPerformaceGraphData(
    params: IPerformanceGraphRequestParams
  ): Promise<IPerformanceGraphData> {
    const url = `${this.apiDomain}/salesperson/performance/`;
    const data = new FormData();
    data.append('type', params?.type);
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('userToken', '');
    if ((params.salespersons?.length ?? 0) > 0) {
      data.append(
        'searchUserID',
        params.salespersons.map((p) => p.value).join(',')
      );
    }
    if (params?.fromMonth) data.append('fromMonth', params.fromMonth);
    if (params?.toMonth) data.append('fromDate', params.toMonth);
    if (params?.dashboard) data.append('dashboard', params.dashboard);

    const response = await http.postAsFormData<
      ApiResponse<IPerformanceGraphData>
    >(url, data);

    return response?.data?.result;
  }

  public async getToDoList(isDashboard: boolean): Promise<ToDoResult> {
    const url = `${this.apiDomain}/salesperson/todoList/`;
    const data = new FormData();
    data.append('dashboard', isDashboard ? 'Yes' : '');
    data.append('userToken', '');
    data.append('brandID', '');
    data.append('branchID', '');
    if (isDashboard) {
      data.append('page', '1');
      data.append('perPage', '10');
    }
    const response = await http.postAsFormData<ApiResponse<ToDoResult>>(
      url,
      data
    );

    return response?.data?.result;
  }

  public async getOrderRequestsDuplicate(
    params: IOrderListParams,
    includeCheckedInToken?: boolean,
    dashboard?: boolean
  ): Promise<ApiResponse<IOrder[]>> {
    const url = `${this.apiDomain}/order/request/`;
    const data = new FormData();
    data.append('status', params?.status);
    if (params?.requestID) data.append('requestID', params.requestID);
    if (params?.startDate) data.append('fromDate', params.startDate);
    if (params?.endDate) data.append('toDate', params.endDate);
    if (params?.requestedBy) data.append('requestedBy', params.requestedBy);
    if (params?.approvedBy) data.append('approvalUser', params.approvedBy);
    if (params?.createdFor) data.append('createdFor', params.createdFor);
    if (params?.cancelledBy) data.append('cancelledBy', params.cancelledBy);
    if (params?.rejectedBy) data.append('rejectedBy', params.rejectedBy);
    if (params?.paymentID) data.append('paymentMethod', params.paymentID);
    if (dashboard) data.append('dashboard', 'Yes');
    if (params.paymentStatus) {
      data.append('paymentStatus', params.paymentStatus);
    }
    if (params.salesperson) data.append('salesperson', params.salesperson);
    if (params?.export) data.append('export', 'Yes');
    data.append('brandID', params.brand ?? '');
    data.append('branchID', params.branchIDs ?? '');
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // if (api.getUserType() === UserType.Salesperson) {
    //   data.append('userToken', '');
    // }
    const response = await http.postAsFormData<ApiResponse<IOrder[]>>(
      url,
      data,
      includeCheckedInToken
    );
    if (!params?.status) {
      response.data.result = [];
    }
    return response?.data;
  }

  public async getOrderDetails(orderId: string): Promise<IOrderDetails> {
    const url = `${this.apiDomain}/order/requestDetails/`;

    const data = new FormData();
    data.append('requestID', orderId);
    data.append('brandID', '');
    data.append('branchID', '');
    if (api.getUserType() === UserType.Salesperson) {
      data.append('userToken', '');
    }

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data?.result;
  }

  public async getCheckoutData(
    orderID: string,
    brandID: string,
    branchID: string,
    userToken?: string,
    isOrder?: boolean
  ): Promise<ApiResponse<ICheckoutData>> {
    const url = `${this.apiDomain}/cart/checkout/`;
    const data = new FormData();
    if (isOrder) {
      data.append('orderRequestID', orderID);
    } else {
      data.append('cartID', orderID);
    }

    data.append('brandID', brandID);
    data.append('branchID', branchID);
    // if (userToken) {
    //   data.append('userToken', userToken);
    // }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data, true);
    return response?.data;
  }

  public async getArbVerifyPay(
    orderID: string
    // brandID: string,
    // branchID: string,
    // userToken?: string
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/cart/verifyArabPaymentMobile/`;
    const data = new FormData();
    data.append('orderID', orderID);
    // data.append('brandID', brandID);
    // data.append('branchID', branchID);
    // if (userToken) {
    //   data.append('userToken', userToken);
    // }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async getSampleLinks(): Promise<ISampleLinks> {
    const url = `${this.apiDomain}/index/bulkSampleFile`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<ISampleLinks>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async submitBulkOrder(ids: string): Promise<ApiResponse<ICartData>> {
    const url = `${this.apiDomain}/cart/confirmBulkImportCart/`;
    const data = new FormData();
    data.append('tempBulkOrderID', ids);
    const response = await http.postAsFormData<ApiResponse<ICartData>>(
      url,
      data,
      true
    );
    return response?.data;
  }

  public async bulkImportOrders(file: File): Promise<IBulkImportResponse> {
    const url = `${this.apiDomain}/cart/bulkImportCart/`;
    const formData = new FormData();
    formData.append('orderFile', file);
    formData.append('action', 'view');
    const response = await http.postAsFormData(url, formData, true);

    return (response?.data as any)?.result;
  }

  public async getPreviewDetails(
    requestId: string,
    items: IApproveRequestItem[],
    isPreview: boolean,
    userToken: string
  ): Promise<ApiResponse<IPreviewData>> {
    const url = `${this.apiDomain}/order/requestApprove/`;

    const data = new FormData();
    data.append('requestID', requestId);
    data.append('preview', isPreview ? 'Yes' : 'No');
    data.append('requestItems', JSON.stringify(items));
    if (userToken) {
      data.append('userToken', userToken);
      data.append('brandID', '');
      data.append('branchID', '');
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data;
  }

  public async getOrderFilterData(): Promise<IOrderFilterData> {
    const url = `${this.apiDomain}/order/filter/`;

    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<IOrderFilterData>>(
      url,
      data
    );

    return response?.data?.result;
  }

  public async getRejectReason(
    type?: 'Decline' | 'Revoke'
  ): Promise<Reasons[]> {
    const url = `${this.apiDomain}/order/rejectreasons/`;

    const data = new FormData();
    if (type) {
      data.append('type', type);
    }

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async rejectRequest(
    requestId: string,
    items: IRejectRequestItem[],
    isPreview: boolean,
    userToken: string
  ): Promise<ApiResponse<IPreviewData>> {
    const url = `${this.apiDomain}/order/requestDelete/`;

    const data = new FormData();
    data.append('requestID', requestId);
    data.append('preview', isPreview ? 'Yes' : 'No');
    data.append('requestItems', JSON.stringify(items));
    if (userToken) {
      data.append('userToken', userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data;
  }

  public async cancelRequest(
    requestId: string,
    items: IRejectRequestItem[],
    isPreview: boolean,
    userToken: string
  ): Promise<ApiResponse> {
    const url = `${this.apiDomain}/order/requestCancel/`;

    const data = new FormData();
    data.append('requestID', requestId);
    data.append('preview', isPreview ? 'Yes' : 'No');
    data.append('requestItems', JSON.stringify(items));
    if (userToken) {
      data.append('userToken', userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response.data;
  }

  public async returnRequest(
    orderId: string,
    items: IReturnRequestItem[],
    isPreview: boolean,
    userToken: string
  ): Promise<ApiResponse<IOrderDetails>> {
    const url = `${this.apiDomain}/return/returnproducts/`;

    const data = new FormData();
    data.append('orderID', orderId);
    data.append('products', JSON.stringify(items));
    data.append('action', isPreview ? 'Preview' : 'Save');
    if (userToken) {
      data.append('userToken', userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response.data;
  }

  public async getCreditLimitDetails(requestData?: any): Promise<any> {
    const url = `${this.apiDomain}/cart/checkcreditavailibility`;
    const data = new FormData();
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('cartID', requestData?.cartID);
    // if (requestData?.userToken) {
    //   data.append('userToken', requestData?.userToken);
    // }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data, true);
    return response?.data?.result;
  }

  public async requestCreditExtend(
    requestData: CreditExtendRequest
  ): Promise<any> {
    const url = `${this.apiDomain}/cart/requestcredit/`;
    const data = new FormData();
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('cartID', requestData?.cartID);
    data.append('requestType', requestData?.requestType);
    data.append('extendedCreditTerms', requestData?.extendedCreditTerms);
    if (requestData?.userToken) {
      data.append('userToken', requestData?.userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async requestAgain(
    requestId: string,
    userToken?: string
  ): Promise<ApiResponse> {
    const url = `${this.apiDomain}/order/requestAgain/`;

    const data = new FormData();
    data.append('requestID', requestId);
    if (userToken) {
      data.append('userToken', userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response.data;
  }

  public async salepersonRejectOrApprove(
    requestId: string,
    action: 'Approve' | 'Reject',
    reason?: string
  ): Promise<ApiResponse> {
    const url = `${this.apiDomain}/order/requestCancel/`;

    const data = new FormData();
    data.append('requestID', requestId);
    data.append('action', action);
    data.append('reason', reason);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response.data;
  }

  public async downloadCsv(url: string): Promise<any> {
    const response = await http.get<any>(url);

    return response;
  }

  public async removeExportFile(fileName: string): Promise<ApiResponse> {
    const url = `${this.apiDomain}/order/removeExportFile/`;

    const data = new FormData();
    data.append('file', fileName);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response.data;
  }

  public async getReturnRequest(
    params: IReturnRequestFilterParams
  ): Promise<IReturnListApiResponse<ReturnRequests[]>> {
    const url = `${this.apiDomain}/return/returnlist/`;
    const data = new FormData();
    data.append('status', params.status);
    data.append('requestID', params.requestID);
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    // if (params.fromDate) {
    //   data.append(
    //     'searchDate',
    //     `${params.fromDate}/${params.toDate ?? params.fromDate}`
    //   );
    // }
    data.append('brandID', '');
    data.append('branchID', '');

    Object.keys(params.filter)?.forEach((p) => {
      if (p === FilterTypes.brand) {
        const value = (params.filter as any)[p]?.value;
        if (value) {
          data.append('brandID', value);
        }
      }
      if (p === FilterTypes.branch) {
        const val: string[] = [];
        (params.filter as any)[p]?.map((brnch) => {
          if (brnch.value) val.push(brnch.value);
          return;
        });
        if (val.length > 0) {
          data.append('branchID', val.toString());
        }
      }
    });

    const response = await http.postAsFormData<
      IReturnListApiResponse<ReturnRequests[]>
    >(url, data);
    return response.data;
  }

  public async getReturnDetails(
    returnId: string
  ): Promise<ApiResponse<IReturnDetails>> {
    const url = `${this.apiDomain}/return/returnlist/`;

    const data = new FormData();
    data.append('returnID', returnId);
    data.append('brandID', '');
    data.append('branchID', '');
    if (api.getUserType() === UserType.Salesperson) {
      data.append('userToken', '');
    }

    const response = await http.postAsFormData<ApiResponse<IReturnDetails>>(
      url,
      data
    );

    return response?.data;
  }

  public async returnRejectOrApprove(
    returnId: string,
    action: 'Approve' | 'Reject',
    reason?: string
  ): Promise<ApiResponse> {
    const url = `${this.apiDomain}/return/returnlist/`;

    const data = new FormData();
    data.append('returnID', returnId);
    data.append('action', action);
    data.append('reason', reason);
    data.append('brandID', '');
    data.append('branchID', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response.data;
  }
}
