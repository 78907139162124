import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  ArabicLocale,
  cartResetAction,
  changeLanguageAction,
  EnglishLocale,
  ILocale,
  logoutAction,
  State,
} from '../../core/store';
import { RouteKeys } from '../routes/route-keys';
import { UserType, UserPermissionTypes } from '../../core/api/models';
import noImg from '../../assets/images/no-company.png';
import './SideMenu.scss';
import { useAuth } from '../../core/hooks';
import { translate } from '../../utils/text-utils';
import { useNotifications } from '../../core/hooks/useNotifications';
interface CustomProps {
  isExpand?: boolean;
  expandToggle: () => void;
  menuVisible?: boolean;
}

enum subMenus {
  payment = 'payment',
  settings = 'settings',
}

const SideMenuMob: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const authState = useSelector((state: State) => state.authUser);
  const [subMenu, toggleSubMenu] = React.useState<subMenus | null>(null);
  const { hasRole, userToken } = useAuth();
  const subMenuSelection = (e: any, selected: subMenus) => {
    e.preventDefault();
    if (selected === subMenu) {
      toggleSubMenu(null);
    } else {
      toggleSubMenu(selected);
    }
  };
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();
  const history = useHistory();
  const { notificationState } = useNotifications();
  const unreadNotificationCount =
    notificationState.notifications?.filter((p) => !p.isRead)?.length ?? 0;
  const onLanguageChange = (language: ILocale) => {
    //  i18n.changeLanguage(language.languageKey);
    dispatch(changeLanguageAction(language.languageID));
    // const h1 = document.getElementsByTagName('html')[0]; // Get the first <h1> element in the document
    // const att = document.createAttribute('dir'); // Create a "class" attribute
    // att.value = language.isRtl ? 'rtl' : 'ltr'; // Set the value of the class attribute
    // h1.setAttributeNode(att);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  return (
    <>
      <div
        className={`position-fixed top-0 left-0 bg-body zIndex-9 w-100 h-100 overflow-auto pb-5`}
      >
        <header className="bg-primary d-lg-none header-mobile pt-lg-2 pb-4 position-relative mb-4">
          <div className="container-fluid py-3">
            <div className="row gutter-12">
              <div className="col">
                <div className="d-flex align-items-center justify-content-center rounded-circle text-white font-weight-bold h4 m-0 circle-avatar">
                  <span>{authState?.profile?.name[0].toUpperCase()}</span>
                  <span>{authState?.profile?.name[1].toUpperCase()}</span>
                </div>
              </div>
              <div className="col-auto">
                <div className="pt-3 d-flex align-items-center">
                  <i className="icon-social-media h5 mb-0 text-white mr-3 position-relative">
                    {unreadNotificationCount > 0 && (
                      <span className="noti-count font-xs text-white position-absolute rounded-pill text-center">
                        {unreadNotificationCount}
                      </span>
                    )}
                  </i>
                  <div
                    className={`language-switch rounded-pill d-inline-flex align-items-center text-center position-relative ${settings.locale.languageID === 1 ? 'en' : 'ar'
                      }`}
                    onClick={() => {
                      if (settings.locale.languageID === 1) {
                        onLanguageChange(new ArabicLocale());
                      } else {
                        onLanguageChange(new EnglishLocale());
                      }
                    }}
                  >
                    <span className="en">EN</span>
                    <span className="ar">AR</span>
                  </div>
                </div>
              </div>
              <div className="col-12 py-2">
                <h6 className="text-white mb-0 font-weight-bold">
                  {authState?.profile?.name}
                </h6>
                <p className="text-white font-md mb-0">
                  {authState?.profile?.email}
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              history.push(RouteKeys.Offers);
            }}
            className="mob-search position-absolute w-100 bg-white rounded d-flex no-gutters align-items-center p-0 overflow-hidden shadow"
          >
            <div className="px-3 py-2 offer-img">
              <img
                src={require('../../assets/images/offer-img.svg')}
                className="img-fluid"
              />
            </div>
            <div className="pl-2">
              <h6 className="font-base m-0 text-gray-700">
                {translate('Header.Offers')}
              </h6>
              <p className="font-xs mb-0 text-gray-600">
                {translate('Header.AvailLatest')}
              </p>
            </div>
            <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9 text-gray-700 pr-3"></i>
          </div>
        </header>
        <div className="header-mob-bottom container-fluid pt-3 pb-5">
          <div className="bg-white shadow p-3 mb-2 rounded">
            <div className="font-xxs mb-3 text-uppercase text-gray-400">
              {translate('Header.requestsOrders')}
            </div>
            {userToken && (
              <Link
                to="/"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-dashboard mr-3 mb-0 menu-icon"></i>
                {authState?.userType === UserType.Salesperson
                  ? translate('others.customerDashboard')
                  : translate('sideMenu.catalog')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )}
            {authState?.userType === UserType.Salesperson &&
              hasRole(UserPermissionTypes.place_order_for_customers) && (
                <Link
                  to="/customers"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-place-order1 mr-3 mb-0 menu-icon"></i>
                  {translate('Header.placeOrderCustomers')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
              )}
            {/* {authState?.userType === UserType.Customer && (
              <Link
                to="/"
                onClick={() => window.location.reload()}
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-catalog mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.catalog')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )} */}
            {hasRole(UserPermissionTypes.customer_orders) && (
              <Link
                to={'/orders'}
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-my_orders mr-3 mb-0 menu-icon"></i>
                {authState?.userType !== UserType.Salesperson
                  ? translate('sideMenu.myOrders')
                  : translate('sideMenu.customerOrders')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )}
            {/* {hasRole(UserPermissionTypes.quotation_list) && (
              <Link
                to="/quotation"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-quotation mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.quotation')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )} */}
            {/* {hasRole(UserPermissionTypes.return_list) && (
              <Link
                to="/returns"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-return mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.returnRequests')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )} */}
            {hasRole(UserPermissionTypes.saved_later) && (
              <Link
                to="/save-for-later"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <span className="menu-icon mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_941_8558)">
                      <path
                        d="M5.86758 17.3333H19.7342C20.0009 17.3333 20.2454 17.2222 20.4676 17C20.6898 16.7778 20.8009 16.5333 20.8009 16.2667V2.4C20.8009 2.13333 20.6898 1.88889 20.4676 1.66667C20.2454 1.44445 20.0009 1.33333 19.7342 1.33333H18.1342V9.13334L15.6676 7.66667L13.2009 9.13334V1.33333H5.86758C5.60091 1.33333 5.35647 1.44445 5.13424 1.66667C4.91202 1.88889 4.80091 2.13333 4.80091 2.4V16.2667C4.80091 16.5333 4.91202 16.7778 5.13424 17C5.35647 17.2222 5.60091 17.3333 5.86758 17.3333ZM5.86758 18.2667C5.2898 18.2667 4.81202 18.0778 4.43425 17.7C4.05647 17.3222 3.86758 16.8444 3.86758 16.2667V2.4C3.86758 1.82222 4.05647 1.34445 4.43425 0.966668C4.81202 0.58889 5.2898 0.400002 5.86758 0.400002H19.7342C20.312 0.400002 20.7898 0.58889 21.1676 0.966668C21.5454 1.34445 21.7342 1.82222 21.7342 2.4V16.2667C21.7342 16.8444 21.5454 17.3222 21.1676 17.7C20.7898 18.0778 20.312 18.2667 19.7342 18.2667H5.86758ZM2.26758 21.8667C1.6898 21.8667 1.21202 21.6778 0.834245 21.3C0.456467 20.9222 0.267578 20.4444 0.267578 19.8667V5.06667H1.20091V19.8667C1.20091 20.1333 1.31202 20.3778 1.53424 20.6C1.75647 20.8222 2.00091 20.9333 2.26758 20.9333H17.0676V21.8667H2.26758ZM5.86758 1.33333H4.80091H20.8009H5.86758Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_941_8558">
                        <rect width="22" height="22" fill="black" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                {translate('cartDetail.save_later')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )}
            {((authState.userType === UserType.Salesperson &&
              authState.checkedInCustomer) ||
              authState.userType === UserType.Customer ||
              authState.userType === UserType.User) && (
                <Link
                  to="/saved-search"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <span className="menu-icon mr-3 text-gray-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="20"
                      viewBox="0 0 14 22"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_920_8555)">
                        <path
                          d="M3.4 1.5H10.6C11.2365 1.5 11.847 1.75022 12.2971 2.19562C12.7471 2.64102 13 3.24511 13 3.875V20.5L7 16.9375L1 20.5V3.875C1 3.24511 1.25286 2.64102 1.70294 2.19562C2.15303 1.75022 2.76348 1.5 3.4 1.5Z"
                          stroke="currentColor"
                          stroke-width="1.6"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_920_8555">
                          <rect width="14" height="22" fill="currentColor"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  {translate('headerCanvas.savedSearch')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                </Link>
              )}
            {/* {authState?.userType === UserType.Salesperson &&
              hasRole(UserPermissionTypes.customer_request_view) && (
                <Link
                  to="/customer-requests"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-new-customer mr-3 mb-0 menu-icon"></i>
                  {translate('sideMenu.newCustomerRequests')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
              )} */}
            {/* {authState?.userType === UserType.Salesperson && (
              <Link
                to="/schedule-management"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-schedule-management mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.scheduleManagement')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )} */}
            {userToken && hasRole(UserPermissionTypes.bulk_order) && (
              <Link
                to="/bulk-order"
                className="d-flex align-items-center text-gray-700"
              >
                <i className="icon-bulk mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.bulkOrder')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )}
          </div>
          {hasRole(UserPermissionTypes.view_duepayments) && (
            <div className="bg-white shadow p-3 mb-2 rounded">
              <div className="font-xxs mb-3 text-uppercase text-gray-400">
                {translate('sideMenu.paymentCenter')}
              </div>
              <Link
                to="/due-payments"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-payment-center mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.duePayments')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
              {/* {hasRole(UserPermissionTypes.list_paymenthistory) && (
                <Link
                  to="/payment-history"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-payment-history mr-3 mb-0 menu-icon"></i>
                  {translate('sideMenu.paymentHistory')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
              )}
              {hasRole(UserPermissionTypes.list_creditrequest) && (
                <Link
                  to="/credit-requests"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-credit-request mr-3 mb-0 menu-icon"></i>
                  {translate('credit.creditRequests')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
              )} */}
              {/* <Link
                to="/creditline-extension-request"
                className="d-flex align-items-center text-gray-700"
              >
                <i className="icon-creditline mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.creditLineExtension')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link> */}
              {/* <Link to="" className="d-flex align-items-center text-gray-700">
              <i className="icon-payment-center mr-3 mb-0 menu-icon"></i>
              {translate('sideMenu.creditnote')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
            </Link> */}
            </div>
          )}
          {hasRole(UserPermissionTypes.report) && (
            <div className="bg-white shadow p-3 mb-2 rounded">
              <div className="font-xxs mb-3 text-uppercase text-gray-400">
                {translate('reports.reports')}
              </div>
              {hasRole(UserPermissionTypes.report_sales) && (
                <Link
                  to="/sales"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-performance mr-3 mb-0 menu-icon"></i>
                  {translate('reports.sales')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
              )}
              {hasRole(UserPermissionTypes.report_payment) && (
                <Link
                  to="/reports-payment"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-payment-history mr-3 mb-0 menu-icon"></i>
                  {translate('duePayments.payment')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
              )}
              {hasRole(UserPermissionTypes.report_payment) && (
                <Link
                  to="/reports-customers"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-corporate-user mr-3 mb-0 menu-icon"></i>
                  {translate('reports.customers')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
              )}
              {/* <Link
                to="/creditline-extension-request"
                className="d-flex align-items-center text-gray-700"
              >
                <i className="icon-creditline mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.creditLineExtension')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link> */}
              {/* <Link to="" className="d-flex align-items-center text-gray-700">
              <i className="icon-payment-center mr-3 mb-0 menu-icon"></i>
              {translate('sideMenu.creditnote')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
            </Link> */}
            </div>
          )}
          <div className="bg-white shadow p-3 mb-2 rounded">
            <div className="font-xxs mb-3 text-uppercase text-gray-400">
              {translate('sideMenu.accountSettings')}
            </div>
            {authState.userType !== 'Salesperson' && (
              <>
                <Link
                  to="/general-settings"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-account-settings mr-3 mb-0 menu-icon"></i>
                  {translate('sideMenu.generalSettings')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
                <Link
                  to="/staff-member"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-boy mr-3 mb-0 menu-icon"></i>
                  {translate('sideMenu.staff')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                </Link>
              </>
            )}
            {hasRole(UserPermissionTypes.view_account_personal_info) &&
              authState.userType === 'Salesperson' && (
                <>
                  <Link
                    to="/account-settings"
                    className="d-flex align-items-center text-gray-700 mb-3"
                  >
                    <i className="icon-account-settings mr-3 mb-0 menu-icon"></i>
                    {translate('sideMenu.profileInformation')}
                    <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
                  </Link>
                </>
              )}
            {/* {hasRole(UserPermissionTypes.list_salespersons) && (
              <Link
                to={RouteKeys.ManageSalesPerson}
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-lock mr-3 mb-0 menu-icon"></i>
                {translate('sideMenu.manageSalesPerson')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
              </Link>
            )} */}
            <Link
              to="/change-password"
              className="d-flex align-items-center text-gray-700 mb-3"
            >
              <i className="icon-lock mr-3 mb-0 menu-icon"></i>
              {translate('common.changePassword')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
            </Link>
            <Link
              to="/terms-of-use"
              className="d-flex align-items-center text-gray-700"
            >
              <i className="icon-terms mr-3 mb-0 menu-icon"></i>
              {translate('sideMenu.termsOfUse')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
            </Link>
          </div>
          <div className="bg-white shadow p-3 mb-2 rounded">
            <Link to="/faq" className="d-flex align-items-center text-gray-700">
              <i className="icon-faq mr-3 mb-0 menu-icon"></i>
              {translate('sideMenu.faq')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
            </Link>
          </div>
          <div className="bg-white shadow p-3 mb-2 rounded">
            <a
              href="#"
              className="d-flex align-items-center text-gray-700"
              onClick={() => {
                dispatch(logoutAction());
                dispatch(cartResetAction());
                history.push(RouteKeys.Login);
              }}
            >
              <i className="icon-logout mr-3 mb-0 menu-icon"></i>
              {translate('sideMenu.logOut')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-9"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenuMob;
