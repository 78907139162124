import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/button';
import { UserPermissionTypes } from '../../../../core/api/models';
import { useAuth } from '../../../../core/hooks';
import { translate } from '../../../../utils/text-utils';
import { TabFilters } from '../../../orders/order-listing-screen';
import { RouteKeys } from '../../../routes/route-keys';

export const ConfirmSummary = (props: {
  header: string;
  text: string;
  buttonTitle?: string;
  redirectionRoute?: string;
}) => {
  const history = useHistory();
  const { hasRole } = useAuth();

  return (
    <div className="px-3 py-4 py-lg-5">
      <div className="bg-white border shadow px-3 py-5 text-center box-sm mx-auto">
        <div className="px-lg-4 py-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="currentColor"
            className="text-primary mb-4"
          >
            <g clip-path="url(#clip0_524_12316)">
              <rect
                x="28"
                y="2"
                width="33"
                height="33"
                rx="16.5"
                fill="#F47A20"
              />
              <path
                d="M40.4068 0.4375C36.9068 1.3125 33.2818 3.59375 30.7818 6.59375C25.7818 12.5312 25.2193 21.625 29.3756 28.0938C32.9068 33.5312 38.5318 36.6562 44.8443 36.6562C50.1881 36.6562 54.0006 35.0938 57.8443 31.25C62.4693 26.6875 64.2818 20.7812 62.9693 14.5312C61.5631 7.8125 56.1881 2.15625 49.5318 0.46875C47.3131 -0.09375 42.6881 -0.09375 40.4068 0.4375ZM47.6881 3.46875C54.6881 4.75 60.0006 11.125 60.0006 18.25C59.9693 25.8125 54.5006 32.1562 47.0006 33.2188C39.8756 34.25 32.7506 29.75 30.6256 22.8438C29.8131 20.2188 29.7818 16.3438 30.5943 13.7812C32.4381 7.8125 38.3756 3.34375 44.7193 3.15625C45.3131 3.15625 46.6568 3.28125 47.6881 3.46875Z"
                fill="#F47A20"
              />
              <path
                d="M47.156 16.2812C45.031 18.875 43.031 21.2812 42.7497 21.6562C42.2185 22.3438 42.2185 22.3438 39.7497 19.9062C38.3747 18.5938 37.1247 17.5 36.9685 17.5C36.8122 17.5 36.3747 17.7188 35.9997 18C34.7185 18.875 35.1247 19.7812 38.3435 23.2812C40.7185 25.8438 41.5622 26.5625 42.1872 26.5625C42.8747 26.5625 43.8122 25.5938 47.8747 20.6875C54.0935 13.1562 53.9685 13.3438 53.3122 12.3438C52.9685 11.8125 52.4997 11.5625 51.9372 11.5625C51.1872 11.5625 50.4685 12.25 47.156 16.2812Z"
                fill="white"
              />
              <path d="M1.15653 10.5626C0.500279 11.2813 0.469029 12.3126 1.12528 12.9376C1.53153 13.3438 2.53153 13.4376 7.18778 13.4376C10.2503 13.4376 12.844 13.5626 12.9378 13.7188C13.0315 13.8751 12.7503 15.7188 12.3128 17.8438L11.5003 21.7188L14.9378 36.7813L18.4065 51.8438L17.5003 55.0626C16.4065 58.8438 16.3753 59.8126 17.1565 61.1251C18.4378 63.2188 17.3128 63.1251 44.2815 63.1251C67.5003 63.1251 68.6878 63.0938 69.1565 62.5626C69.8128 61.8438 69.844 60.8126 69.1878 60.1876C68.7503 59.7501 66.0628 59.6876 44.344 59.6876H20.0003V59.0313C20.0003 58.5626 21.1565 54.0313 21.3753 53.5313C21.4065 53.4688 41.9378 51.2501 60.9378 49.2188C69.0003 48.3438 69.8128 48.1251 71.0003 46.5313C71.3753 46.0313 73.2503 39.7188 75.6565 30.9063C79.3753 17.1563 79.6565 16.0626 79.219 15.4063C78.6565 14.5626 77.469 14.4688 76.7815 15.2188C76.5315 15.5313 76.0628 16.7188 75.7503 17.8751L75.1878 20.0001H71.344C67.6253 20.0001 67.5003 20.0313 67.0003 20.7813C65.844 22.5626 67.2503 23.4376 71.2503 23.4376C73.3128 23.4376 74.0628 23.5313 74.0628 23.8126C74.0315 24.6876 68.5003 44.4376 68.1878 44.7501C67.9065 45.0313 24.6878 49.9376 22.1878 50.0001C21.3753 50.0001 21.3753 49.9376 18.469 37.0938C16.844 30.0313 15.469 24.0313 15.3753 23.8126C15.2815 23.5313 16.0003 23.4376 18.4378 23.4376C21.7503 23.4376 22.5628 23.2188 23.1253 22.1563C23.4065 21.6563 23.3753 21.3438 22.969 20.7501C22.5003 20.0313 22.344 20.0001 18.9065 20.0001C16.9378 20.0001 15.3128 19.9376 15.3128 19.8438C15.3128 19.7501 15.594 18.3438 15.969 16.6876C16.594 13.7501 16.594 13.6876 16.0315 12.5626C15.6253 11.8126 14.969 11.1563 14.0628 10.6876C12.7815 10.0626 12.2815 10.0001 7.15653 10.0001C2.21903 10.0001 1.59403 10.0626 1.15653 10.5626Z" />
              <path d="M21.5003 66.5314C17.2815 67.9064 15.4065 72.5939 17.594 76.3126C19.719 79.9376 24.5003 80.7189 27.719 77.9689C31.1565 75.0314 30.594 69.4376 26.6565 67.2501C25.219 66.4689 22.7815 66.1251 21.5003 66.5314ZM25.219 70.3126C27.0003 71.3751 27.1253 74.0939 25.469 75.5314C24.0315 76.8126 21.3128 76.3126 20.4378 74.6564C19.6878 73.1564 19.8753 71.9376 21.0628 70.7501C22.3128 69.5001 23.6565 69.3439 25.219 70.3126Z" />
              <path d="M60.7811 66.7188C58.9373 67.375 57.0623 69.5 56.5936 71.4688C55.3436 76.5938 60.7186 81.2188 65.5623 79.1875C70.1561 77.2813 71.1561 71.0313 67.3748 67.9063C65.4061 66.3125 63.1248 65.875 60.7811 66.7188ZM64.9373 70.1875C67.6873 72.0938 66.4061 76.25 63.0623 76.25C62.0623 76.25 61.5936 76.0313 60.7186 75.1563C59.7186 74.1563 59.6248 73.9375 59.7811 72.7188C60.1248 70.2188 62.9998 68.8125 64.9373 70.1875Z" />
            </g>
            <defs>
              <clipPath id="clip0_524_12316">
                <rect width="80" height="80" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h6
            className="font-weight-bold mb-3 pt-1"
            style={{ lineHeight: '1.4' }}
          >
            {props.header}
          </h6>
          <div className="font-medium text-muted line-height-normal">
            {props.text}
          </div>
          <div className="pt-4 mt-3 mx-auto" style={{ maxWidth: '240px' }}>
            <div className="pb-2 mb-1">
              <Button
                size="md"
                className=" Button__primary Button--radius Button--block"
                onClick={() => {
                  history.push(RouteKeys.Home);
                }}
              >
                {translate('cart.backHome')}
              </Button>
            </div>
            <div className="pb-2 mb-1">
              {hasRole(UserPermissionTypes.customer_orders) && (
                <Button
                  size="md"
                  className=" Button__primary-outline Button--radius Button--block font-md"
                  onClick={() => {
                    history.push(
                      props.redirectionRoute ??
                        `${RouteKeys.Orders}/${TabFilters.RequestApproved}`
                    );
                  }}
                >
                  {props.buttonTitle ?? translate('orders.viewMyOrders')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
