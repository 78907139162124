import React, { useEffect, useState } from 'react';
import Button from '../../../components/button';
import './cart.scss';
import './cartItem.scss';
import '../../../containers/search-tab/search-box.scss';
// @ts-ignore
import { useCart } from '../../../core/hooks/useCart';
import empty from './empty.png';
import classnames from 'classnames';
import { useQuotes } from '../../../core/hooks/useQuotes';
import { TextInput } from '../../../components/form-group';
import { QuoteItem } from './quote-item';
import { CartItemComponent } from './cart-item';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
import { ICartData } from '../../../models/order-models';
import {
  IQuotation,
  UserPermissionTypes,
  UserType,
} from '../../../core/api/models';
import { useDispatch, useSelector } from 'react-redux';
import { State, cartOpen, quoteOpen, checkInAction } from '../../../core/store';
import { useBrandsAndBranches } from '../../../core/hooks/useBrandsAndBranches';
import { useTranslation } from 'react-i18next';
import { translate } from '../../../utils/text-utils';
import Currency from 'react-currency-formatter';
import { CurrencyFormatter } from '../../../components/currency-formatter';
import { DeleteConfirmationDialog } from './components/delete-confirmation';
import { toastr } from 'react-redux-toastr';
import { ContainerLoader, FullPageLoader } from '../../../components/loader';
import { useAuth } from '../../../core/hooks';
import { decodeHtmlEntities } from '../../../utils/common';
import Modal from '../../../components/modal';
import OTPInput from '../../../components/form-group/otp-input/OtpInput';
import api from '../../../api';

interface CustomProps {
  popup?: boolean;
  hideSubmit?: boolean;
  onToggle?: (isVisible: boolean) => void;
  setShowCartPopup?: (status: boolean) => void;
}
export enum TabNames {
  Cart = 'Cart',
  Quotes = 'Quotes',
}

const Cart: React.FunctionComponent<CustomProps> = (props: CustomProps) => {
  const authState = useSelector((state: State) => state.authUser);
  const isPopup = props.popup ?? false;

  const { getCart, cart, loaded } = useCart();
  const { brandState } = useBrandsAndBranches();
  // const { getQuotations, quotations, loaded2, quoteState } = useQuotes();

  const [tabName, setTabName] = React.useState(TabNames.Cart);

  const [popupToggle, setPopupToggle] = React.useState<boolean>(!isPopup);

  const cartOpenState = useSelector((state: State) => state.cart.cartOpen);
  const quoteOpenState = useSelector((state: State) => state.cart.quoteOpen);

  useEffect(() => {
    props.onToggle?.(popupToggle);
  }, [popupToggle]);

  useEffect(() => {
    if (props.setShowCartPopup) {
      setPopupToggle(isPopup);
    }
  }, [isPopup]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (authState?.userToken) {
      getCart(() => {
        setRendered(true);
      });
      // getQuotations();
    }
  }, [
    brandState?.selectedBrand?.brandID,
    brandState?.selectedBranch?.warehouseID,
  ]);

  const [isRendered, setRendered] = useState(false);
  // const userStatusFromQuotation = quoteState?.quoteDetails?.userStatus || '';
  // const quoteList = quotations ?? [];

  React.useEffect(() => {
    if (cartOpenState === true) {
      setTabName(TabNames.Cart);
    }
    if (quoteOpenState === true) {
      setTabName(TabNames.Quotes);
    }
  }, [cartOpenState, quoteOpenState]);

  const accountCode = authState.checkedInCustomer?.brandWithAccountNumber?.find(
    (p) => p.brandID === brandState?.selectedBrand?.brandID
  )?.accountNumber;

  return (
    <>
      {loaded && (
        <div
          className={`cartBlock border-left border-bottom bg-white ${cartOpenState === true || quoteOpenState === true ? 'active' : ''
            }`}
        >
          {authState.userType === UserType.Salesperson &&
            authState.checkedInCustomer && (
              <div className="px-lg-4 p-3 border-bottom bg-white">
                <div className="row gutter-12 align-items-center">
                  <div className="col minw-0">
                    <h6 className="text-truncate m-0 font-base font-weight-bold">
                      {decodeHtmlEntities(
                        authState.checkedInCustomer?.customerName ?? ''
                      )}
                    </h6>
                    {accountCode != null && (
                      <p className="m-0 font-sm">
                        <span className="text-muted">
                          {translate('customerListing.accountCode')}
                          {' : '}
                        </span>
                        {
                          authState.checkedInCustomer?.brandWithAccountNumber?.find(
                            (p) =>
                              p.brandID === brandState?.selectedBrand?.brandID
                          )?.accountNumber
                        }
                      </p>
                    )}
                  </div>
                  <div className="col-auto">
                    <Button
                      className="Button__primary promo-btn font-md"
                      size="sm"
                      type="submit"
                      onClick={() => {
                        if (authState.checkedInCustomer) {
                          dispatch(checkInAction(authState.checkedInCustomer));
                        }
                      }}
                    >
                      {translate('Home.Checkout')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          <div className="p-3 d-lg-block d-none border-bottom border-light">
            <div className="px-1 py-px">
              <div className="row gutter-10 align-items-center">
                <div className="col minw-0">
                  <span
                    onClick={() => {
                      setTabName(TabNames.Cart);
                      if (props?.setShowCartPopup) {
                        props.setShowCartPopup(true);
                      } else {
                        setPopupToggle(true);
                      }
                    }}
                    className="font-md font-weight-bold"
                  >
                    {t('cart.My_Cart')}
                  </span>
                </div>
                <div className="col-auto">
                  {!!cart?.items?.length && tabName === TabNames.Cart && (
                    <span className="font-md text-muted font-weight-medium">
                      {translate('Home.Items_in_Cart')}{' '}
                      <span className="text-dark">{cart.items?.length}</span>
                    </span>
                  )}
                  {/* {!!quotations?.length && tabName === TabNames.Quotes && (
                    <span className="font-md cursor-pointer text-muted d-inline-block">
                      {translate('Home.Items_in_Quote')}{' '}
                      <span className="text-dark font-weight-medium">
                        {quoteList?.length}
                      </span>
                    </span>
                  )} */}
                  {(isPopup || props.setShowCartPopup) && (
                    <div
                      onClick={() => {
                        if (props.setShowCartPopup) {
                          props.setShowCartPopup(!isPopup);
                        } else {
                          setPopupToggle(!popupToggle);
                        }
                      }}
                      className="cursor-pointer border border-primary rounded border-width-2 font-weight-bold d-flex align-items-center justify-content-center"
                      style={{ width: '28px', height: '28px' }}
                    >
                      <i
                        className={
                          (!popupToggle
                            ? 'icon-chevron-down'
                            : 'icon-chevron-up') +
                          ` text-primary font-xxxs font-weight-bold`
                        }
                      ></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <ul className="searchBox__tabs border-0 font-xs text-gray-600 p-0 list-unstyled m-0 d-inline-flex font-weight-medium overflow-auto text-nowrap">
            <li
              onClick={() => {
                setTabName(TabNames.Cart);
                if (props?.setShowCartPopup) {
                  props.setShowCartPopup(true);
                } else {
                  setPopupToggle(true);
                }
              }}
              className={classnames({
                'position-relative mr-5 cursor-pointer': true,
                'searchBox__tabs--active text-gray-900 cartBlock__header mr-5':
                  tabName === TabNames.Cart,
              })}
            >
              {t('cart.My_Cart')}{' '}
              <span className="text-gray-600 ml-2">
                ({myList?.length || 0})
              </span>
            </li>
            <li
              onClick={() => {
                setTabName(TabNames.Quotes);

                if (props?.setShowCartPopup) {
                  props.setShowCartPopup(true);
                } else {
                  setPopupToggle(true);
                }
              }}
              className={classnames({
                'position-relative cursor-pointer': true,
                'searchBox__tabs--active text-gray-900 cartBlock__header':
                  tabName === TabNames.Quotes,
              })}
            >
              {translate('Home.My_Quotes')}{' '}
            </li>
          </ul> */}
          {popupToggle && (
            <>
              {cartOpenState && (
                <div className="border-bottom p-3 d-flex align-items-center font-weight-bold font-xl text-gray-900 bg-white d-lg-none">
                  <i
                    className="icon-arrow-left mr-3 font-lg"
                    onClick={() => dispatch(cartOpen(!cartOpenState))}
                  ></i>
                  {translate('Home.My_Cart')}
                </div>
              )}
              {quoteOpenState && (
                <div className="border-bottom p-3 d-flex align-items-center font-weight-bold font-xl text-gray-900 bg-white d-lg-none">
                  <i
                    className="icon-arrow-left mr-3 font-lg"
                    onClick={() => dispatch(quoteOpen(!quoteOpenState))}
                  ></i>
                  {translate('Home.My_Quotations')}
                </div>
              )}
              <div
                className={`cartPopup custom-scroll-vertical m-0 d-flex flex-column ${popupToggle ? 'isOpen' : ''
                  } ${authState.userType === UserType.Salesperson &&
                    authState.checkedInCustomer
                    ? 'sm'
                    : ''
                  }`}
              >
                {tabName === TabNames.Cart && isRendered && (
                  <CartComponent
                    cart={cart}
                    canEditCart={false}
                  ></CartComponent>
                )}
                {!isRendered && (
                  <ContainerLoader />
                )}
                {/* {tabName === TabNames.Quotes && (
                  <QuoteComponent
                    quoteList={quoteList ?? []}
                    quoteUserStatus={userStatusFromQuotation}
                  ></QuoteComponent>
                )} */}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;

export const CartComponent = (props: {
  cart?: ICartData;
  disableSubmit?: boolean;
  showHeader?: boolean;
  disableCounter?: boolean;
  canEditCart?: boolean;
}) => {
  const showHeader = props.showHeader ?? false;
  const [showConfirm, setShowConfirm] = useState(false);
  const [isClearCart, setIsClearCart] = useState(false);

  const authState = useSelector((state: State) => state.authUser);


  const disableCounter = props.disableCounter ?? false;
  const history = useHistory();
  const { hasRole } = useAuth();
  const cart = props.cart;
  const cartItems = cart?.items ?? [];
  const disableSubmit = props.disableSubmit ?? false;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { t } = useTranslation();
  const { bulkDeleteFromCart } = useCart();
  const [loading, setLoading] = useState(false);

  const [isHoliday, setIsHoliday] = useState(false);
  const [holidayMessage, setHolidayMessage] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [showSaveLater, setShowSaveLater] = useState(false);
  const [saveLaterTitle, setSaveLaterTitle] = useState(cart?.saveLaterName ?? '');

  const [showOtp, setShowOtp] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState('');

  const [otpMessage,setOtpMessage] = useState<string>('');

  if (!!!cart?.items?.length) {
    return (
      <div className="cartBlock__item">
        <div className="cartBlock__itemHead">
          {translate('Home.Your_Cart_is_empty')}
        </div>
        <div className="cartBlock__img">
          <img src={empty} alt="" />
        </div>
      </div>
    );
  }
  const delteItems = async (reason: string, otherReason?: string) => {
    try {
      setLoading(true);
      await bulkDeleteFromCart(
        selectedItems.join(','),
        cart?.cartID,
        reason,
        otherReason,
        isClearCart
      )
        .then(() => {
          setSelectedItems([]);
        })
        .catch(() => {
          toastr.error(
            translate('common.error'),
            translate('toast.somethingWentWrong')
          );
        });
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      setLoading(false);
    }
  };

  const holidayCheck = async () => {
    try {
      setDisableButton(true);
      const response: any = await api.model.getBusinessHours({
        cartID: cart?.cartID,
      });
      if (response?.response === 'Success' && response?.result?.holidayMsg === "") {
        if (authState.userType === UserType.User) {
          submitOtp();
        } else {
          history.push(`${RouteKeys.ConfirmCart}`);
          setDisableButton(false);
        }
      } else if (response?.response === 'Failure' && response.result.holidayMsg !== "") {
        setIsHoliday(true);
        setHolidayMessage(response.result.holidayMsg);
        setDisableButton(false);
      } else {
        toastr.error(t('common.error'), response?.errorMsg);
      }
    } catch (err) {
      setDisableButton(false);
      toastr.error(t('common.error'), t('common.something_wrong'));
    }
  };

  const saveToLater = async () => {
    if (saveLaterTitle?.length != 0) {
      try {
        const response: any = await api.model.addSaveToLater({
          cartID: cart?.cartID,
          saveLaterName: saveLaterTitle,
        });
        if (response?.response === 'Success') {
          setShowSaveLater(false);
          setSaveLaterTitle(undefined);
          history.push('/save-for-later');
          toastr.success('Success', `${t('saveLater.save_success')}`);
        } else {
          toastr.error(translate('common.error'), response?.errorMsg);
        }
      } catch (err) {
        toastr.error(
          translate('common.error'),
          translate('toast.somethingWentWrong')
        );
      } finally {
        setShowSaveLater(false);
        setSaveLaterTitle(undefined);
      }
    } else {
      toastr.error('Error', `${t('saveLater.no_title')}`);
    }
  };

  const submitOtp = async () => {
    try {
      setDisableButton(true);
      const response: any = await api.model.cartGetOtp({
        cartID: cart?.cartID,
      });
      if (response?.response === 'Success') {
        toastr.success('Success', `${t('forgot_password.otp_success')}`);
        setShowOtp(true);
        setDisableButton(false);
        setOtpMessage(response?.result?.otpHeadText);
      } else {
        setOtpLoading(false);
        setDisableButton(false);
        toastr.error(t('common.error'), response?.errorMsg);
      }
    } catch (err) {
      setOtpLoading(false);
      setDisableButton(false);
      toastr.error(t('common.error'), t('common.something_wrong'));
    }
  };

  const verifyOtp = async () => {
    if (otp) {
      try {
        setOtpLoading(true);
        const response: any = await api.model.cartVerifyOtp({
          cartID: cart?.cartID,
          otp: otp,
        });
        if (response?.response === 'Success') {
          setOtpLoading(false);
          setShowOtp(false);
          setOtp(undefined);
          history.push(`${RouteKeys.ConfirmCart}`);
          // toastr.success('Success', `${t('forgot_password.otp_success')}`)
        } else {
          setOtpLoading(false);
          toastr.error(t('common.error'), response?.errorMsg);
        }
      } catch (err) {
        setOtpLoading(false);
        toastr.error(t('common.error'), t('common.something_wrong'));
      }
    } else {
      toastr.error('Error', `${t('forgot_password.otp_required')}`);
    }
  };

  return (
    <>
      {showHeader && (
        <div className="p-3 d-lg-block d-none border-bottom border-light">
          <div className="px-1 py-px">
            <div className="row gutter-10 align-items-center">
              <div className="col minw-0 font-md font-weight-bold">
                {t('cart.My_Cart')}
              </div>
              <div className="col-auto">
                <span className="font-md text-muted font-weight-medium">
                  {translate('Home.Items_in_Cart')}{' '}
                  <span className="text-dark">{cartItems.length}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!props?.canEditCart && (
        <div className="px-3 py-1 bg-body border-bottom border-light line-height">
          <div className="d-flex flex-wrap align-items-center px-1 py-px">
            {/* <div>
            <input
              onClick={(event: any) => {
                if (event.target.checked) {
                  setSelectedItems(cartItems?.map((p) => p.cartItemID) ?? []);
                } else {
                  setSelectedItems([]);
                }
              }}
              type="checkbox"
              checked={cartItems.length === selectedItems.length}
              id="checkone1"
            />
            <label
              className="font-xs form-check-label text-muted checkbox-sm"
              htmlFor="checkone1"
            >
              Select all
            </label>
          </div> */}
            {selectedItems.length >= 1 && (
              <span
                onClick={() => {
                  if (cart?.userStatus !== 'Inactive') {
                    setIsClearCart(false);
                    setShowConfirm(true);
                  }
                }}
                className={`mb-0 font-xs font-weight-medium d-inline-flex tooltip left sm position-relative ${cart?.userStatus !== 'Inactive' && 'text-primary'
                  } cursor-pointer`}
                style={{
                  color: cart?.userStatus === 'Inactive' ? '#999' : '',
                }}
              >
                {t('common.delete')}{' '}
                {selectedItems.length === 1
                  ? t('orders.item')
                  : t('Header.Items')}
                {cart?.userStatus === 'Inactive' && (
                  <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                    <p className="font-weight-medium my-1 text-gray-900 font-sm">
                      {t('common.inactive_user_message')}
                    </p>
                  </span>
                )}
              </span>
            )}
            {!props?.canEditCart && (
              <div className="ml-auto pl-3">
                <span
                  onClick={() => {
                    if (cart?.userStatus !== 'Inactive') {
                      setIsClearCart(true);
                      setShowConfirm(true);
                    }
                  }}
                  className={`font-xs font-weight-medium d-inline-flex tooltip sm position-relative ${cart?.userStatus !== 'Inactive' && 'text-primary'
                    } cursor-pointer`}
                  style={{
                    color: cart?.userStatus === 'Inactive' ? '#999' : '',
                  }}
                >
                  {translate('cart.clearCart')}
                  {cart?.userStatus === 'Inactive' && (
                    <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                      <p className="font-weight-medium my-1 text-gray-900 font-sm">
                        {t('common.inactive_user_message')}
                      </p>
                    </span>
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        {cartItems?.map((cartItem, index) => {
          return (
            <CartItemComponent
              canEditCart={props?.canEditCart}
              isChecked={selectedItems.indexOf(cartItem.cartItemID) !== -1}
              onSelectionChange={() => {
                let clone = [...selectedItems];
                if (clone.indexOf(cartItem.cartItemID) === -1) {
                  clone.push(cartItem.cartItemID);
                } else {
                  clone = clone.filter((p) => p !== cartItem.cartItemID);
                }
                setSelectedItems(clone);
              }}
              key={`${cartItem.cartID}_${index}`}
              isViewOnly={disableCounter}
              item={cartItem}
              cartId={cart.cartID}
              cartUserStatus={cart?.userStatus || ''}
            ></CartItemComponent>
          );
        })}
      </div>
      <div className="px-3 pt-3 text-sm-right font-md font-weight-medium">
        <div className="px-1">
          <div className="row gutter-8 pb-px">
            <div className="col-4 col-sm-8">{translate('Home.Sub_Total')}</div>
            <div className="col-8 col-sm-4 text-right">
              <CurrencyFormatter quantity={cart?.orderSubtotal} />
            </div>
          </div>
          <div className="row gutter-8 pb-px font-xs text-muted">
            <div className="col-4 col-sm-8">
              {translate('Home.Shipping_Fee')}
            </div>
            <div className="col-8 col-sm-4 text-right">
              <CurrencyFormatter quantity={cart?.shippingAmount} />
            </div>
          </div>
          <div className="row gutter-8 pb-px font-xs text-muted">
            <div className="col-4 col-sm-8">
              {translate('Home.VAT')}{' '}
              {cart?.taxPercent ? cart?.taxPercent.toNumber() + ' % ' : ''}
            </div>
            <div className="col-8 col-sm-4 text-right">
              <CurrencyFormatter quantity={cart?.orderTaxAmount} />
            </div>
          </div>
          <div className="row gutter-8 font-base font-weight-bold pt-px">
            <div className="col-4 col-sm-8">{translate('Home.Total')}</div>
            <div className="col-8 col-sm-4 text-right">
              <CurrencyFormatter quantity={cart?.orderTotalAmount} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="px-3">
        <div className="promo-block px-1">
          <div className="row gutter-5">
            <div className="col">
              <TextInput
                label=""
                placeholder="Promo Code"
                type="text"
                size="sm"
              ></TextInput>
            </div>
            <div className="col-auto">
              <Button
                className=" Button__primary-outline Button--radius font-weight-bold font-md px-3"
                size="md"
                type="submit"
              >
                {translate('Home.Apply')}
              </Button>
            </div>
          </div>
        </div>
      </div> */}
      {hasRole(UserPermissionTypes.enable_promocode) && (
        <div className="pt-px">
          <div className="col-8 d-flex justify-content-between mt-4">
            <div>
              <input
                type="text"
                placeholder={translate('cart.promo_code')}
                className="form-control"
              />
            </div>
            <div className="pl-3">
              <button className="btn btn-outline-primary p-0 cart-a-btn">
                {translate('cart.apply')}
              </button>
            </div>
          </div>
        </div>
      )}

      {!disableSubmit && (
        <div className="bg-white position-sticky bottom-0 zIndex-9 p-3 mt-auto">
          <div className="px-1">
            <div className="tooltip left sm top position-relative">
              <Button
                className="Button__primary Button--radius w-100 d-flex justify-content-between p-3"
                type="submit"
                onClick={() => {
                  if (hasRole(UserPermissionTypes.approve_order)) {
                    history.push(RouteKeys.ConfirmCart);
                  } else {
                    holidayCheck();
                  }
                }}
                disabled={cart?.userStatus === 'Inactive' || disableButton}
              >
                {hasRole(UserPermissionTypes.approve_order) ? (
                  <span>{translate('cartDetail.proceedToCheckout')}</span>
                ) : (
                  <span>{translate('common.send_request')}</span>
                )}
                <span className="ml-3">
                  <CurrencyFormatter quantity={cart?.orderTotalAmount} />
                </span>
              </Button>
              {cart?.userStatus === 'Inactive' && (
                <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                  <p className="font-weight-medium my-1 text-gray-900 font-sm">
                    {translate('common.inactive_user_message')}
                  </p>
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      <DeleteConfirmationDialog
        title={
          !isClearCart
            ? `${translate('cart.deleteConfirm')} ${selectedItems.length === 1
              ? `${translate('cart.anItem')}`
              : selectedItems.length + ` ${translate('cart.items')}`
            } ${translate('cart.fromCart')}`
            : `${translate('cart.clearCartItems')}`
        }
        onDismiss={() => {
          setShowConfirm(false);
        }}
        onConfirm={(reason: string, otherReason?: string) => {
          setShowConfirm(false);
          delteItems(reason, otherReason);
        }}
        open={showConfirm}
      ></DeleteConfirmationDialog>
      {loading && (
        <div className="position-absolute top-0 left-0 h-100 w-100">
          <FullPageLoader></FullPageLoader>
        </div>
      )}
      <Modal
        isOpen={isHoliday}
        contentClassName="bg-white rounded-lg px-3 px-md-4 py-5"
      >
        <div className="px-px mx-md-1 py-2 text-center">
          <h5 className="text-dark font-weight-bold pb-px">
            {t('others.sorry_for_inconvenience')}
          </h5>
          <p className="font-md font-weight-medium">
            {holidayMessage}
          </p>
          <div className="row pt-1">
            <div className="col-12 mb-3 pb-1">
              <button
                type="button"
                onClick={() => {
                  setIsHoliday(false);
                  setShowSaveLater(true)
                }}
                className="btn btn-primary btn-sm btn-block"
              >
                {t('others.confirm_save')}
              </button>
            </div>
            <div className="col-12 text-center">
              <span
                className="text-primary font-md font-weight-bold py-1 cursor-pointer"
                onClick={() => setIsHoliday(false)}
              >
                {t('common.cancel')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showSaveLater}
        contentClassName="bg-white rounded-lg px-3 px-md-4 pt-4 pb-5"
      >
        <div className="px-px mx-md-1 py-2">
          <h5 className="font-weight-bold pb-px">
            {t('cartDetail.save_later')}
          </h5>
          <div>
            <TextInput
              placeholder={t('saveLater.add_title')}
              label=""
              default={saveLaterTitle}
              onChange={(val: any) => setSaveLaterTitle(val)}
              type="text"
              size="sm"
            />
          </div>
          <div className="row pt-1">
            <div className="col-12 py-2 mb-2">
              <button
                type="button"
                onClick={() => saveToLater()}
                className="btn btn-primary btn-sm btn-block"
              >
                {t('common.save')}
              </button>
            </div>
            <div className="col-12 py-2 text-center">
              <span
                className="text-primary font-md font-weight-bold py-1 cursor-pointer"
                onClick={() => setShowSaveLater(false)}
              >
                {t('common.cancel')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showOtp}
        contentClassName="bg-white rounded-lg px-3 px-md-4 py-5"
      >
        <div className="px-px mx-md-1 py-2 text-center">
          <h5 className="text-dark font-weight-bold pb-px">
            {t('forgot_password.enter_otp')}
          </h5>
          <p className="font-md font-weight-medium">
            {otpMessage}
          </p>
          <OTPInput
            autoFocus
            length={4}
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={(otpInput) => setOtp(otpInput)}
          />
          <div className="text-right pb-4 pt-1">
            <span onClick={() => submitOtp()} className="text-primary cursor-pointer font-weight-semibold font-md">
              {t('forgot_password.resendOtp')}
            </span>
          </div>
          <div className="row pt-1">
            <div className="col-12 mb-3 pb-1">
              <button
                type="button"
                disabled={otpLoading}
                onClick={() => verifyOtp()}
                className="btn btn-primary btn-sm btn-block"
              >
                {t('common.verify')}
              </button>
            </div>
            <div className="col-12 text-center">
              <span
                className="text-primary font-md font-weight-bold py-1 cursor-pointer"
                onClick={() => setShowOtp(false)}
              >
                {t('common.cancel')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const QuoteComponent = (props: {
  quoteList: IQuotation[];
  showHeader?: boolean;
  disableSubmit?: boolean;
  quoteUserStatus?: string;
}) => {
  const quoteList = props.quoteList;
  const history = useHistory();
  const disableSubmit = props.disableSubmit ?? false;
  return (
    <>
      {!!quoteList?.length ? (
        <>
          {props.showHeader && (
            <div className="cartBlock__head">
              <ul className="searchBox__tabs border-0 font-xs text-gray-600 p-0 mx-2 list-unstyled m-0 bg-white d-flex font-weight-medium overflow-auto text-nowrap">
                <li className="position-relative mr-5 cursor-pointer text-gray-900 cartBlock__header mr-5">
                  {translate('Home.My_Quote')}
                </li>
              </ul>
              <div className="cartItem__product-count position-relative justify-content-end">
                <div className="price-block pr-0">
                  <span className="font-md text-gray-600 transform-btm d-inline-block">
                    {translate('Home.Items_in_Quote')}{' '}
                    <span className="text-dark">{quoteList.length}</span>
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="bg-white h-100 d-flex flex-column overflow-auto h-adjust">
            {quoteList?.map((pdt, index) => {
              return (
                <QuoteItem
                  key={`${pdt.partNumber}_${index}`}
                  item={pdt}
                  disabled={props?.showHeader}
                  quoteUserStatus={props?.quoteUserStatus}
                ></QuoteItem>
              );
            })}
          </div>

          {!!quoteList?.length && !disableSubmit && (
            <div className="px-lg-4 p-3">
              <div className="tooltip left sm top position-relative">
                <Button
                  className="my-1 Button__primary w-100 text-capitalize d-flex justify-content-center rounded py-3"
                  type="submit"
                  onClick={() => {
                    history.push(RouteKeys.ConfirmQuote);
                  }}
                  disabled={props?.quoteUserStatus === 'Inactive'}
                >
                  <span className="font-xl">
                    {translate('Home.Send_Request')}
                  </span>
                </Button>
                {props?.quoteUserStatus === 'Inactive' && (
                  <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                    <p className="font-weight-medium my-1 text-gray-900 font-sm">
                      {translate('common.inactive_user_message')}
                    </p>
                  </span>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="cartBlock__item">
            <div className="cartBlock__itemHead">
              {translate('Home.Your_Quote_is_empty')}
            </div>
            <div className="cartBlock__img">
              <img src={empty} alt="" />
            </div>
          </div>
        </>
      )}
    </>
  );
};
