import React, { useEffect, useState } from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import { DropdownList } from 'react-widgets';
import api from '../../api';
import Button from '../../components/button';
import {
  ISearchParams,
  Model,
  PartSuggestion,
  ProductFilter,
} from '../../core/api/models';
import 'react-widgets/dist/css/react-widgets.css';
import classnames from 'classnames';
import { useProduct } from '../../core/hooks';
import { useBrandsAndBranches } from '../../core/hooks/useBrandsAndBranches';
import { isNullOrEmpty, translate } from '../../utils/text-utils';
import { ITabDataWithColumns } from '../orders/order-listing-screen';
import ProductFilterPopUp from './ProductFilterPopUp';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SaveToSearch from './SaveToSearch';

interface SearchTabProps {
  setIsSearched?: (data: boolean) => void;
  urlProps?: UrlProps;
}
export enum SearchTabNames {
  Model = 'Model',
  ProductPartNumber = 'ProductOrPartNumber',
  VINNumber = 'VinNumber',
}
export interface UrlProps {
  tab: SearchTabNames;
  model?: string;
  vin?: string;
  partNo?: string;
  folder?: string;
}

const TabSearch: React.FunctionComponent<SearchTabProps> = (props) => {
  const { hash } = useLocation();
  const searchKey = hash?.replace('#', '');
  const urlData = props?.urlProps;
  const [showSave, setShowSave] = useState(false);
  // const split = searchKey?.split('-');
  // const searchTabStringFromUrl = split?.[0];
  // const defaultSearchValueFromUrl = split?.[1];

  const {
    searchParams,
    selectedFilter,
    isProductLoading,
    setSearchParam,
    setProductFilter,
    getPartSuggestions,
    partSuggestions,
    setPartSuggestions,
  } = useProduct();
  const { brandState } = useBrandsAndBranches();
  const [filterOpen, setFilterOpen] = useState(!!searchParams?.model);
  const suggestionRef = React.useRef<any>(null);
  const [brand, setBrand] = useState<string>();

  useEffect(() => {
    setBrand(brandState.selectedBrand?.brandID ?? '');
  }, [brandState.selectedBrand]);
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (
      suggestionRef.current &&
      !suggestionRef.current.contains(event.target)
    ) {
      setPartSuggestions([]);
    }
  };
  // const {
  //   data: modelsList,
  //   loading,
  //   loaded,
  //   reload,
  // } = useDataLoader<Model[]>(() =>
  //   api.model.getModelsV2(brandState?.selectedBrand?.brandID ?? '')
  // );

  // React.useEffect(() => {
  //   if (loaded) {
  //     reload(brand);
  //   }
  // }, [brandState?.selectedBrand?.brandID]);

  const tabs: ITabDataWithColumns[] = [
    // {
    //   tabTitle: translate('Home.Model'),
    //   tabKey: SearchTabNames.Model,
    // },
    {
      tabTitle: translate('Home.Product_or_part_Number'),
      tabKey: SearchTabNames.ProductPartNumber,
    },
    // {
    //   tabTitle: translate('Home.VIN_Number'),
    //   tabKey: SearchTabNames.VINNumber,
    // },
  ];

  // const selectedTabFromUrl = tabs.find((p) => p.tabKey === urlData?.tab);
  const [selectedTab, setSelectedTab] = React.useState<ITabDataWithColumns>(
    tabs[0]
  );

  const [model, setModel] = useState<Model>();
  const [vin, setVin] = useState('');
  const [pNumber, setPNumber] = useState<any>({
    data: '',
    showSuggestion: false,
  });
  const [folder, setFolder] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const history = useHistory();

  /* Update local state and store state for search when page reloads */
  // useEffect(() => {
  //   if (urlData?.model) {
  //     const modelObject = modelsList?.find((p) => p.id === urlData?.model);
  //     if (modelObject) {
  //       setModel(modelObject);
  //       const sParams: ISearchParams = {
  //         model: modelObject,
  //       };
  //       setSearchParam(sParams);
  //     }
  //   } else {
  //     setModel(undefined);
  //   }
  // }, [modelsList, urlData?.model]);

  useEffect(() => {
    if (urlData?.vin) {
      const sParams: ISearchParams = {
        vin: urlData.vin,
      };
      setVin(urlData.vin);
      setSearchParam(sParams);
    }
    if (urlData?.partNo) {
      const sParams: ISearchParams = {
        partNumber: urlData.partNo,
      };
      setPNumber({ data: urlData.partNo, showSuggestion: false });
      setSearchParam(sParams);
      setShowSave(true);
    }
    if (urlData?.tab) {
      setSelectedTab(tabs.find((t) => t.tabKey === urlData?.tab));
    }
    if (urlData?.folder) {
      const sParams: ISearchParams = {
        folder: urlData.folder,
      };
      setFolder(urlData?.folder);
      setSearchParam(sParams);
      setSelectedTab(tabs.find((t) => t.tabKey === 'ProductOrPartNumber'));
    }
  }, [urlData?.vin, urlData?.partNo, urlData?.tab]);

  useEffect(() => {
    if (
      !isNullOrEmpty(pNumber?.data) &&
      (pNumber?.data?.length ?? 0) >= 3 &&
      pNumber?.showSuggestion
    ) {
      getPartSuggestions(pNumber?.data);
    } else {
      setPartSuggestions([]);
    }
  }, [pNumber?.data]);

  /* Update local state and store state for search when page reloads */

  const onSearch = (modelFromUrl?: Model, tabClick?: boolean, tab?: any) => {
    const currentModel = modelFromUrl ?? model;
    let url = '';

    const params: ISearchParams = {
      model: undefined,
      vin: '',
      partNumber: '',
      folder: '',
    };
    if (!tabClick) {
      url = `/search?brand=${brandState?.selectedBrand?.brandID}`;
      if (currentModel) {
        params.model = currentModel;
        url = url + `&model=${currentModel.id}`;
      } else if (pNumber?.data) {
        params.partNumber = pNumber?.data;
        url = url + `&partNo=${pNumber?.data}`;
      } else if (vin) {
        params.vin = vin;
        url = url + `&vin=${vin}`;
      } else if (folder) {
        params.vin = vin;
        url = url + `&vin=${vin}`;
      }
    } else {
      if (tab == SearchTabNames.Model) {
        url = url;
      } else if (tab == SearchTabNames.ProductPartNumber) {
        url = `/search?brand=${brandState?.selectedBrand?.brandID}&partNo=`;
      } else if (tab == SearchTabNames.VINNumber) {
        url = `/search?brand=${brandState?.selectedBrand?.brandID}&vin=`;
      }
    }
    if (props?.setIsSearched) {
      props?.setIsSearched(true);
    }
    setSearchParam(params);
    if(decodeURI(window.location.pathname + window.location.search) !== url){
      history.replace(url);
    } else {
      window.location.reload()
    }
  };

  const onSearchSuggestion = (data: any) => {
    let url = '';

    const params: ISearchParams = {
      model: undefined,
      vin: '',
      partNumber: '',
      folder: '',
    };

    url = `/search?brand=${brandState?.selectedBrand?.brandID}`;

    params.partNumber = data;
    url = url + `&partNo=${data}`;

    if (props?.setIsSearched) {
      props?.setIsSearched(true);
    }
    setSearchParam(params);
    history.replace(url);
    setPartSuggestions([]);
  };

  useEffect(() => {
    if (
      (selectedTab.tabKey === SearchTabNames.Model && model) ||
      (selectedTab.tabKey === SearchTabNames.VINNumber && vin) ||
      (selectedTab.tabKey === SearchTabNames.ProductPartNumber && pNumber?.data)
    ) {
      setIsDisabled(false);
      setShowSave(true);
    } else {
      setIsDisabled(true);
      setShowSave(false);
    }
  }, [model, vin, pNumber?.data]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  // search part-no on enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });
  return (
    <>
      <div className="position-relative">
        <div className="searchBox__outer rounded-lg p-2">
          <div className="searchBox">
            <div className="row no-gutters align-items-center">
              {selectedTab.tabKey === SearchTabNames.Model && (
                <>
                  <div className="px-4 border-right d-md-flex d-none align-items-center h-100">
                    <img
                      src={brandState.selectedBrand?.brandIcon}
                      className="img-fluid mh-100"
                      alt={brandState.selectedBrand?.brandName ?? ''}
                    />
                  </div>
                  {/* <div className="searchBox__input">
                  <DropdownList
                    filter="contains"
                    busy={!loaded || loading}
                    placeholder={translate('Home.selectModel')}
                    data={modelsList?.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    )}
                    textField="name"
                    valueField="id"
                    defaultValue={model}
                    value={model}
                    disabled={!brand}
                    onChange={(modl) => {
                      setModel(modl);
                    }}
                  ></DropdownList>
                </div> */}
                </>
              )}
              {selectedTab.tabKey === SearchTabNames.ProductPartNumber && (
                <div className="col searchBox__input">
                  <input
                    type="text"
                    placeholder={translate('Home.enterProductOrPartNo')}
                    // defaultValue={pNumber}
                    value={pNumber?.data}
                    onChange={(e) => {
                      setPNumber({
                        data: e.target.value,
                        showSuggestion: true,
                      });
                    }}
                    className="form-control"
                  />
                </div>
              )}
              {selectedTab.tabKey === SearchTabNames.VINNumber && (
                <div className="col-auto searchBox__input">
                  <input
                    type="text"
                    placeholder={translate('Home.enterVinNo')}
                    //   value={vin}
                    defaultValue={vin}
                    onChange={(e) => {
                      setVin(e.target.value);
                    }}
                  />
                </div>
              )}
              <div className="col-auto searchBox__button">
                <Button
                  onClick={() => {
                    onSearch();
                  }}
                  className="Button Button__primary Button--radius"
                  size="md"
                  disabled={isDisabled}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    className="mr-sm-2"
                  >
                    <path
                      d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.5 17.5L13.875 13.875"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    className="d-none d-sm-block"
                    style={{ paddingBottom: '1px' }}
                  >
                    {translate('Home.Search')}
                  </span>
                </Button>
                {/* <Button
              size="xs"
              disabled={isProductLoading}
              onClick={() => {
                setFilterOpen(true);
              }}
              className={`px-2 text-uppercase ${
                brand && model?.id ? 'text-gray-700' : ''
              }`}
            >
              <i
                className={`icon-tool font-md mr-1 position-relative ${
                  brand && model?.id ? 'text-primary' : ''
                }`}
              >
                {props.filterSelected && <span className="filtered"></span>}
              </i>
              {translate('Home.Filter')}
            </Button> */}
              </div>
              {showSave && (
                <div className="col-auto save-to-search-link">
                  <SaveToSearch pNumber={pNumber?.data} />
                </div>
              )}
            </div>
          </div>
        </div>
        {partSuggestions?.length > 0 && (
          <div
            ref={suggestionRef}
            className="search-suggestions position-absolute z-9 w-100 bg-white border rounded p-2 font-sm font-weight-medium"
          >
            {partSuggestions?.map((i: PartSuggestion, index: any) => {
              return (
                <div
                  key={`item-${i.optionSKUPacked}-${index}`}
                  onClick={() => {
                    setPNumber({
                      data: i.optionSKUPacked,
                      showSuggestion: false,
                    });
                    onSearchSuggestion(i.optionSKUPacked);
                  }}
                  className="cursor-pointer item transition rounded px-3 py-2"
                >
                  <span className="font-weight-bold">{i?.optionSKUPacked}</span>{' '}
                  {i?.productTitle}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <ProductFilterPopUp
        productFilters={model?.parameters}
        onSelect={(filter: ProductFilter[]) => {
          setProductFilter(filter);
        }}
        onClose={() => {
          setFilterOpen(false);
        }}
        selectedFilter={selectedFilter}
        filterOpen={filterOpen}
      ></ProductFilterPopUp>
    </>
  );
};

export default TabSearch;
