import React, { useEffect, useState } from 'react';
import './Return.scss';
import { UserHeader } from '../header';
import api from '../../api';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import {
  IReturnListApiResponse,
  ReturnReqCountByStatus,
  ReturnRequests,
} from '../../models/order-models';
import { translate } from '../../utils/text-utils';
import { ITabDataWithColumns } from '../orders/order-listing-screen';
import { FilterTypes } from '../orders/components/order-filter-component';
import { usePaginatedDataLoader } from 'react-remote-data-hooks';
import { PaginatedApiResponse } from '../../core/api/models';
import { ReturnRequestListing } from './components/return-listing';
import { ContainerLoader } from '../../components/loader';
import DateRange from '../../components/form-group/date-range';
import { DateFormats, formatDate } from '../../utils/date-utils';
import Pagination from '../../components/pagination/Pagination';
import { DebounceInput } from 'react-debounce-input';
export enum StatusTabs {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
export const StatusTabNames = {
  Pending: 'return.pending',
  Approved: 'return.approved',
  Rejected: 'return.rejected',
};

const ReturnRequestScreen: React.FunctionComponent = () => {
  const history = useHistory();
  const { tab } = useParams<{
    tab: string;
  }>();

  const tabs: ITabDataWithColumns[] = [
    {
      tabTitle: translate(StatusTabNames.Pending),
      tabKey: StatusTabs.Pending,
      filterTypes: [],
    },
    {
      tabTitle: translate(StatusTabNames.Approved),
      tabKey: StatusTabs.Approved,
      filterTypes: [],
    },
    {
      tabTitle: translate(StatusTabNames.Rejected),
      tabKey: StatusTabs.Rejected,
      filterTypes: [],
    },
  ];
  const [selectedTab, setSelectedTab] = React.useState<ITabDataWithColumns>(
    tabs[0]
  );

  const [date, setDate] = useState<any>();
  const [count, setCount] = useState<ReturnReqCountByStatus>();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [tabName, setTabName] = React.useState(StatusTabNames.Pending);
  const [listSearch, toggleListSearch] = React.useState<boolean>(false);

  useEffect(() => {
    const sTab = tabs.find((tb) => tb.tabKey === tab);
    if (sTab) {
      setSelectedTab(sTab);
    }
  }, [tab]);

  const onDateChange = (value: { startDate: Date; endDate: Date }) => {
    const d = {
      startDate: formatDate(
        new Date(value.startDate),
        DateFormats.YearMonthFormat
      ),
      endDate: formatDate(new Date(value.endDate), DateFormats.YearMonthFormat),
    };
    setDate(d);
  };

  const getTotalPages = () => {
    const ct = count?.[selectedTab.tabKey] ?? 0;

    if (ct > 0) {
      const pageCount = ct / perPage;

      return Math.trunc(pageCount) === pageCount
        ? pageCount
        : Math.trunc(pageCount) + 1;
    }
    return 0;
  };

  return (
    <>
      <UserHeader menuVisible showBrand={false} />

      <div className="inner-wrapper">
        <div className="list-head">
          <div className="px-lg-4 py-lg-2 p-3 border-bottom">
            <div className="row gutter-8 align-items-center py-lg-0 py-1">
              <div className="col-auto d-lg-none d-flex">
                <i
                  className="icon-arrow-left font-xxl icon-rtl font-weight-bold"
                  onClick={() => {
                    history.goBack();
                  }}
                ></i>
              </div>
              <div className="col">
                <h5 className="m-0 font-weight-bold">
                  {translate('return.returnRequests')}
                </h5>
              </div>
              <div className="col-auto d-lg-none d-flex">
                <span
                  className="font-weight-medium text-primary d-inline-flex cursor-pointer"
                  onClick={() => toggleListSearch(!listSearch)}
                >
                  {listSearch === true ? (
                    <i className="icon-cancel font-sm"></i>
                  ) : (
                    <i className="icon-search"></i>
                  )}
                </span>
              </div>
              <div
                className={`col-lg-auto col-12 mt-lg-0 mt-3 list-search ${
                  listSearch === true ? 'active' : ''
                }`}
              >
                <div className="filter-search">
                  <div className="input-group">
                    <DebounceInput
                      value={search}
                      minLength={1}
                      debounceTimeout={600}
                      className="form-control border-0 pr-0 font-md text-lg-right"
                      placeholder={translate('DashBoard.requestID')}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <input
                        type="submit"
                        className="form-control-search btn btn-link border-0 bg-transparent p-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-lg-auto col-12 mt-lg-0 mt-3 list-search ${
                  listSearch === true ? 'active' : ''
                }`}
              >
                <div className="filter-date">
                  <div className="dateRange">
                    <DateRange
                      placeholder={translate('customerScreen.selectDateRange')}
                      onChange={(value: any) => {
                        onDateChange(value);
                      }}
                    ></DateRange>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-block border-bottom px-lg-2">
          <ul className="nav flex-nowrap overflow-auto p-0">
            {tabs.map((p, index) => {
              return (
                <li
                  key={'return-tab-' + index}
                  className={classNames({
                    active: p.tabKey === selectedTab?.tabKey,
                  })}
                  onClick={() => {
                    setSelectedTab(p);
                  }}
                >
                  <h4 className="m-0 p-0 d-lg-block d-none">
                    {' '}
                    {count?.[p.tabKey] ?? 0}
                  </h4>
                  <p className="font-sm font-weight-medium m-0 p-0 text-black-50">
                    {p.tabTitle}{' '}
                    <span className="d-lg-none">
                      ({count?.[p.tabKey] ?? 0})
                    </span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>

        <>
          <ReturnRequestListing
            page={page}
            perPage={perPage}
            date={date}
            filterTypes={[]}
            tab={selectedTab.tabKey}
            setCount={setCount}
            search={search}
          />
          <Pagination
            currentPage={page}
            totalPages={getTotalPages()}
            onPageChange={(pg: number) => {
              setPage(pg);
            }}
            perPage={perPage}
            onPerPageChange={(pg: number) => {
              setPerPage(pg);
            }}
            count={count?.[selectedTab.tabKey] ?? 0}
            label={translate('reports.reports')}
          />
        </>
      </div>
    </>
  );
};

export default ReturnRequestScreen;
