import React, { useEffect, useState } from 'react';
import { AppTable, IColumn } from '../../../components/grid/app-table';
import { IOrderDetails } from '../../../models/order-models';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';
import {
  formatNumber,
  isNullOrEmpty,
  translate,
} from '../../../utils/text-utils';
import { useTranslation } from 'react-i18next';
import { NoResult } from '../../../components/grid/no-result';
import { CurrencyFormatter } from '../../../components/currency-formatter';
import { showTaxPercent } from './cancel-order-items-grid';
export const OrderItemsGrid = (props: {
  data: IOrderDetails;
  status: string;
  columns: IColumn[];
}) => {
  const assignInitialApprovedQty = () => {
    const clone = { ...props.data };
    clone?.items?.forEach((p) => {
      p.approvedQty = parseInt(p.requestedQty, 10);
    });
    return clone;
  };

  useEffect(() => {
    setOrder(assignInitialApprovedQty());
  }, []);

  const [order, setOrder] = useState(props.data);

  useEffect(() => {
    setOrder(props.data);
  }, [props.data]);

  if (!props?.data?.items?.length) {
    return <NoResult />;
  }

  const columns = props.columns;

  return (
    <>
      {order?.items?.length > 0 && (
        <>
          <div className="table-block grid-table tab-table">
            <AppTable
              key={`orderitems_${order?.items?.length}`}
              data={order.items ?? []}
              columns={columns}
              vatPercent={order?.taxPercent}
              showEmptyResult
            ></AppTable>
          </div>
          <PriceSummary data={order}></PriceSummary>
        </>
      )}
    </>
  );
};

export const PriceSummary = (props: { data: IOrderDetails }) => {
  const { t } = useTranslation();
  const order = props.data;
  return (
    <div className="row justify-content-end mt-4">
      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 font-weight-medium">
        <SummaryItem
          label={t('cart.subTotal')}
          amount={order?.orderSubtotal ?? ''}
        ></SummaryItem>

        <SummaryItem
          label={t('cart.shippingFee')}
          amount={order?.shippingAmount ?? ''}
        ></SummaryItem>

        <SummaryItem
          label={t('orders.discount')}
          amount={order?.orderPromoDiscountAmount ?? ''}
        ></SummaryItem>
        <SummaryItem
          label={`${t('cart.VAT')} ${(order?.taxPercent ?? 0)?.toNumber()} %`}
          amount={order?.orderTaxAmount ?? ''}
        ></SummaryItem>

        <div className="row pb-1 mt-3 font-base">
          <div className="col-md-6 col-sm-6 col-6 text-right">
            <p className="m-0 font-weight-bold m-0">{t('orders.grandTotal')}</p>
          </div>
          <div className="col-md-6 col-sm-6 text-right col-6">
            <p className="m-0 font-weight-bold m-0">
              <CurrencyFormatter quantity={order?.orderTotalAmount} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const SummaryItem = (props: { label: string; amount: string }) => {
  let amount = 0;
  try {
    amount = parseFloat(props.amount);
  } catch (err) {
    amount = 0;
  }
  if (amount === 0 || isNaN(amount) ) {
    return <></>;
  }
  return (
    <>
    {amount !== undefined && (
      <div className="row mb-2 font-md">
      <div className="col-md-6 col-sm-6 col-6 text-right">
        <p className="m-0">{props.label}</p>
      </div>
      <div className="col-md-6 col-sm-6 text-right col-6">
        <p className="m-0">
          <CurrencyFormatter quantity={amount} />
        </p>
      </div>
    </div>
    )}
    </>
  );
};

export const PriceItem = (props: {
  amount: string;
  showHiphenIfZero?: boolean;
}) => {
  let amount = 0;
  try {
    const amt = Number(props?.amount)
    amount = Math.round(amt * 100) / 100;
  } catch (err) {
    amount = 0;
  }
  if (amount === 0 && (props?.showHiphenIfZero ?? false)) {
    return <div>-</div>;
  }
  return (
    <div>
      <CurrencyFormatter quantity={amount?.toFixed(2)} />
    </div>
  );
};

export interface IGrid {
  optionSKU: IColumn;
  productOptionName: IColumn;
  requestedQty: IColumn;
  rejectedQty: IColumn;
  approvedQty: IColumn;
  immediatelyAvailable: IColumn;
  backOrderQty: IColumn;
  orderItemPrice: IColumn;
  orderItemSubtotal: IColumn;
  itemDiscountValue: IColumn;
  itemTaxValue: IColumn;
  amtExcludeVat: IColumn;
  itemTotalAmount: IColumn;
  notAvailable: IColumn;
  orderItemQuantity: IColumn;
  deliveredQty: IColumn;
  quantityDelivered: IColumn;
  toBeReturned: IColumn;
  reason: IColumn;
  rejectReason: IColumn;
  vat: IColumn;
  availableInDays: IColumn;
  requestedReservedQty: IColumn;
  orderQuantityReserved: IColumn;
}

export const orderProcessingColumns = () => ({
  optionSKU: {
    key: 'optionSKU',
    title: translate('bulkOrder.partNumber'),
    columnRenderer: (data: any) => {
      return (
        <CopyToClipboard
          onCopy={() =>
            toastr.success(
              translate('orders.copiedToClipboard'),
              translate('orders.copiedToClipboard')
            )
          }
          text={data.optionSKU}
        >
          <span className="cursor-pointer d-flex align-items-center">
            {data.optionSKU}
            <i className="icon-copy font-sm ml-2 text-black-50"></i>
          </span>
        </CopyToClipboard>
      );
    },
  },
  productOptionName: {
    key: 'productOptionName',
    title: translate('orders.item'),
    columnRenderer: (data: any) => {
      return (
        <div>
          <div className="font-md">{data.productOptionName}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {data?.fdNumber && (
              <div className="font-md" style={{ marginRight: '10px' }}>
                FD : <span className="text-gray-600">{data?.fdNumber}</span>
              </div>
            )}
            {data?.mcNumber && (
              <div className="font-md">
                MC : <span className="text-gray-600">{data?.mcNumber}</span>
              </div>
            )}
          </div>
        </div>
      );
    },
  },
  requestedQty: {
    key: 'orderItemQuantity',
    title: translate('orders.requestedQty'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.orderItemQuantity ?? 0)}`}</>;
    },
  },
  rejectedQty: {
    key: 'rejectedQuantity',
    title: translate('orders.rejectedQty'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.rejectedQuantity ?? 0)}`}</>;
    },
  },
  approvedQty: {
    key: 'approvedQty',
    title: translate('orders.approvedQty'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.approvedQty ?? 0)}`}</>;
    },
  },
  deliveredQty: {
    key: 'orderItemQuantity',
    title: translate('orders.quantityDelivered'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.orderItemQuantity ?? 0)}`}</>;
    },
  },
  qtyDelivered: {
    key: 'deliveredQuantity',
    title: translate('orders.DeliveredQty'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.deliveredQuantity ?? 0)}`}</>;
    },
  },
  notAvailable: {
    key: 'notAvailableQty',
    title: translate('orders.notAvailable'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.notAvailableQty ?? 0)}`}</>;
    },
  },
  immediatelyAvailable: {
    key: 'immediatelyAvailable',
    title: translate('orders.immediatelyAvailable'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.immediatelyAvailable ?? 0)}`}</>;
    },
  },
  backOrderQty: {
    key: 'backOrderQty',
    title: translate('orders.availableInBackOrder'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.backOrderQty ?? 0)}`}</>;
    },
  },
  orderItemPrice: {
    key: 'orderItemPrice',
    title: translate('orders.unitPrice'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <PriceItem amount={data.orderItemPrice} />;
    },
  },
  orderItemSubtotal: {
    key: 'orderItemSubtotal',
    title: translate('cart.subTotal'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return (
        <div>
          <PriceItem amount={data.orderItemSubtotal} />
          {/* {data?.totalRetailPrice && (
            <div className="text-muted font-xs">
              {translate('orders.totalRetailPrice')}:{' '}
              <PriceItem amount={data.totalRetailPrice} />
            </div>
          )} */}
        </div>
      );
    },
  },
  orderItemQuantity: {
    key: 'orderItemQuantity',
    title: translate('orders.orderQuantity'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.orderItemQuantity ?? 0)}`}</>;
    },
  },
  orderQuantityReserved: {
    key: 'orderQtyReserved',
    title: translate('orders.orderQuantity'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.orderQtyReserved ?? 0)}`}</>;
    },
  },
  itemDiscountValue: {
    key: 'itemDiscountValue',
    title: translate('orders.discount'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <PriceItem showHiphenIfZero amount={data.itemDiscountValue} />;
    },
  },
  itemTaxValue: {
    key: 'itemTaxValue',
    title: `${translate('cart.VAT')}`,
    isVat: true,
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <PriceItem amount={data.itemTaxValue} />;
    },
  },
  amtExcludeVat: {
    key: 'amtExcludeVat',
    title: translate('orders.amtExclVAT'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <PriceItem amount={data.amtExcludeVat} />;
    },
  },
  itemTotalAmount: {
    key: 'itemTotalAmount',
    title: translate('cart.total'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <PriceItem amount={data.itemTotalAmount} />;
    },
  },
  quantityDelivered: {
    key: 'orderItemQuantity',
    title: translate('orders.quantityDelivered'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.orderItemQuantity ?? 0)}`}</>;
    },
  },
  toBeReturned: {
    key: 'orderItemQuantity',
    title: translate('orders.toBeReturned'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.orderItemQuantity ?? 0)}`}</>;
    },
  },
  reason: {
    key: 'reason',
    title: translate('orders.reason'),
    columnRenderer: (data: any) => {
      return (
        <>
          {!isNullOrEmpty(data?.reason)
            ? data?.reason
            : translate('orders.others')}
        </>
      );
    },
  },
  rejectReason: {
    key: 'rejectReason',
    title: translate('orders.reason'),
    columnRenderer: (data: any) => {
      return (
        <>
          {!isNullOrEmpty(data?.rejectReason)
            ? data?.rejectReason
            : translate('orders.others')}
        </>
      );
    },
  },
  vat: {
    key: 'itemTaxValue',
    title: translate('cart.VAT'),
    rightAlign: true,
    isVat: true,
    columnRenderer: (data: any) => {
      return <PriceItem amount={data.itemTaxValue} />;
    },
  },
  availableInDays: {
    key: 'availLaterQty',
    title: translate('orders.availableInDays'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.availLaterQty ?? 0)}`}</>;
    },
  },
  requestedReservedQty: {
    key: 'requestedQty',
    title: translate('orders.requestedQty'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <>{`${formatNumber(data?.requestedQty ?? 0)}`}</>;
    },
  },
  retailPrice: {
    key: 'partsPrice',
    title: translate('orders.retailPrice'),
    rightAlign: true,
    columnRenderer: (data: any) => {
      return <PriceItem amount={data?.partsPrice ?? 0} />;
    },
  },
});
