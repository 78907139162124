import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import Checkbox from '../../components/form-group/switch-input/SwitchInputSingleLabel';
import { NoResult } from '../../components/grid/no-result';
import { useNotifications } from '../../core/hooks/useNotifications';
import { INotificationItem } from '../../models/order-models';
import { translate } from '../../utils/text-utils';
import { decodeHtmlEntities } from '../../utils/common';

export const NotificationPopup = (props: {
  title?: string;
  subTitle?: string;
  onClose: () => void;
}) => {
  const { notificationState, markAsRead } = useNotifications();

  const storeNotifications = notificationState?.notifications ?? [];
  const [isRendered, setIsRendered] = useState(false);
  const [localNotifications, setNotifications] = useState<INotificationItem[]>(
    []
  );

  const [showUnRead, setShowUnRead] = useState(false);

  const [selectedType, setSelectedType] = useState('All Types');

  useEffect(() => {
    setIsRendered(true);
    setNotifications(getClone(storeNotifications));
  }, []);

  useEffect(() => {
    notificationMarkAsRead();
  }, [localNotifications]);

  const notificationMarkAsRead = () => {
    const unreadIds = localNotifications
      .filter((p) => !p.isRead)
      .map((p) => p.notificationId);
    if (unreadIds.length > 0) {
      markAsRead(unreadIds.join(','));
    }
  };

  const syncNotifications = () => {
    setNotifications(getClone(storeNotifications));
  };

  const getClone = (obj: any) => {
    return JSON.parse(JSON.stringify(storeNotifications));
  };
  const showNewMessagePopup =
    storeNotifications.length > localNotifications.length;

  const types: any[] = [
    ...new Set(localNotifications.map((p) => p.notificationType)),
  ].map((q) => {
    return {
      value: q,
      name: q,
    };
  });
  types.unshift({
    value: 'All Types',
    name: translate('notifications.allTypes'),
  });

  const filteredNotifications = localNotifications
    ?.filter((p) => !p.isRead || !showUnRead)
    .filter(
      (p) => p.notificationType === selectedType || selectedType === 'All Types'
    );

  return (
    <div>
      <div className="notification-popup detail-popup bg-white position-fixed top-0 w-100 h-100 overflow-auto in">
        <div className="px-lg-4 px-3 py-3 border-bottom bg-whtie">
          <div className="row gutter-8 align-items-center">
            <div className="col-auto d-flex">
              <i
                className="icon-close-main text-gray-500 h6 m-0 cursor-pointer mr-lg-2"
                onClick={() => props.onClose()}
              ></i>
            </div>
            <div className="col-auto">
              <h6 className="m-0">{decodeHtmlEntities(props.title ?? '')}</h6>
              <div className="font-md text-muted">{decodeHtmlEntities(props.subTitle ?? '')}</div>
            </div>
            <div className="col font-md">
              <div style={{ width: 182 }}>
                <DropdownList
                  defaultValue={'All Types'}
                  data={types}
                  valueField="value"
                  textField="name"
                  onChange={(val) => {
                    setSelectedType(val.value);
                  }}
                ></DropdownList>
              </div>
            </div>
            <div className="col-auto notification-switch">
              <Checkbox
                label={translate('notifications.onlyShowUnread')}
                gap="no"
                name="unread"
                onChange={(val) => setShowUnRead(val)}
              />
            </div>
          </div>
        </div>
        {showNewMessagePopup && (
          <div className="text-right pt-4 pr-4">
            <label
              onClick={() => {
                syncNotifications();
              }}
              className="time-tag old cursor-pointer"
            >
              {translate('notifications.newNotificationMessage')}
            </label>
          </div>
        )}
        <div className="px-lg-4 px-3 py-4">
          <ul className="notification-list list-unstyled p-0 m-0">
            {filteredNotifications.map((p, index) => {
              return (
                <NotificationItem
                  onItemClick={props.onClose}
                  key={'notiofication-item' + index}
                  item={p}
                ></NotificationItem>
              );
            })}
          </ul>
          {filteredNotifications.length === 0 && <NoResult></NoResult>}
        </div>
      </div>
      {/* add class "show" to "modal-backdrop" for popup backdrop */}
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export const NotificationItem = (props: {
  item: INotificationItem;
  onItemClick: () => void;
}) => {
  const item = props.item;
  const history = useHistory();

  const handleRedirection = () => {
    if (item.notificationType === 'Order') {
      history.replace(`/order-detail/${item.redirectionId}`);
    } else if (item.notificationType === 'ExtensionRequest') {
      history.push(
        `/creditline-extension-request-details/${item.redirectionId}`
      );
    } 
    // else if (item.notificationType === 'Customer') {
    //   history.push(
    //     `/creditline-extension-request-details/${item.redirectionId}`
    //   );
    // } 
    else if (item.notificationType === 'CreditRequest') {
      history.push(`/credit-request-details/${item.redirectionId}`);
    } else if (item.notificationType === 'Return') {
      history.push(`/return-details/${item.redirectionId}`);
    }

    if (item.notificationType !== 'Customer') {
      props.onItemClick();
    }
  };

  return (
    <li
      onClick={() => {
        handleRedirection();
      }}
      className={
        'd-flex flex-wrap position-relative border-bottom pb-3 mb-3 cursor-pointer' +
        (item.isRead ? '' : ' unread')
      }
    >
      <div className="col-md-9 col-12">
        <div className="font-lg mb-2 font-weight-medium">
          {decodeHtmlEntities(item.displayText)}
        </div>
        <div className="d-inline-flex align-items-center bg-body rounded border font-xs py-1 px-2 mr-2">
          <span className="text-muted mr-1">
            {translate('notifications.requestId')}:
          </span>{' '}
          {item.requestId}
        </div>
        <div className="d-inline-flex align-items-center bg-body rounded border font-xs py-1 px-2 mr-2">
          <span className="text-muted mr-1">
            {translate('notifications.customer')}:
          </span>{' '}
          {decodeHtmlEntities(item.customerName)}
        </div>
      </div>
      <div className="col-md-3 col-12 text-right">
        <div className="time font-xs d-inline-block px-2">
          {moment(item.date).fromNow()}
        </div>
      </div>
    </li>
  );
};
