import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import api from '../../api';
import {
  productRequestAction,
  productGetAction,
  productSetModelAction,
  productSetFilterAction,
  productSetModelParamAction,
  productSetBrandParamAction,
  productResetAction,
  setProductSearchParamsAction,
} from '../store';
import {
  ISearchParams,
  Model,
  PartSuggestion,
  Parts,
  ProductFilter,
  ProductResponse,
} from '../api/models';
import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../utils/text-utils';

export const useProduct = () => {
  const productState = useSelector((state: State) => state.product);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState<Parts[]>([]);
  const [partSuggestions, setPartSuggestions] = useState<PartSuggestion[]>([]);

  const getCarsV2 = async (
    modelID: string,
    filters?: ProductFilter[],
    pageNo?: number,
    isLoadMore?: boolean
  ) => {
    try {
      if (!isLoadMore) {
        dispatch(productRequestAction(true));
      }

      const response = await api.model.getCarsV2(modelID, filters, pageNo);
      dispatch(
        productGetAction({
          ...response,
          page: pageNo,
          selectedFilter: filters,
        })
      );
    } catch (error) {
      toastr.error(
        translate('common.error'),
        (error as any)?.response?.data?.message
      );
    } finally {
      dispatch(productRequestAction(false));
    }
  };

  const searchCarsvin = async (
    vinNum: string,
    filters?: ProductFilter[],
    pageNo?: number
  ) => {
    try {
      dispatch(productRequestAction(true));
      const response = await api.model.searchCarsvin(vinNum, filters, pageNo);
      dispatch(
        productGetAction({
          ...response,
          page: pageNo,
          selectedFilter: filters,
        })
      );
    } catch (error) {
      toastr.error(
        translate('common.error'),
        (error as any)?.response?.data?.message
      );
    } finally {
      dispatch(productRequestAction(false));
    }
  };

  const searchPart = async (
    partNo: string,
    page: number,
    limit: number,
    isLoadMore?: boolean,
    folderId?: any
  ) => {
    try {
      setLoading(true);
      if (!isLoadMore) {
        dispatch(productRequestAction(true));
      }
      // dispatch(productRequestAction(true));
      const response = await api.model.searchParts(
        partNo,
        page,
        limit,
        folderId
      );
      if (response.response === 'Success' && !!response.result?.length) {
        const res: ProductResponse = {
          carFilters: [],
          result: [],
          totalRecord: response?.result?.length ?? 0,
          errorMsg: '',
          folderName: response?.folderName,
          status: 'Success',
          page: 1,
          count: response?.count,
          selectedFilter: [],
        };
        dispatch(productGetAction(res));
        if (!isLoadMore) {
          setParts(response.result ?? []);
        } else {
          setParts((prevData) => [...prevData, ...(response.result ?? [])]);
        }
        setLoading(false);
      } else {
        setParts([]);
        const res: ProductResponse = {
          carFilters: [],
          result: [],
          totalRecord: 0,
          folderName: '',
          errorMsg: translate('Home.sorryNoParts'),
          status: 'Failure',
          page: 1,
          count: 0,
          selectedFilter: [],
        };
        dispatch(productGetAction(res));
      }
    } catch (error) {
      toastr.error(
        translate('common.error'),
        (error as any)?.response?.data?.message
      );
      dispatch(productRequestAction(false));
    } finally {
      dispatch(productRequestAction(false));
    }
  };

  const getPartSuggestions = async (partNo: string) => {
    try {
      setLoading(true);
      const response = await api.model.getPartSuggestion(partNo);
      if (response.response === 'Success' && !!response.result?.length) {
        setPartSuggestions(response.result ?? []);
      } else {
        setPartSuggestions([]);
      }
    } catch (error) {
      toastr.error(
        translate('common.error'),
        (error as any)?.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };

  const setProductModel = async (model?: Model) => {
    dispatch(productSetModelAction(model));
  };

  const setProductFilter = async (filters: ProductFilter[]) => {
    dispatch(productSetFilterAction(filters));
  };

  const setModel = async (model: string) => {
    dispatch(productSetModelParamAction(model));
  };

  const setBrand = async (brand: string) => {
    dispatch(productSetBrandParamAction(brand));
  };

  const resetProduct = async () => {
    dispatch(productResetAction());
  };

  const setSearchParam = async (params: ISearchParams) => {
    dispatch(setProductSearchParamsAction(params));
  };

  const getOfferParts = async () => {
    try {
      dispatch(productRequestAction(true));
      const response = await api.model.getOfferParts();
      if (response.response === 'Success' && !!response.result?.length) {
        setParts(response.result ?? []);
      } else {
        setParts([]);
      }
    } catch (error) {
      toastr.error(
        translate('common.error'),
        (error as any)?.response?.data?.message
      );
      dispatch(productRequestAction(false));
    } finally {
      dispatch(productRequestAction(false));
    }
  };

  return {
    getCarsV2,
    searchCarsvin,
    setProductModel,
    setProductFilter,
    setModel,
    setBrand,
    resetProduct,
    setSearchParam,
    searchPart,
    getPartSuggestions,
    getOfferParts,
    carFilters: productState.carFilters,
    result: productState.result,
    totalRecord: productState.totalRecord,
    count: productState.count,
    folderName: productState.folderName,
    errorMsg: productState.errorMsg,
    status: productState.status,
    loading,
    loaded: productState.isProductLoaded,
    page: productState.page,
    selectedModel: productState.modelDetails,
    selectedFilter: productState.selectedFilter,
    model: productState.model,
    brand: productState.brand,
    searchParams: productState.searchParams,
    isProductLoading: productState.isProductLoading,
    parts,
    setParts,
    partSuggestions,
    setPartSuggestions
  };
};
