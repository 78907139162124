import * as React from 'react';
import { IListFilterProps } from './props';
import { FilterValue } from '../../core/api/models/saved-filters';
import { translate } from '../../utils/text-utils';
import { decodeHtmlEntities } from '../../utils/common';

interface IMultiSelectFilterProps<T = any> extends IListFilterProps<T> {
  allowSearch?: boolean;
  options: any[];
  labelField: string;
  valueField: string;
  value?: FilterValue[];
  keyPrefix?: string;
}

const MultiSelectFilter: React.FunctionComponent<IMultiSelectFilterProps> = (
  props
) => {
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState<FilterValue[]>(
    props.value || []
  );

  React.useEffect(() => {
    setSelected(props.value || []);
  }, [props.value]);

  const onSelect = (value: FilterValue, sel: boolean) => {
    if (sel) {
      setSelected([...(selected || []), value]);
    } else {
      setSelected(selected?.filter((val) => val.value !== value.value) || []);
    }
  };

  const onAllSelected = (isSelected: boolean) => {
    const allValues = !isSelected
      ? []
      : filteredOptions?.map((ff) => {
          return {
            label: ff[props.labelField],
            value: ff[props.valueField],
          };
        });

    setSelected(allValues);
  };

  const onApply = () => {
    setSearch('');
    props.applyFilter?.(selected);
  };

  const onClose = () => {
    setSearch('');
    props.onClose?.();
  };

  const filteredOptions = search
    ? props.options?.filter((op) =>
        op[props.labelField]?.match(new RegExp(search, 'i'))
      )
    : props.options;

  return (
    <>
      <div className="pb-3">
        <div className="d-flex align-items-center justify-content-between bg-body p-3">
          <h6 className="m-0 font-md text-gray-700">
            {translate('common.filterBy')} {props.label}
          </h6>
          <span
            className="cursor-pointer d-inline-flex text-center rounded-circle sub-dropdown-back ml-3"
            onClick={onClose}
          >
            <i className="icon-close font-xs text-gray-700"></i>
          </span>
        </div>
        {props.allowSearch && (
          <div className="search">
            <div className="input-group border-bottom">
              <input
                type="text"
                className="form-control border-0 pr-0"
                placeholder={translate('Home.Search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append">
                <span className="form-control-search btn btn-link border-0 bg-transparent px-3 py-0" />
              </div>
            </div>
          </div>
        )}
        <ul className="list-unstyled p-2 m-0 pl-0 ">
          {!!filteredOptions?.length && (
            <li key="all-0" className="py-2 pl-2 pb-3 border-bottom">
              <input
                type="checkbox"
                id={`all-${props.label}${props.keyPrefix ?? ''}`}
                onChange={(e) => onAllSelected(e.target.checked)}
                checked={
                  !filteredOptions.find(
                    (ff) =>
                      !selected.find(
                        (fff) => ff[props.valueField] === fff.value
                      )
                  )
                }
              />
              <label
                htmlFor={`all-${props.label}${props.keyPrefix ?? ''}`}
                className="font-sm font-weight-semibold"
              >
                {`All ${props.label}`}
              </label>
            </li>
          )}
          {filteredOptions?.map((option, index) => {
            const label = option[props.labelField];
            const value = option[props.valueField];
            // if (!value) {
            //   return <></>;
            // }
            const id = `${label}-${value}-${index}-${props.keyPrefix ?? ''}`;
            return (
              <li key={index} className="py-2 pl-2 ">
                <input
                  type="checkbox"
                  id={id}
                  onChange={(e) => onSelect({ label, value }, e.target.checked)}
                  checked={!!selected?.find((fv) => fv.value === value)}
                />
                <label htmlFor={id} className="font-sm font-weight-semibold">
                  {decodeHtmlEntities(label)}
                </label>
              </li>
            );
          })}
          {filteredOptions?.length === 0 && (
            <div className=" py-2 pl-2 font-xs text-gray-600 mb-2">
              <span className="text-gray-900">
                {translate('orders.No_Result')}
              </span>
            </div>
          )}
        </ul>
        <div className="px-3 text-right">
          <button
            type="button"
            className="btn btn-primary py-2 px-4 font-weight-semibold"
            onClick={onApply}
          >
            <span className="px-2">{translate('orders.Apply')}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default MultiSelectFilter;
