import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/modal';
import './save-search.scss';
import { TextInput } from '../../components/form-group';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { translate } from '../../utils/text-utils';

interface ActionModalProps {
  onClose?: () => void;
  isOpen?: boolean;
  pNumber?: string;
}

const data = [
  'Ford',
  'Lincoln',
  'Lincoln Exterior Parts',
  'Ford Exterior Parts',
  'Accessories',
  'Lincoln Interior Parts',
  'Ford Interior Parts',
];

const SaveToSearch: React.FunctionComponent<ActionModalProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [saveSearch, setSaveSearch] = React.useState(false);
  const [folderID, setFolderID] = React.useState('');
  const [selectedFolder, setSelectedFolder] = React.useState('');
  const [newFolderName, setNewFolderName] = React.useState('');
  const [newFolder, setCreateNewFolder] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);

  const {
    data: folderList,
    loading: folderLoading,
    loaded: folderLoaded,
    reload: reloadAddFolder,
  } = useDataLoader<any>(() => api.user.getSavedSearchFolder());

  const createFolder = async () => {
    if (newFolderName) {
      try {
        const response: any = await api.user.addEditSaveSearchFolder({
          folderName: newFolderName,
        });
        if (response?.response === 'Success') {
          toastr.success(
            translate('common.success'),
            `${t('savedSearch.Added_folder')} ${newFolderName}`
          );
          reloadAddFolder();
          setCreateNewFolder(false);
          setSelectedFolder(response?.result?.folderData?.folderName);
          setFolderID(response?.result?.folderData?.searchFolderID);
        } else {
          toastr.error(
            translate('common.error'),
            `${t('savedSearch.folder_error')}`
          );
        }
      } catch (error) {
        toastr.error(translate('common.error'), `Something went wrong`);
      }
    } else {
      toastr.error('Error', `${t('savedSearch.no_folder_name')}`);
    }
  };

  const createSaveSearch = async () => {
    try {
      const response: any = await api.user.addSaveSearch({
        searchFolderID: folderID,
        searchName: props?.pNumber,
      });
      if (response?.response === 'Success') {
        toastr.success(
          translate('common.success'),
          `${t('savedSearch.Added_search_name')} ${props?.pNumber} ${t(
            'savedSearch.in_to'
          )} ${selectedFolder}`
        );
        setConfirmation(false);
        setSaveSearch(false);
        setFolderID(undefined);
      } else {
        toastr.error(
          translate('common.error'),
          `${t('savedSearch.name_error')}`
        );
      }
    } catch (error) {
      toastr.error(translate('common.error'), `Something went wrong`);
    }
  };

  return (
    <>
      <span
        onClick={() => {
          setSaveSearch(true);
          setFolderID(undefined);
          document.body.classList.add('scrollspace');
        }}
        className="text-primary font-md font-weight-medium d-inline-block px-lg-1 mx-lg-1 cursor-pointer"
      >
        {t('savedSearch.SaveToSearch')}
      </span>
      <Modal
        isOpen={saveSearch}
        contentClassName="save-search-popup bg-white rounded-lg p-0 overflow-y-clip"
      >
        <div className="overflow-auto px-2 py-4">
          <div className="px-px">
            {!confirmation ? (
              <>
                <h5 className="font-weight-bold mx-3 pt-1 pb-3">
                  {t('savedSearch.SaveYourSearchTo')}
                </h5>
                <ul
                  className="list-unstyled folder-list pb-px"
                  style={{ minHeight: '195px' }}
                >
                  {folderList?.folderData?.map((item: any, index: any) => {
                    return (
                      <li
                        className={`font-md font-weight-medium d-flex align-items-center line-height cursor-pointer ${
                          folderID === `${item?.searchFolderID}`
                            ? 'selected'
                            : ''
                        }`}
                        key={index}
                        onClick={() => {
                          setFolderID(`${item?.searchFolderID}`);
                          setSelectedFolder(item?.folderName);
                        }}
                      >
                        <span className="d-inline-block pb-px">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="11"
                            viewBox="0 0 14 11"
                            fill="currentColor"
                          >
                            <path d="M1.4 11C1.015 11 0.685417 10.8654 0.41125 10.5961C0.137083 10.3268 0 10.0031 0 9.625V1.375C0 0.996875 0.137083 0.673177 0.41125 0.403906C0.685417 0.134635 1.015 0 1.4 0H5.0225C5.20917 0 5.38708 0.034375 5.55625 0.103125C5.72542 0.171875 5.87417 0.269271 6.0025 0.395313L6.41648 0.801897C6.79046 1.1692 7.2937 1.375 7.81789 1.375H12.6C12.985 1.375 13.3146 1.50964 13.5887 1.77891C13.8629 2.04818 14 2.37188 14 2.75V9.625C14 10.0031 13.8629 10.3268 13.5887 10.5961C13.3146 10.8654 12.985 11 12.6 11H1.4Z" />
                          </svg>
                        </span>
                        <span className="px-2 mx-1 text-truncate">
                          {item?.folderName}
                        </span>
                        <span className="tick ml-auto">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="8"
                            viewBox="0 0 11 8"
                            fill="currentColor"
                          >
                            <path d="M3.84663 8L0 4.2079L0.961656 3.25988L3.84663 6.10395L10.0383 0L11 0.948025L3.84663 8Z" />
                          </svg>
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <div className="px-3 mx-1">
                  <div className="row gutter-10 align-items-center">
                    <div className="col">
                      <span
                        className="d-inline-flex align-items-center text-primary font-md font-weight-medium cursor-pointer"
                        onClick={() => {
                          setCreateNewFolder(true);
                          setFolderID(undefined);
                        }}
                      >
                        <span className="d-inline-flex mr-2 pr-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="currentColor"
                          >
                            <path d="M2.23078 11.625C1.85193 11.625 1.53125 11.4937 1.26875 11.2312C1.00625 10.9687 0.875 10.648 0.875 10.2692V1.73078C0.875 1.35193 1.00625 1.03125 1.26875 0.76875C1.53125 0.50625 1.85193 0.375 2.23078 0.375H5.78748C5.96824 0.375 6.14204 0.410101 6.30886 0.480301C6.47569 0.550488 6.62064 0.647119 6.74371 0.770194L7.84852 1.875H13.7692C14.148 1.875 14.4687 2.00625 14.7312 2.26875C14.9937 2.53125 15.125 2.85193 15.125 3.23078V10.2692C15.125 10.648 14.9937 10.9687 14.7312 11.2312C14.4687 11.4937 14.148 11.625 13.7692 11.625H2.23078ZM2.23078 10.5H13.7692C13.8365 10.5 13.8918 10.4783 13.9351 10.4351C13.9783 10.3918 14 10.3365 14 10.2692V3.23078C14 3.16346 13.9783 3.10817 13.9351 3.06489C13.8918 3.02162 13.8365 2.99998 13.7692 2.99998H7.38845L5.95334 1.56489C5.92931 1.54086 5.90407 1.52403 5.87763 1.5144C5.85118 1.50479 5.82353 1.49998 5.79468 1.49998H2.23078C2.16346 1.49998 2.10817 1.52162 2.06489 1.56489C2.02162 1.60817 1.99998 1.66346 1.99998 1.73078V10.2692C1.99998 10.3365 2.02162 10.3918 2.06489 10.4351C2.10817 10.4783 2.16346 10.5 2.23078 10.5ZM9.6875 7.31246V8.24998C9.6875 8.40959 9.74134 8.54324 9.84903 8.65093C9.95672 8.75862 10.0904 8.81246 10.25 8.81246C10.4096 8.81246 10.5432 8.75862 10.6509 8.65093C10.7586 8.54324 10.8125 8.40959 10.8125 8.24998V7.31246H11.75C11.9096 7.31246 12.0432 7.25862 12.1509 7.15093C12.2586 7.04324 12.3125 6.90959 12.3125 6.74998C12.3125 6.59037 12.2586 6.45672 12.1509 6.34903C12.0432 6.24134 11.9096 6.1875 11.75 6.1875H10.8125V5.24998C10.8125 5.09037 10.7586 4.95672 10.6509 4.84903C10.5432 4.74134 10.4096 4.6875 10.25 4.6875C10.0904 4.6875 9.95672 4.74134 9.84903 4.84903C9.74134 4.95672 9.6875 5.09037 9.6875 5.24998V6.1875H8.74998C8.59037 6.1875 8.45672 6.24134 8.34903 6.34903C8.24134 6.45672 8.1875 6.59037 8.1875 6.74998C8.1875 6.90959 8.24134 7.04324 8.34903 7.15093C8.45672 7.25862 8.59037 7.31246 8.74998 7.31246H9.6875Z" />
                          </svg>
                        </span>
                        <span>{t('savedSearch.CreateNew')}</span>
                      </span>
                    </div>
                    <div className="col-auto">
                      <div className="row gutter-12 align-items-center">
                        <div
                          className="col-auto text-primary font-md font-weight-bold py-1 cursor-pointer "
                          onClick={() => {
                            setSaveSearch(false);
                            setFolderID(undefined);
                            document.body.classList.remove('scrollspace');
                          }}
                        >
                          {t('common.cancel')}
                        </div>
                        <div className="col-auto">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            disabled={folderID === undefined ? true : false}
                            onClick={() => {
                              setConfirmation(true);
                            }}
                          >
                            {t('common.save')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center py-4 my-3">
                <h5 className="font-weight-bold text-dark mx-3 pt-1 pb-px">
                  {t('savedSearch.YourConfirmationIsRequested')}
                </h5>
                <p className="font-md font-weight-medium mb-4 pb-1">
                  {t('savedSearch.PreserveTheIdentifier')}{' '}
                  <b>'{props?.pNumber}'</b> {t('savedSearch.WithinTheCategory')}{' '}
                  <b>'{selectedFolder}'</b>?
                </p>
                <button
                  type="button"
                  onClick={() => createSaveSearch()}
                  className="btn btn-primary btn-sm"
                >
                  {t('common.ConfirmSave')}
                </button>
                <div className="pt-2 mt-1">
                  <span
                    onClick={() => setConfirmation(false)}
                    className="btn btn-sm text-primary font-md"
                  >
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path d="M5.83698 10.0937L10.3464 14.6032L9.49998 15.4375L3.5625 9.49998L9.49998 3.5625L10.3464 4.39678L5.83698 8.90625H15.4375V10.0937H5.83698Z" />
                    </svg>

                    {t('customerHeader.back')}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <>
          {newFolder && (
            <div className="create-folder-overlay position-absolute top-0 left-0 w-100 h-100 rounded-lg"></div>
          )}
          <div
            className={`create-folder position-absolute left-0 bottom-0 bg-white p-4 w-100 ${
              newFolder ? 'is-open' : ''
            }`}
          >
            <div className="mx-1 px-px">
              <h5 className="font-weight-bold">{t('savedSearch.NewSearch')}</h5>
              <div>
                <TextInput
                  placeholder={t('savedSearch.add_folder_name')}
                  label=""
                  onChange={(val: any) => setNewFolderName(val)}
                  type="text"
                  size="sm"
                />
              </div>
              <div className="row gutter-12 align-items-center justify-content-end">
                <div
                  className="col-auto text-primary font-md font-weight-bold py-1 cursor-pointer"
                  onClick={() => setCreateNewFolder(false)}
                >
                  {t('common.cancel')}
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    onClick={() => createFolder()}
                    className="btn btn-primary btn-sm"
                  >
                    {t('common.save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default SaveToSearch;
