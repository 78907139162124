import React from 'react';
import '../Input.scss';

interface CustomProps {
  default?: string;
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  label?: string;
  gap?: 'sm' | 'md' | 'lg';
  size?: 'lg' | 'sm';
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
  autofocus?: boolean;
  showHidePassword?: boolean; // Add the showHidePassword prop
}

interface CustomState {
  value?: string;
  showPassword?: boolean; // Add state to manage password visibility
}

class PasswordInput extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: '',
    name: '',
    label: '',
    placeholder: '',
    gap: 'md',
    size: 'sm',
    onChange: () => {
      return;
    },
    error: '',
    disabled: false,
    autofocus: false,
    showHidePassword: false, // Default is false
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
      showPassword: false, // Initialize showPassword state
    };
    this.handleChange = this.handleChange.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this); // Bind the toggle method
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
      this.props.onChange(this.props.default);
    }
  }

  handleChange(e: { target: any }) {
    const { value } = e.target;
    this.setState({ value });
    this.props.onChange(value);
  }

  togglePasswordVisibility() {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  render() {
    const {
      size,
      label,
      placeholder,
      name,
      gap,
      error,
      disabled,
      autoComplete,
      showHidePassword, // Destructure the new prop
    } = this.props;
    const extraClassesInput: string[] = [];
    if (size === 'lg') extraClassesInput.push('Input__field--lg');
    const extraClasses = extraClassesInput.join(' ');
    const inputIdCandidate = name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div className={`textField textField--margin-${gap}`}>
        {label && (
          <label className="textField__label" htmlFor={inputId}>
            {label}
          </label>
        )}
        <div className='password-wrapper'>
          <input
            id={inputId}
            className={`textField__input ${extraClasses}`}
            type={this.state.showPassword ? 'text' : 'password'} // Toggle input type
            placeholder={placeholder}
            onChange={this.handleChange}
            value={this.state.value}
            disabled={disabled}
            autoComplete={autoComplete}
            autoFocus={this.props.autofocus}
          />
          {showHidePassword && !!this.state.value && (
            <span
              onClick={this.togglePasswordVisibility}
              className='visibility-icon'
            >
              {this.state.showPassword ? <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#000000"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#000000"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>} {/* Toggle icon */}
            </span>
          )}
        </div>
        {error && <span className="textField__validation">{error}</span>}
      </div>
    );
  }
}

export default PasswordInput;
