import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import api from '../../../api';
import { FullPageLoader } from '../../../components/loader';
import { useAuth, useCart } from '../../../core/hooks';
import { translate } from '../../../utils/text-utils';
import { UserHeader } from '../../header';
import { RouteKeys } from '../../routes/route-keys';
import { InPageConfirmation } from './components/cart-confirm-popup';
import { ConfirmSummary } from './components/confirm-summary';
import { InActiveUserPopup } from '../../../components/inactive-user-popup';
import { CartPriceDetails } from './cart-price-details';
import { CartDetails } from './cart-detail';
import { UserPermissionTypes } from '../../../core/api/models';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../footer/Footer';
import { useLocation } from 'react-router-dom';
import { useOrder } from '../../../core/hooks/useOrder';

const CartConfirmation: React.SFC = () => {
  const { getCart: getCart, cart } = useCart();
  const [loader, setLoader] = useState(false);
  const [orderId, setOrderId] = useState<string>();
  const [status, setStatus] = useState<string>();
  const [saveTitle, setSaveTitle] = useState<string>();

  // const { loadCheckoutDetails } = useOrder(api.order);
  const { hasRole } = useAuth();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<{
    isOpen?: boolean;
    msg?: string;
  }>({ isOpen: false });

  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const priceChange = searchParams.get('priceChange');

  // useEffect(() => {
  //   if (priceChange === 'Yes') {
  //     loadCheckoutDetails(cart?.cartID);
  //   }
  // }, []);

  const onSubmit = async () => {
    try {
      setLoader(true);
      const response = await api.model.submitRequest(cart?.cartID!);
      if (
        response?.errorMsg?.includes('inactive') &&
        response?.response === 'Failure'
      ) {
        setOpenModal({
          isOpen: true,
          msg: response?.errorMsg,
        });
      }
      getCart();
      setOrderId(response.order_id);
      setStatus(response?.dynamicOrderStatus);
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      setLoader(false);
    }
  };

  const onClose = () => {
    setOpenModal({});
  };

  return (
    <div className="min-h-screen d-flex flex-column">
      <UserHeader />
      <div className="d-lg-none bg-white p-3 border-bottom">
        <h6 className="font-weight-bold m-0 py-1">
          {translate('Header.Cart')}
        </h6>
      </div>
      <div className={`container ${orderId ? 'px-0' : ''}`}>
        {!orderId && (
          <div className="row gutter-8 py-4">
            <div className="col-12 col-lg-8 col-xl-9 pb-3 pb-lg-4">
              <div className="pr-xl-3">
                {hasRole(UserPermissionTypes.approve_order) ? (
                  <CartDetails
                    disableSubmit={true}
                    showHeader={true}
                    cart={cart}
                  />
                ) : (
                  <InPageConfirmation
                    onConfirm={onSubmit}
                    text={translate('cartConfirmation.cartVerifyMessage')}
                  />
                )}
              </div>
            </div>
            {hasRole(UserPermissionTypes.approve_order) && (
              <div className="col-12 col-lg-4 col-xl-3 pt-lg-5 mt-lg-2 pb-2">
                <div className="bg-white border rounded-lg shadow p-3 overflow-hidden position-sticky price-block mt-1">
                  <div className="px-1">
                    <CartPriceDetails
                      disableSubmit={false}
                      showHeader={true}
                      cart={cart}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {orderId && (
          <>
            <ConfirmSummary
              redirectionRoute={RouteKeys.Orders + '/' + status}
              header={translate('orders.orderSubmittedSuccessfully')}
              text={translate('orders.pleaseReferRequestId').replace(
                '{orderId}',
                orderId
              )}
            ></ConfirmSummary>
          </>
        )}
        <InActiveUserPopup
          isOpen={openModal.isOpen}
          content={openModal.msg || ''}
          onClose={onClose}
        />
      </div>
      <Footer />
      {loader && <FullPageLoader></FullPageLoader>}
    </div>
  );
};

export default CartConfirmation;
