import React, { Fragment, useState } from 'react';
import Counter from '../../../components/counter';
import { useCart } from '../../../core/hooks';
import { CartItem } from '../../../models/order-models';
import { InActiveUserPopup } from '../../../components/inactive-user-popup';

export const CartCounter = (props: { cartItem: CartItem, cartUserStatus?: string, commaSeparation?: boolean }) => {
  const item = props.cartItem;
  const { updateQuantity } = useCart();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState<{ isOpen?: boolean; msg?: string; }>({ isOpen: false });

  const onClose = () => {
    setOpenModal({});
  }

  const handleUpdateQuantity = async (items, cnt) => {
    setLoading(true)
    const response = await updateQuantity(items, cnt, () => {
      setLoading(false);
    });
    if (response?.errorMsg?.includes("inactive") && response?.response === 'Failure') {
      setLoading(false);
      setOpenModal({
        isOpen: true,
        msg: response?.errorMsg,
      });
    }
  };
  return (
    <Fragment>
      <Counter
        commaSeparation={props.commaSeparation || false}
        isLoading={loading}
        value={item?.orderItemQuantity!}
        onChange={(count) => {
          handleUpdateQuantity(item, count);
        }}
        isProductAddedToCart={true}
        className="numberField"
        updateValue={false}
        defaultValue={item.orderItemQuantity}
        disabled={false}
        userStatus={props?.cartUserStatus}
      />
      <InActiveUserPopup isOpen={openModal.isOpen} content={openModal.msg || ''} onClose={() => onClose()} />
    </Fragment>

  );
};
