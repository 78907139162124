import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import api from '../../api';
import { cartGetAction } from '../store';
import { CartItem, ICartData } from '../../models/order-models';
import { toastr } from 'react-redux-toastr';
import { IAddToCartModel, IAddToQuoteModel } from '../api/models';
import { translate } from '../../utils/text-utils';

export const useCart = () => {
  const cartState = useSelector((state: State) => state.cart);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const getCart = async (onSuccess?: () => void) => {
    setLoading(true);
    const response = await api.model.getCart();
    dispatch(cartGetAction({...response?.cartData, userStatus: response?.userStatus }));
    setLoading(false);
    if (onSuccess) {
      onSuccess();
    }
  };
  const addToCart = async (data: IAddToCartModel) => {
    try {
      setLoading(true);
      const response = await api.model.addToCart(data);

      if (response?.result) {
        dispatch(cartGetAction(response?.result));
        toastr.success(translate('common.success'), translate('toast.itemAddedToCart'));
      } else {
        if (!response?.errorMsg?.includes("inactive") && response?.response === 'Failure') {         
          toastr.error(translate('common.error'), response?.errorMsg ?? 'Unable to add');
        }         
      }
      return response;
    } catch (error) {
      toastr.error(translate('common.error'), (error as any)?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const updateQuantity = async (item: CartItem, count: number, onSuccess?: () => void) => {
    const cart: ICartData = { ...cartState?.cart } as any;

    const index =
      cart?.items?.findIndex(
        (p) =>
          p.productID === item.productID &&
          p.productOptionID === item.productOptionID
      ) ?? -1;
    if (index > -1 && cart?.items?.[index]) {
      // item.isLoading = true;
      cart.items[index] = item;
      dispatch(cartGetAction(cart));
    }
    try {
      const response = await api.model.updateQuantity(item, count);
      if (response?.result) {
        toastr.success(translate('common.success'), translate('toast.itemUpdatedInCart'));      
        dispatch(cartGetAction(response?.result));
        onSuccess?.();
      } else {
        if (response?.errorMsg?.includes("inactive") && response?.response === 'Failure') {
          return response;
        } else{
          toastr.error(
            translate('common.error'),
            response?.errorMsg ?? 'Unable to update quantity'
          );
        }
       
      }

      // toastr.success('Success', 'Mylist updated successfully');
    } catch (error) {
      toastr.error(translate('common.error'), translate('toast.somethingWentWrong'));
      if (index > -1) {
        cart.items[index].isLoading = false;
        dispatch(cartGetAction(cart));
      }
    }
  };

  const bulkDeleteFromCart = async (
    ids: string,
    cartID: string,
    reason: string,
    otherReason: string,
    isClearCart: boolean
  ) => {
    const cart: ICartData = { ...cartState?.cart } as any;

    try {
      const response = await api.model.removeFromCart({
        cartItemID: ids,
        id: cartID,
        reason: reason,
        reasonOther: otherReason,
        isClearCart: isClearCart,
      });
      if (response.response === 'Success') {
        dispatch(cartGetAction(response.result));
        toastr.success(
          translate('common.success'),
          response?.errorMsg
            ? response.errorMsg
            : translate('toast.itemUpdatedInCart')
        );
      }
    } catch (error) {
      toastr.error(translate('common.error'), translate('toast.somethingWentWrong'));
    }
  };

  const removeFromCart = async (item: CartItem, cartID: string) => {
    const cart: ICartData = { ...cartState?.cart } as any;

    try {
      const response = await api.model.removeFromCart({
        cartItemID: item.cartItemID,
        id: cartID,
      });
      if (response.response === 'Success') {
        dispatch(cartGetAction(response.result));
        toastr.success(
          translate('common.success'),
          response?.errorMsg
            ? response.errorMsg
            : translate('toast.itemUpdatedInCart')
        );
      }
    } catch (error) {
      toastr.error(translate('common.error'), translate('toast.somethingWentWrong'));
    }
  }; 
  return {
    getCart,
    updateQuantity,
    addToCart,
    removeFromCart,
    bulkDeleteFromCart,
    cart: cartState.cart,
    loaded: cartState.isCartLoaded,
  };
};
