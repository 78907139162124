/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './UserHeader.scss';
import './dropdown.scss';
import { SideMenu } from '.';
import { isNullOrEmpty, translate } from '../../utils/text-utils';
import LogoImage from '../../assets/logo/images/logo-white.svg';
import LogoRtlImage from '../../assets/logo/images/logo-rtl.svg';
import LogoImage1 from '../../assets/logo/images/logo-sm.svg';
import {
  ArabicLocale,
  changeLanguageAction,
  EnglishLocale,
  ILocale,
  State,
  cartOpen,
  quoteOpen,
  setNotificationPopupVisibilityAction,
} from '../../core/store';
import { RouteKeys } from '../routes/route-keys';
import BrandSelection from './BrandSlelection';
import { useBrandsAndBranches } from '../../core/hooks/useBrandsAndBranches';
import { useAuth, useCart, useProduct } from '../../core/hooks';
import { useQuotes } from '../../core/hooks/useQuotes';
import i18n from '../../i18n';
import {
  ISearchParams,
  Model,
  ProductFilter,
  UserPermissionTypes,
  UserType,
} from '../../core/api/models';
import SideMenuMob from './SideMenuMob';
import { NotificationPopup } from './notification-popup';
import { useNotifications } from '../../core/hooks/useNotifications';
import TabSearch from '../search-tab/search-tab';
import { decodeHtmlEntities } from '../../utils/common';

interface CustomProps {
  menuVisible?: boolean;
  mobHeader?: boolean;
  showBrand?: boolean;
  hideSticky?: boolean;
}

const UserHeaderCanvas: React.SFC<CustomProps> = (props: CustomProps) => {
  const {
    getCarsV2,
    searchCarsvin,
    setSearchParam,
    totalRecord,
    loaded,
    setProductFilter,
    selectedFilter,
    model,
    setModel,
    brand,
    setBrand,
  } = useProduct();
  const { notificationState } = useNotifications();
  const { hasRole } = useAuth();
  const history = useHistory();
  const showBrand = props.showBrand ?? false;

  const cartOpenState = useSelector((state: State) => state.cart.cartOpen);
  const quoteOpenState = useSelector((state: State) => state.cart.quoteOpen);

  const [stateExpand, setStateExpand] = React.useState<boolean>(false);
  const [menuAccountOpen, toggleMenuAccountOpen] =
    React.useState<boolean>(false);
  const [brandSelectionOpen, setBrandSelectionOpen] =
    React.useState<boolean>(false);

  const [searched, setSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [brand, setBrand] = useState('');
  // const [model, setModel] = useState('');
  const [modelDetails, setModelDetails] = useState<Model>();
  const [vinNumber, setVinNumber] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchFilter, toggleSearchFilter] = useState(false);
  const { cart } = useCart();
  const { quotations } = useQuotes();

  const authState = useSelector((state: State) => state.authUser);
  const domain = authState?.userToken ? '/' : '/customers';
  const { brandState, getBrandAndBranches } = useBrandsAndBranches();
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();
  const onLanguageChange = (language: ILocale) => {
    //  i18n.changeLanguage(language.languageKey);
    dispatch(changeLanguageAction(language.languageID));
    // const h1 = document.getElementsByTagName('html')[0]; // Get the first <h1> element in the document
    // const att = document.createAttribute('dir'); // Create a "class" attribute
    // att.value = language.isRtl ? 'rtl' : 'ltr'; // Set the value of the class attribute
    // h1.setAttributeNode(att);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    if (authState?.userToken) {
      getBrandAndBranches(authState?.userToken);
    }
  }, [authState.userToken]);
  const unreadNotificationCount =
    notificationState.notifications?.filter((p) => !p.isRead)?.length ?? 0;
  const getCars = (
    searchbrand?: string | undefined,
    searchmodel?: string | undefined,
    searchVin?: string | undefined,
    filters?: ProductFilter[],
    pageNo?: number
  ) => {
    if (searchbrand && searchmodel) {
      getCarsV2(searchmodel, filters, pageNo);
    } else if (searchVin) {
      searchCarsvin(searchVin, filters, pageNo);
    }
  };
  const { loadNotifications } = useNotifications();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      loadNotifications();
    }
  }, []);

  const onSearch = (selectedBrand: string, selectedmodel: string) => {
    let url = `/search-result?b=${selectedBrand}`;
    if (selectedmodel) {
      url = url + `&m=${selectedmodel}`;
    }

    // history.replace(url);
    setBrand(selectedBrand);
    setModel(selectedmodel);
    setVinNumber('');
    if (selectedBrand && selectedmodel) {
      setSearched(true);
      // window.history.pushState(
      //   {
      //     id: 'homepage',
      //   },
      //   'pageTitle',
      //   url
      // );
      history.replace(url);
      setIsLoading(true);
      getCars(selectedBrand, selectedmodel, '', selectedFilter, 1);
      setFilterOpen(true);
    }
  };

  const onSearchVin = (vin: string) => {
    setSearched(true);
    setIsLoading(true);
    getCars('', '', vin, selectedFilter, 1);
    setBrand('');
    setModel('');
    setVinNumber(vin);
    const url = `/search-result?vin=${vin}`;
    window.history.pushState(
      {
        id: 'homepage',
      },
      'pageTitle',
      url
    );
    // history.replace(url);
  };
  const { pathname } = useLocation();
  return (
    <>
      <header className="header d-lg-block d-none py-1">
        <div className="container py-px">
          <div className="row gutter-8 align-items-center inner">
            <div className="col-auto">
              <div className="menu-exp">
                <div
                  className={` text-white cursor-pointer ${
                    stateExpand === true ? 'active' : ''
                  }`}
                  onClick={() => setStateExpand(!stateExpand)}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="20"
                      viewBox="0 0 25 20"
                      fill="none"
                      stroke="white"
                    >
                      <path
                        d="M2 2H23.3333"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 10H19.3333"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 18H15.3333"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div
              onClick={() => history.push(domain)}
              className="cursor-pointer col-auto"
            >
              <div className="row gutter-8 align-items-center">
                <div className="col-auto">
                  <div className="d-flex align-items-center justify-content-center rounded-circle text-white font-weight-bold h4 m-0 circle-avatar">
                    <span>{authState?.profile?.customerCompanyName[0].toUpperCase()}</span>
                    <span>{authState?.profile?.customerCompanyName[1].toUpperCase()}</span>
                  </div>
                </div>
                <div className="col text-white font-base font-weight-bold text-uppercase pt-px">
                  <div
                    className="text-truncate line-height-normal"
                    style={{ maxWidth: '190px' }}
                    dangerouslySetInnerHTML={{
                      __html: authState?.profile?.customerCompanyName,
                    }}
                  />
                  {!isNullOrEmpty(authState?.profile?.customerCompanyNameArabic) && (
                    <div
                      className="text-truncate line-height-normal"
                      style={{ maxWidth: '190px' }}
                      dangerouslySetInnerHTML={{
                        __html: authState?.profile?.customerCompanyNameArabic,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col d-flex justify-content-end">
              <ul className="list-unstyled font-sm text-uppercase font-weight-bold row align-items-center m-0">
                {((authState.userType === UserType.Salesperson &&
                  authState.checkedInCustomer) ||
                  authState.userType === UserType.Customer ||
                  authState.userType === UserType.User) && (
                  <li className="col-auto">
                    <div
                      onClick={() => {
                        history.push(`${RouteKeys.SavedSearch}`);
                      }}
                      className="d-flex align-items-center cursor-pointer text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="22"
                        viewBox="0 0 14 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_920_8555)">
                          <path
                            d="M3.4 1.5H10.6C11.2365 1.5 11.847 1.75022 12.2971 2.19562C12.7471 2.64102 13 3.24511 13 3.875V20.5L7 16.9375L1 20.5V3.875C1 3.24511 1.25286 2.64102 1.70294 2.19562C2.15303 1.75022 2.76348 1.5 3.4 1.5Z"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_920_8555">
                            <rect width="14" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className="m-0 pl-2">
                        {translate('headerCanvas.savedSearch')}
                      </p>
                    </div>
                  </li>
                )}
                {hasRole(UserPermissionTypes.enable_offers) && (
                  <li className="col-auto">
                    <div
                      onClick={() => {
                        history.push(`${RouteKeys.Offers}`);
                      }}
                      className="d-flex align-items-center cursor-pointer text-white ml-xl-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_34_269)">
                          <path
                            d="M8.65234 15.3219L15.3203 8.6543"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.20801 9.76556C9.51489 9.76556 9.76367 9.5168 9.76367 9.20993C9.76367 8.90306 9.51489 8.6543 9.20801 8.6543C8.90112 8.6543 8.65234 8.90306 8.65234 9.20993C8.65234 9.5168 8.90112 9.76556 9.20801 9.76556Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14.7646 15.3222C15.0715 15.3222 15.3203 15.0734 15.3203 14.7666C15.3203 14.4597 15.0715 14.2109 14.7646 14.2109C14.4578 14.2109 14.209 14.4597 14.209 14.7666C14.209 15.0734 14.4578 15.3222 14.7646 15.3222Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.20819 6.65383C4.20819 6.00543 4.46578 5.38359 4.92429 4.92511C5.3828 4.46662 6.00467 4.20905 6.65311 4.20905H7.76443C8.41 4.20868 9.02922 3.95301 9.48699 3.49784L10.2649 2.71995C10.4921 2.49147 10.7623 2.31016 11.0598 2.18644C11.3573 2.06272 11.6764 1.99902 11.9986 1.99902C12.3208 1.99902 12.6399 2.06272 12.9374 2.18644C13.2349 2.31016 13.505 2.49147 13.7323 2.71995L14.5102 3.49784C14.968 3.95301 15.5872 4.20868 16.2327 4.20905H17.3441C17.9925 4.20905 18.6144 4.46662 19.0729 4.92511C19.5314 5.38359 19.789 6.00543 19.789 6.65383V7.7651C19.7894 8.41063 20.045 9.02982 20.5002 9.48756L21.2782 10.2654C21.5067 10.4926 21.688 10.7628 21.8117 11.0603C21.9354 11.3578 21.9991 11.6768 21.9991 11.999C21.9991 12.3212 21.9354 12.6403 21.8117 12.9378C21.688 13.2353 21.5067 13.5054 21.2782 13.7326L20.5002 14.5105C20.045 14.9682 19.7894 15.5874 19.789 16.2329V17.3442C19.789 17.9926 19.5314 18.6145 19.0729 19.0729C18.6144 19.5314 17.9925 19.789 17.3441 19.789H16.2327C15.5872 19.7894 14.968 20.045 14.5102 20.5002L13.7323 21.2781C13.505 21.5066 13.2349 21.6879 12.9374 21.8116C12.6399 21.9353 12.3208 21.999 11.9986 21.999C11.6764 21.999 11.3573 21.9353 11.0598 21.8116C10.7623 21.6879 10.4921 21.5066 10.2649 21.2781L9.48699 20.5002C9.02922 20.045 8.41 19.7894 7.76443 19.789H6.65311C6.00467 19.789 5.3828 19.5314 4.92429 19.0729C4.46578 18.6145 4.20819 17.9926 4.20819 17.3442V16.2329C4.20782 15.5874 3.95214 14.9682 3.49694 14.5105L2.71901 13.7326C2.49052 13.5054 2.3092 13.2353 2.18547 12.9378C2.06174 12.6403 1.99805 12.3212 1.99805 11.999C1.99805 11.6768 2.06174 11.3578 2.18547 11.0603C2.3092 10.7628 2.49052 10.4926 2.71901 10.2654L3.49694 9.48756C3.95214 9.02982 4.20782 8.41063 4.20819 7.7651V6.65383Z"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_34_269">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className="m-0 pl-2">{translate('Header.OFFERS')}</p>
                    </div>
                  </li>
                )}
                {((authState.userType === UserType.Salesperson &&
                  authState.checkedInCustomer &&
                  hasRole(UserPermissionTypes.bulk_order)) ||
                  ((authState.userType === UserType.Customer ||
                    authState.userType === UserType.User) &&
                    hasRole(UserPermissionTypes.bulk_order))) && (
                  <li className="col-auto">
                    <Link
                      to={RouteKeys.BulkOrder}
                      className="d-flex align-items-center cursor-pointer text-white ml-xl-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_34_257)">
                          <path
                            d="M14.1111 19.7778H5.22222C4.63285 19.7778 4.06762 19.5437 3.65087 19.1269C3.23413 18.7102 3 18.1449 3 17.5556V4.22222C3 3.63285 3.23413 3.06762 3.65087 2.65087C4.06762 2.23413 4.63285 2 5.22222 2H18.5556V7.55556"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.2227 15.3333L18.556 12L21.8893 15.3333"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.5547 12V22"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_34_257">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className="m-0 pl-2">
                        {translate('Header.BULK_ORDER')}
                      </p>
                    </Link>
                  </li>
                )}
                <li className="col-auto">
                  <span
                    className={`d-flex align-items-center cursor-pointer text-white ml-xl-3 font-arabic line-height ${
                      settings?.locale?.languageID === 1 ? 'align-text-top' : ''
                    }`}
                    onClick={() => {
                      if (settings.locale.languageID === 1) {
                        onLanguageChange(new ArabicLocale());
                      } else {
                        onLanguageChange(new EnglishLocale());
                      }
                    }}
                  >
                    {settings?.locale?.languageID === 1 ? 'عربي' : 'English'}
                  </span>
                </li>
                <li className="col-auto">
                  <div
                    className={`d-flex align-items-center cursor-pointer text-white ml-xl-3 notification ${
                      unreadNotificationCount > 0 ? 'mr-3' : ''
                    }`}
                    onClick={() => {
                      dispatch(setNotificationPopupVisibilityAction(true));
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="23"
                      viewBox="0 0 17 23"
                      fill="none"
                    >
                      <path
                        d="M16.7088 15.5406L15.6923 14.8629V8.97949C15.6923 5.23404 12.8144 2.14867 9.15385 1.81721V0.653846C9.15385 0.292749 8.8611 0 8.5 0C8.1389 0 7.84615 0.292749 7.84615 0.653846V1.81721C4.18562 2.14867 1.30769 5.23404 1.30769 8.97949V14.8629L0.29118 15.5406C0.109236 15.6619 0 15.866 0 16.0846V18.7C0 19.0611 0.292749 19.3538 0.653846 19.3538H4.96378C5.2707 21.0376 6.73902 22.3179 8.5 22.3179C10.2609 22.3179 11.7293 21.0376 12.0362 19.3538H16.3462C16.7073 19.3538 17 19.0611 17 18.7V16.0846C17 15.866 16.8907 15.6618 16.7088 15.5406ZM8.5 21.0103C7.46304 21.0103 6.58489 20.3102 6.30486 19.3538H10.6952C10.4151 20.3102 9.53696 21.0103 8.5 21.0103ZM15.6923 18.0462C15.0855 18.0462 2.02195 18.0462 1.30769 18.0462V16.4345L2.32421 15.7568C2.50611 15.6356 2.61538 15.4314 2.61538 15.2128V8.97949C2.61538 5.73471 5.25522 3.09487 8.5 3.09487C11.7448 3.09487 14.3846 5.73471 14.3846 8.97949V15.2128C14.3846 15.4315 14.4939 15.6356 14.6758 15.7568L15.6923 16.4345V18.0462Z"
                        fill="white"
                      />
                    </svg>
                    {unreadNotificationCount > 0 && (
                      <span className="noti position-absolute">
                        {unreadNotificationCount}
                      </span>
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-auto d-flex">
              <Link to={`${domain}`} className="Logo">
                {settings?.locale?.isRtl ? (
                  <img
                    src={LogoRtlImage}
                    alt="Logo"
                    className="d-none d-xl-flex ml-3"
                  />
                ) : (
                  <img
                    src={LogoImage}
                    alt="Logo"
                    className="d-none d-xl-flex ml-3"
                  />
                )}
                <img src={LogoImage1} alt="Logo" className="d-xl-none ml-3" />
              </Link>
            </div>
          </div>
          {/* {showBrand && (
            <div className="col-auto mt-sm-0 mt-3">
              <div
                className="d-inline-flex align-items-baseline font-md text-gray-600 cursor-pointer"
                onClick={() => setBrandSelectionOpen(true)}
              >
                <span className="font-weight-bold text-gray-900 border-bottom border-dark border-width-2 mr-2">
                  {decodeHtmlEntities(
                    brandState?.selectedBrand?.brandName ?? ''
                  )}
                </span>
                {decodeHtmlEntities(
                  brandState?.selectedBranch?.warehouseName ?? ''
                )}
                <i className="icon-arrow-down text-primary ml-2 pl-1 font-sm"></i>
              </div>
            </div>
          )} */}
        </div>
      </header>
      {props.mobHeader && (
        <header className="d-lg-none header-mobile py-2 bg-primary position-relative">
          <div className="container-fluid">
            <div className="row gutter-8 align-items-center">
              <div className="col">
                <Link to={`${domain}`} className="Logo">
                  {settings?.locale?.isRtl ? (
                    <img src={LogoImage} alt="Logo" />
                  ) : (
                    <img src={LogoRtlImage} alt="Logo" />
                  )}
                </Link>
              </div>
              <div className="col-auto">
                <div className="d-flex align-items-center">
                  <i className="icon-social-media h5 mb-0 text-white mr-3 position-relative">
                    {unreadNotificationCount > 0 && (
                      <span className="noti-count font-xs text-white position-absolute rounded-pill text-center">
                        {unreadNotificationCount}
                      </span>
                    )}
                  </i>
                  <div
                    className={`language-switch rounded-pill d-inline-flex align-items-center text-center position-relative ${
                      settings.locale.languageID === 1 ? 'en' : 'ar'
                    }`}
                    onClick={() => {
                      if (settings.locale.languageID === 1) {
                        onLanguageChange(new ArabicLocale());
                      } else {
                        onLanguageChange(new EnglishLocale());
                      }
                    }}
                  >
                    <span className="en">EN</span>
                    <span className="ar">AR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mob-search position-absolute w-100 bg-white rounded d-flex no-gutters align-items-center shadow">
            <div className="col px-2 minw-0">
              <div
                className="d-inline-flex align-items-baseline font-md text-gray-600 cursor-pointer w-100"
                onClick={() => setBrandSelectionOpen(true)}
              >
                <span className="font-weight-bold text-gray-900 text-uppercase text-nowrap">
                  {decodeHtmlEntities(
                    brandState?.selectedBrand?.brandName ?? ''
                  )}{' '}
                  -&nbsp;
                </span>
                <span className="text-truncate">
                  {decodeHtmlEntities(
                    brandState?.selectedBranch?.warehouseName ?? ''
                  )}
                </span>
                <i className="icon-arrow-down text-gray-700 ml-2 pl-1 font-xxxs"></i>
              </div>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => {
                  // toggleSearchFilter(true)
                  const params: ISearchParams = {
                    model: undefined,
                    vin: '',
                    partNumber: '',
                    folder: '',
                  };
                  setSearchParam(params);
                  history.push(`${RouteKeys.SearchResult}`);
                }}
              >
                {translate('Header.Search')}
              </button>
            </div>
          </div> */}
        </header>
      )}
      <SideMenu
        isExpand={stateExpand}
        expandToggle={() => setStateExpand(!stateExpand)}
        menuVisible={props.menuVisible}
      />
      {menuAccountOpen && (
        <SideMenuMob
          isExpand={menuAccountOpen}
          expandToggle={() => setStateExpand(!menuAccountOpen)}
          menuVisible={props.menuVisible}
        />
      )}
      {/* <BrandSelection
        isOpen={brandSelectionOpen}
        togglePopup={() => setBrandSelectionOpen(false)}
      /> */}
      <footer
        className={`d-lg-none position-fixed bottom-0 left-0 w-100 zIndex-9 bg-white text-center footer-mobile ${
          props.hideSticky ? 'd-none' : 'd-lg-none'
        }`}
      >
        <div className="container-fluid py-px">
          <div className="row gutter-12">
            <div className="col">
              <Link
                to="/"
                onClick={() => {
                  if (menuAccountOpen === true) {
                    toggleMenuAccountOpen(false);
                  }
                }}
                className={`py-2 footer-menu d-inline-flex flex-column ${
                  pathname === '/' && !menuAccountOpen ? 'active' : ''
                }`}
              >
                <i className="icon-footer-home mb-1"></i>
                <p className="m-0 font-xs">{translate('Header.Home')}</p>
              </Link>
            </div>
            <div className="col">
              <div onClick={() => toggleMenuAccountOpen(true)}>
                <span
                  className={`py-2 footer-menu d-inline-flex flex-column cursor-pointer ${
                    menuAccountOpen ? 'active' : ''
                  }`}
                >
                  <i className="icon-footer-account mb-1"></i>
                  <p className="m-0 font-xs">{translate('Header.Account')}</p>
                </span>
              </div>
            </div>
            <div className="col">
              <div
                className={`py-2 d-inline-flex flex-column footer-menu ${
                  pathname === 'search' ? 'active' : ''
                }`}
                onClick={() => {
                  toggleMenuAccountOpen(false);
                  toggleSearchFilter(true);
                }}
              >
                <i className="icon-footer-search mb-1"></i>
                <p className="m-0 font-xs">{translate('Header.Search')}</p>
              </div>
            </div>
            {/* <div className="col">
              <div
                className="py-2 d-inline-flex flex-column footer-menu"
                onClick={() => dispatch(quoteOpen(!quoteOpenState))}
              >
                <i className="icon-footer-quotes mb-1 position-relative">
                  {!!quotations?.length && (
                    <span className="footer-count position-absolute bg-primary text-white font-xxs font-weight-bold rounded-pill">
                      {quotations?.length}
                    </span>
                  )}
                </i>
                <p className="m-0 font-xs">{translate('Header.Quotes')}</p>
              </div>
            </div> */}
            <div className="col">
              <div
                className="py-2 d-inline-flex flex-column footer-menu"
                onClick={() => {
                  history.push('/');
                  dispatch(cartOpen(!cartOpenState));
                }}
              >
                <i className="icon-footer-cart mb-1 position-relative">
                  {!!cart?.items?.length && (
                    <span className="footer-count position-absolute bg-primary text-white font-xxs font-weight-bold rounded-pill">
                      {cart?.items?.length}
                    </span>
                  )}
                </i>

                <p className="m-0 font-xs">{translate('Header.Cart')}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {searchFilter && (
        <div className="d-lg-none position-fixed top-0 left-0 w-100 h-100 bg-body zIndex-9">
          <div className="border-bottom p-3 d-flex align-items-center font-weight-bold font-xl text-gray-900 bg-white">
            <i
              className="icon-arrow-left font-xxl icon-rtl font-weight-bold"
              onClick={() => toggleSearchFilter(false)}
            ></i>
            {/* {brandState?.selectedBrand?.brandName ?? ''}{' '}
            {brandState?.selectedBranch?.warehouseName ?? ''} */}
          </div>
          <div className="p-3">
            <TabSearch
              setIsSearched={(data) => {
                history.push(`${RouteKeys.SearchResult}`);
              }}
            />
          </div>
        </div>
      )}
      {notificationState.isPopupOpened && (
        <NotificationPopup
          title={translate('others.notifications')}
          onClose={() => {
            dispatch(setNotificationPopupVisibilityAction(false));
          }}
        />
      )}
    </>
  );
};

export default UserHeaderCanvas;
