import { FilterTypes } from '../../../containers/orders/components/order-filter-component';
import { filter } from 'lodash';
import {
  ISalesPersonCustomer,
  ISalespersonCustomerFilter,
  ISalespersonFilter,
} from '../../../containers/account-settings/manage-salesperson/manage-sales-person-screen';
import { IBrandBranchData } from '../../../models/brand';
import {
  CartItem,
  ICartData,
  ICartDeleteReason,
  ICheckoutData,
  IDeliveryAddress,
} from '../../../models/order-models';
import {
  Address,
  ApiResponse,
  BannerResponse,
  Brand,
  FreqProdList,
  Group,
  GroupApiResponse,
  GroupResponse,
  MediaFile,
  IAddToCartModel,
  ICartResponse,
  Model,
  MyItems,
  MyList,
  IQuotation,
  PaginatedFreqProdList,
  Parts,
  PartsApiResponse,
  PartsResponse,
  Product,
  ProductApiResponse,
  ProductFilter,
  ProductResponse,
  IAddToQuoteModel,
  IBankDetailsResponse,
  IDocument,
  IAddress,
  IDocumentType,
  IQuoteDetails,
  IDocumentList,
  IQuotationResponse,
  IQuotationDetails,
  ISalesPerson,
  ISalespersonRole,
  ISuggestList,
  IReplaceQuotationParam,
  IReplaceQuoteResponse,
  ISuggestPreviewResponse,
  IBulkEstimatePreviewResponse,
  ISalesmanagerRole,
  IAdduserBrandsParams,
  ICustomerBrands,
  IDocumentUpdateParam,
  UpdateQuotationAPIResponse,
  SalesData,
  PartSuggestion,
} from '../models';
import * as http from './http';
import ApiService from './service';
import { da } from 'date-fns/locale';
import { DateFormats, formatDate } from '../../../utils/date-utils';
import api from '../../../api';

export class ModelsService extends ApiService {

  public async getModels(brand: string): Promise<Model[]> {
    const url = `${this.apiDomain}/fetchapi/getmodels`;
    const data = new FormData();
    data.append('brandID', brand);
    const response = await http.postAsFormData<ApiResponse<Model[]>>(url, data);

    return response?.data?.result;
  }

  public async getBranches(brand: string): Promise<any[]> {
    const url = `${this.apiDomain}/index/listWareHouse/`;
    const data = new FormData();
    data.append('brandID', brand);
    const response = await http.postAsFormData<ApiResponse<any[]>>(url, data);
    return response?.data?.result;
  }
  public async getDistrictList(countryID: string): Promise<any[]> {
    const url = `${this.apiDomain}/user/listStates/`;
    const data = new FormData();
    data.append('countryID', countryID);
    const response = await http.postAsFormData<ApiResponse<any[]>>(url, data);

    return response?.data?.result;
  }

  public async getModelsV2(brand: string): Promise<Model[]> {
    const url = `${this.apiDomain}/partsapi/getmodels`;
    const data = new FormData();
    data.append('brandID', brand);
    const response = await http.postAsFormData<ApiResponse<Model[]>>(url, data);

    return response?.data?.result;
  }

  public async getCars(
    modelID: string,
    filters?: ProductFilter[]
  ): Promise<ProductResponse> {
    const url = `${this.apiDomain}/fetchapi/getCars`;
    const data = new FormData();
    data.append('modelID', modelID);
    const parameterIDs = filters?.filter((f) => f.value)?.map((x) => x.value);
    if (!!parameterIDs?.length) {
      data.append('parameterIDs', parameterIDs?.join());
    }
    const response = await http.postAsFormData<ProductApiResponse<any>>(
      url,
      data
    );

    const { carFilters, result } = response?.data;

    return {
      carFilters,
      result,
    };
  }

  public async getCarsV2(
    modelID: string,
    filters?: ProductFilter[],
    pageNo?: number
  ): Promise<ProductResponse> {
    const url = `${this.apiDomain}/partsapi/getCars`;
    const data = new FormData();
    // data.append('brandID', brand);
    data.append('modelID', modelID);
    data.append('pageID', pageNo ? pageNo?.toString() : '1');

    const parameterIDs = filters?.filter((f) => f.value)?.map((x) => x.value);
    if (!!parameterIDs?.length) {
      data.append('parameterIDs', parameterIDs?.join());
    }
    const apiResponse = await http.postAsFormData<ProductApiResponse<any>>(
      url,
      data
    );

    const { carFilters, result, totalRecord, errorMsg, response } =
      apiResponse?.data;

    return {
      carFilters,
      result,
      totalRecord,
      errorMsg,
      status: response,
    };
  }

  public async searchCarsvin(
    vinNum: string,
    filters?: ProductFilter[],
    pageNo?: number
  ): Promise<ProductResponse> {
    const url = `${this.apiDomain}/fetchapi/searchcarsvin`;
    const data = new FormData();
    data.append('vinNum', vinNum);
    // const parameterIDs = filters?.map((x) => x.value);
    // if (!!parameterIDs?.length) {
    //   data.append('parameterIDs', parameterIDs?.join());
    // }
    const apiResponse = await http.postAsFormData<ProductApiResponse<any>>(
      url,
      data
    );

    const { carFilters, result, totalRecord, errorMsg, response } =
      apiResponse?.data;

    return {
      carFilters,
      result,
      totalRecord: result?.length,
      errorMsg,
      status: response,
    };
  }

  public async searchParts(
    partNum: string,
    page?: number,
    limit?: number,
    searchFolderID?: any
  ): Promise<ApiResponse<Parts[]>> {
    const url = `${this.apiDomain}/fetchapi/getPartsWithNo/`;
    const data = new FormData();
    data.append('partNumber', partNum);
    data.append('page', page.toString());
    data.append('limit', limit.toString());
    // data.append('brandID', '3');
    // data.append('branchID', '62');
    if (searchFolderID) {
      data.append('searchFolderID', searchFolderID);
    }
    const apiResponse = await http.postAsFormData<ApiResponse<Parts[]>>(
      url,
      data,
      true
    );
    const { result, count, errorMsg, response, status } = apiResponse?.data;

    return {
      ...apiResponse?.data,
      count: count,
      errorMsg,
    };
  }

  public async getPartSuggestion(
    partNum: string,
  ): Promise<ApiResponse<PartSuggestion[]>> {
    const url = `${this.apiDomain}/fetchapi/getPartSuggestion`;
    const data = new FormData();
    data.append('partNumber', partNum);
    const apiResponse = await http.postAsFormData<ApiResponse<PartSuggestion[]>>(
      url,
      data,
      true
    );
    const { result, count, errorMsg, response, status } = apiResponse?.data;

    return {
      ...apiResponse?.data,
      count: count,
      errorMsg,
    };
  }

  public async getGroups(
    brandID: string,
    carid: string,
    groupid?: string
  ): Promise<GroupResponse> {
    const url = `${this.apiDomain}/fetchapi/getGroups`;
    const data = new FormData();
    data.append('brandID', brandID);
    data.append('carid', carid);
    if (groupid) {
      data.append('groupid', groupid);
    }

    const response = await http.postAsFormData<GroupApiResponse<any>>(
      url,
      data
    );

    return response?.data;
  }

  public async getParts(
    brandID: string,
    carid: string,
    groupid: string
  ): Promise<PartsResponse> {
    const url = `${this.apiDomain}/fetchapi/getParts`;
    const data = new FormData();
    // data.append('brandID', brandID);
    data.append('carid', carid);
    data.append('groupid', groupid);
    // data.append('brandID', 'Hyundai');
    // data.append('carid', 'c1090cfb164021303c74efb94680a587');
    // data.append('groupid', 'IzLwn5qANjA2NjNfNjA2NjMxMQ');

    const response = await http.postAsFormData<PartsApiResponse<any>>(
      url,
      data
    );

    return {
      ...response?.data?.result,
      carDetails: response?.data?.carDetails,
    };
  }

  public async getCountryList(isDefault?: boolean): Promise<any> {
    let url = `${this.apiDomain}/user/countries/${isDefault ? '?default=No' : '?default=Yes'
      }`;
    // if (isDefault) {
    //   url = url + '?default=No';
    // } else {
    //   url = url + '?default=Yes';
    // }
    const response = await http.get<ApiResponse<any>>(url);
    return response?.data?.result;
  }

  public async getSalespersonRoleList(): Promise<ISalespersonRole[]> {
    const url = `${this.apiDomain}/salesperson/listsalespersonroles/`;
    const data = new FormData();
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('userToken', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getStateList(countryID: string): Promise<any> {
    const url = `${this.apiDomain}/user/listStates/`;
    const data = new FormData();
    data.append('countryID', countryID);
    data.append('brandID', '');
    data.append('branchID', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  // public async getMyFrequentlyList(
  //   pageNo: number
  // ): Promise<PaginatedFreqProdList> {
  //   const url = `${this.apiDomain}/cart/getMyFrequentlyList`;
  //   const data = new FormData();
  //   data.append('page', pageNo?.toString());
  //   data.append('userToken', this.token ? this.token : '');
  //   const response = await http.postAsFormData<ApiResponse<FreqProdList[]>>(
  //     url,
  //     data
  //   );
  //   const { count, result } = response?.data;
  //   return {
  //     totalCount: count,
  //     result,
  //   };
  // }

  public async getMyFrequentlyList(
    pageNo: number,
    perPage?: number
  ): Promise<PaginatedFreqProdList> {
    const url = `${this.apiDomain}/cart/frequentlyBoughtOrders`;
    const data = new FormData();
    data.append('page', pageNo?.toString());
    if (perPage) {
      data.append('perPage', perPage.toString());
    }
    const response = await http.postAsFormData<ApiResponse<FreqProdList[]>>(
      url,
      data,
      true
    );
    const { count, result } = response?.data;
    return {
      totalCount: count,
      result,
    };
  }

  public async listBanner(): Promise<BannerResponse> {
    const url = `${this.apiDomain}/banner/listBanner`;
    const data = new FormData();

    const response = await http.postAsFormData<ApiResponse<BannerResponse>>(
      url,
      data
    );

    return response?.data?.result;
  }

  // public async getMyList(): Promise<MyList[]> {
  //   const url = `${this.apiDomain}/cart/getMyList`;
  //   const data = new FormData();
  //   data.append('userToken', this.token ? this.token : '');
  //   const response = await http.postAsFormData<ApiResponse<MyList[]>>(
  //     url,
  //     data
  //   );
  //   return response?.data?.result;
  // }

  public async getCart(): Promise<ICartResponse> {
    const url = `${this.apiDomain}/cart/index`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<ICartResponse>>(
      url,
      data,
      true
    );
    return response?.data?.result;
  }

  public async getCompanyBankDetails(): Promise<IBankDetailsResponse> {
    const url = `${this.apiDomain}/company-bank-details`;
    const data = new FormData();
    const response = await http.postAsFormData<
      ApiResponse<IBankDetailsResponse>
    >(url, data);
    return response?.data?.result;
  }

  public async getMyQuotes(): Promise<IQuoteDetails> {
    const url = `${this.apiDomain}/cart/viewQuotation`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<IQuoteDetails>>(
      url,
      data,
      true
    );
    return response?.data?.result;
  }

  public async getBrands(): Promise<Brand[]> {
    const url = `${this.apiDomain}/fetchapi/getBrands`;
    const data = new FormData();

    const response = await http.postAsFormData<ApiResponse<Brand[]>>(url, data);

    return response?.data?.result;
  }

  public async getBrandsWithBranches(
    userToken?: string
  ): Promise<IBrandBranchData[]> {
    const url = `${this.apiDomain}/index/listBrandWithBranches`;
    const data = new FormData();

    if (userToken) {
      data.append('userToken', userToken);
    } else {
      data.append('userToken', '');
    }

    const response = await http.postAsFormData<ApiResponse<IBrandBranchData[]>>(
      url,
      data,
      false
    );
    return response?.data?.result;
  }

  public async getAllBrands(
    userToken?: string
  ): Promise<IBrandBranchData[]> {
    const url = `${this.apiDomain}/index/listAllBrand`;
    const data = new FormData();

    if (userToken) {
      data.append('userToken', userToken);
    } else {
      data.append('userToken', '');
    }

    const response = await http.postAsFormData<ApiResponse<IBrandBranchData[]>>(
      url,
      data,
      false
    );
    return response?.data?.result;
  }

  // public async addToMyList(parts: Parts): Promise<MyList[]> {
  //   const url = `${this.apiDomain}/cart/addToMyList`;
  //   const data = new FormData();
  //   data.append('productID', parts.id);
  //   data.append('productName', parts.name);
  //   data.append('partNumber', parts.number);
  //   data.append('quantity', (parts.quantity || '1')?.toString());
  //   data.append('userToken', this.token ? this.token : '');

  //   const response = await http.postAsFormData<ApiResponse<MyList[]>>(
  //     url,
  //     data
  //   );

  //   return response?.data?.result;
  // }
  public async addToCart(
    parts: IAddToCartModel
  ): Promise<ApiResponse<ICartData>> {
    const url = `${this.apiDomain}/cart/addItem`;
    const data = new FormData();
    data.append('productID', parts.productID);

    data.append('productOptionID', parts.productOptionID);
    data.append('cartID', parts.cartID || '');
    data.append('quantity', parts.quantity);
    const response = await http.postAsFormData<ApiResponse<ICartData>>(
      url,
      data,
      true
    );
    return response?.data;
  }

  public async getDeleteReasons(userToken?: string, salespersonToken?: string): Promise<ICartDeleteReason[]> {
    const url = `${this.apiDomain}/cart/cartDeleteReasons`;
    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }
    if (salespersonToken) {
      data.append('salespersonToken', salespersonToken);
    }

    const response = await http.postAsFormData<ApiResponse<any>>(
      url,
      data,
      true
    );
    return response?.data?.result;
  }

  public async addToQuote(
    parts: IAddToQuoteModel
  ): Promise<ApiResponse<IQuoteDetails>> {
    const url = `${this.apiDomain}/cart/addQuotation`;
    const data = new FormData();
    data.append('partNumber', parts.partNumber);
    data.append('productName', parts.productName);
    data.append('quantity', (parts.quantity || '1')?.toString());
    const response = await http.postAsFormData<ApiResponse<IQuoteDetails>>(
      url,
      data,
      true
    );
    return response?.data;
  }

  public async removeQuote(parts: IQuotation): Promise<IQuoteDetails> {
    const url = `${this.apiDomain}/cart/deleteQuotation`;
    const data = new FormData();
    data.append('quotationRequestID', parts.quotationRequestID);
    data.append('quotationReqItemID', parts.quotationReqItemID);
    const response = await http.postAsFormData<ApiResponse<IQuoteDetails>>(
      url,
      data,
      true
    );
    return response?.data?.result;
  }

  public async addToMyFrequentlyList(parts: FreqProdList): Promise<MyList[]> {
    const url = `${this.apiDomain}/cart/addToMyList`;
    const data = new FormData();
    data.append('productID', parts.productID);
    // data.append('productName', parts.productName);
    // data.append('partNumber', parts.partNumber);
    // data.append('quantity', (parts.quantity || '1')?.toString());
    // data.append('userToken', this.token ? this.token : '');

    const response = await http.postAsFormData<ApiResponse<MyList[]>>(
      url,
      data,
      true
    );

    return response?.data?.result;
  }

  public async updateMyList(parts: MyList): Promise<MyList[]> {
    const url = `${this.apiDomain}/cart/addToMyList`;
    const data = new FormData();
    data.append('productID', parts.productID);
    data.append('productName', parts.productName);
    data.append('partNumber', parts.partNumber);
    data.append('quantity', (parts.quantity || '1')?.toString());
    const response = await http.postAsFormData<ApiResponse<MyList[]>>(
      url,
      data,
      true
    );

    return response?.data?.result;
  }

  public async updateQuantity(
    parts: CartItem,
    count: number
  ): Promise<ApiResponse<ICartData>> {
    const url = `${this.apiDomain}/cart/updateQuantity`;
    const data = new FormData();
    data.append('productOptionID', parts.productOptionID);
    data.append('productID', parts.productID);
    data.append('cartID', parts.cartID);
    data.append('cartItemID', parts.cartItemID);
    data.append('quantity', count.toString());
    const response = await http.postAsFormData<ApiResponse<ICartData>>(
      url,
      data,
      true
    );

    return response?.data;
  }

  public async updateMyQuotationQuantity(parts: any): Promise<UpdateQuotationAPIResponse> {
    const url = `${this.apiDomain}/cart/updateQuotation`;
    const data = new FormData();
    data.append('quotationReqItemID', parts.quotationReqItemID);
    data.append('quantity', parts.quantity?.toString());

    const response = await http.postAsFormData<ApiResponse<IQuoteDetails>>(
      url,
      data,
      true
    );
    return response?.data;
  }

  public async addToFrequentlyList(parts: {
    productID: string;
    productName: string;
    partNumber: string;
    quantity: number;
  }): Promise<MyList[]> {
    const url = `${this.apiDomain}/cart/addToMyFrequentlyList`;
    const data = new FormData();
    data.append('productID', parts.productID);
    data.append('productName', parts.productName);
    data.append('partNumber', parts.partNumber);
    data.append('quantity', (parts.quantity || '1')?.toString());

    const response = await http.postAsFormData<ApiResponse<MyList[]>>(
      url,
      data,
      true
    );

    return response?.data?.result;
  }

  public async addAddress(
    address: IAddress,
    isDelivery?: boolean,
    userToken?: string
  ): Promise<IDeliveryAddress[]> {
    const url = `${this.apiDomain}/user/shipping/`;
    const data = new FormData();
    // if (userToken) {
    //   data.append('userToken', userToken);
    // }
    if (isDelivery) {
      data.append('FromCheckOut', 'Yes');
      data.append('shippingFname_1', address.shippingFname_1);
      data.append('shippingLname_1', address.shippingLname_1);
    } else {
      data.append('shippingFname', address.shippingFname);
      data.append('shippingLname', address.shippingLname);
    }

    data.append('shippingAddress1', address.shippingAddress1);
    data.append('shippingCountryID', address.shippingCountryID);
    data.append('shippingStateID', address.shippingStateID);
    data.append('shippingCity', address.shippingCity);
    data.append('shippingZip', address.shippingZip);
    data.append('shippingPhoneDialCode', address.shippingPhoneDialCode || '');
    data.append('shippingPhone', address.shippingPhone || '');
    data.append('warehouseIDs', address.warehouseIDs);
    data.append(
      'shippingPhoneCountryCode',
      address.shippingPhoneCountryCode || ''
    );

    if (address.userShippingID) {
      data.append('userShippingID', address.userShippingID);
    }
    data.append('action', address.action || '');
    // if (address.userToken) {
    //   data.append('userToken', address.userToken);
    // }
    const response = await http.postAsFormData<ApiResponse<IDeliveryAddress[]>>(
      url,
      data,
      true
    );
    return response?.data?.result ?? [];
  }

  public async checkoutSubmit(body: any, userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/cart/checkoutsubmit/`;
    const data = new FormData();
    data.append('cartID', body.cartID);
    data.append(
      'paymentSettingsGroupKeySelected',
      body?.paymentSettingsGroupKeySelected
    );
    data.append('selfPickup', body.pickupType || 'no');
    data.append('deliveryType', body.deliveryType);
    data.append('poNumber', body.poNumber);
    data.append('poDate', body.poDate);
    if (body.purchaseFile) {
      data.append('purchaseFile', body.purchaseFile);
    }
    if (body?.comments) {
      data.append('comments', body?.comments);
    }
    data.append('orderRequestID', body.orderRequestID);
    data.append('locationID', body.locationID);
    // data.append(
    //   'shippingContactPhoneCountryCode',
    //   body.shippingContactPhoneCountryCode
    // );
    // data.append(
    //   'shippingContactPhoneDialCode',
    //   body?.shippingContactPhoneDialCode
    // );
    // data.append('shippingContactPhone', body.shippingContactPhone);
    // data.append('shippingContactName', body.shippingContactName);
    if (body.userShippingID) {
      data.append('userShippingID', body.userShippingID);
    }
    if (userToken) {
      data.append('userToken', userToken);
    }

    const response = await http.postAsFormData<ApiResponse<any[]>>(
      url,
      data,
      true
    );
    return response;
  }

  public async checkoutContactSubmit(body: any, userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/cart/updatecontactinfo/`;
    const data = new FormData();
    data.append('cartID', body.cartID);
    data.append(
      'shippingContactPhoneCountryCode',
      body.shippingContactPhoneCountryCode
    );
    data.append(
      'shippingContactPhoneDialCode',
      body?.shippingContactPhoneDialCode
    );
    data.append('comments', body?.comments);
    data.append('shippingContactPhone', body.shippingContactPhone);
    data.append('shippingContactName', body.shippingContactName);
    data.append('userShippingID', body.userShippingID);
    // if (userToken) {
    //   data.append('userToken', userToken);
    // }

    const response = await http.postAsFormData<ApiResponse<any[]>>(
      url,
      data,
      true
    );
    return response;
  }

  public async removeFromList(params: {
    id?: number;
    listType: string;
    type?: string;
  }): Promise<MyList[]> {
    const url = `${this.apiDomain}/cart/removeFromList`;
    const data = new FormData();
    if (params.id) {
      data.append('id', params.id?.toString());
    }
    data.append('listType', params.listType);
    if (params.type) {
      data.append('type', params.type);
    }

    const response = await http.postAsFormData<ApiResponse<MyList[]>>(
      url,
      data,
      true
    );

    return response?.data?.result;
  }
  public async removeFromCart(params: {
    id: string;
    cartItemID: string;
    type?: string;
    reason?: string;
    reasonOther?: string;
    isClearCart?: boolean;
  }): Promise<ApiResponse<ICartData>> {
    const url = `${this.apiDomain}/cart/updatecart`;
    const data = new FormData();
    data.append('cartID', params.id);
    data.append('mode', 'delete_item');
    data.append('cartItemID', params.cartItemID);
    if (params.isClearCart) {
      data.append('isCompleteCart', 'Yes');
      data.append('cartItemID', undefined);
    }

    if (params.reason != null && params.reason != '') {
      data.append('reason', params.reason);
    }

    if (params.reasonOther != null && params.reasonOther != '') {
      data.append('reasonOther', params.reasonOther);
    }
    const response = await http.postAsFormData<ApiResponse<ICartData>>(
      url,
      data,
      true
    );

    return response?.data;
  }

  public async submitRequest(cartID: string): Promise<any> {
    const url = `${this.apiDomain}/cart/saveOrderRequest`;
    const data = new FormData();
    data.append('cartID', cartID);
    const response = await http.postAsFormData<ApiResponse<any>>(
      url,
      data,
      true
    );
    return response?.data;
  }

  public async approveOrder(orderID: string): Promise<any> {
    const url = `${this.apiDomain}/order/requestApprove/`;
    const data = new FormData();
    data.append('requestID', orderID);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async submitRequest2(): Promise<any> {
    const url = `${this.apiDomain}/cart/submitQuotation`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<IQuoteDetails>>(
      url,
      data,
      true
    );
    return response?.data;
  }

  public async upload(
    id: string,
    name: string,
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    const url = `${this.apiDomain}/purchase-order/${id}/upload`;
    const response = await http.upload(url, formData, onUploadProgress);
    return response?.data;
  }

  public async getAddress(userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/shipping/`;

    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('action', 'list');
    const response = await http.postAsFormData<ApiResponse<any>>(
      url,
      data,
      true
    );

    return response?.data?.result;
  }

  public async getUserBrands(userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/listUserToBrand`;
    const data = new FormData();
    data.append('status', 'view');
    data.append('sortBy', 'desc');
    if (userToken) {
      data.append('userToken', userToken);
    }
    // data.append('userToken',  'ac3dd6d24b038d3896c9d747f8e352a7');
    const response = await http.postAsFormData<ApiResponse<any>>(
      url,
      data,
      true
    );

    return response?.data?.result;
  }

  public async getContactPersonBranch(userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/contactPersonBranch`;
    const data = new FormData();
    data.append('status', 'view');
    data.append('sortBy', 'desc');
    if (userToken) {
      data.append('userToken', userToken);
    }
    // data.append('userToken',  'ac3dd6d24b038d3896c9d747f8e352a7');
    const response = await http.postAsFormData<ApiResponse<any>>(
      url,
      data,
      true
    );

    return response?.data?.result;
  }

  public async getCustomerBrands(
    userToken?: string
  ): Promise<ICustomerBrands[]> {
    const url = `${this.apiDomain}/user/listUsersBrand`;
    const data = new FormData();
    // data.append('status', 'view');
    // data.append('sortBy', 'desc');
    if (userToken) {
      data.append('userToken', userToken);
    }
    // data.append('userToken',  'ac3dd6d24b038d3896c9d747f8e352a7');
    const response = await http.postAsFormData<ApiResponse<ICustomerBrands[]>>(
      url,
      data,
      true
    );

    return response?.data?.result;
  }

  public async getContactPerson(params?: {
    wareHouseID?: string;
    contactTypeID?: string;
    userToken?: string;
  }): Promise<any> {
    const url = `${this.apiDomain}/user/listContactPerson`;
    const data = new FormData();

    data.append('warehouseID', params?.wareHouseID || '');
    data.append('contactTypeID', params?.contactTypeID || '');
    if (params?.userToken) {
      data.append('userToken', params?.userToken);
    }

    // data.append('userToken',  'ac3dd6d24b038d3896c9d747f8e352a7');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data?.result;
  }

  public async getContactTypes(userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/listContactType`;
    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }

    // data.append('userToken',  'ac3dd6d24b038d3896c9d747f8e352a7');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data?.result;
  }

  public async getUserList(params?: {
    searchText?: string;
    userToken?: string;
    isAddStaff?: boolean;
  }): Promise<any> {
    const url = `${this.apiDomain}/user/listusers`;
    const data = new FormData();
    data.append('userFullName', params?.searchText ? params?.searchText : '');
    if (params?.userToken) {
      data.append('userToken', params?.userToken);
    }
    data.append('brandID', '');
    data.append('branchID', '');
    // data.append('userToken', this.token ? this.token : '');

    // data.append('userToken',  'ac3dd6d24b038d3896c9d747f8e352a7');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getSalespersonList(params: {
    searchText?: string;
    filter?: ISalespersonFilter;
    page?: number;
    perPage?: number;
  }): Promise<ApiResponse<ISalesPerson[]>> {
    const url = `${this.apiDomain}/salesperson/listsalespersons/`;
    const data = new FormData();
    data.append('stateID', params.filter?.assignedStates?.join(',') ?? '');
    data.append('roleID', params.filter?.roles?.join(',') ?? '');
    data.append('searchKeyword', params?.searchText ?? '');
    data.append('brandID', '');
    data.append('branchID', '');
    if (params.page) {
      data.append('page', params.page.toString());
    }
    if (params.perPage) {
      data.append('perPage', params.perPage.toString());
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async getSalespersons(
    brandID: string,
    userToken?: string
  ): Promise<ISalesPerson[]> {
    const url = `${this.apiDomain}/salesperson/listsalespersons/`;
    const data = new FormData();
    data.append('fromCustomer', userToken ? 'No' : 'yes');
    data.append('brandID', brandID);
    data.append('branchID', '');
    if (userToken) data.append('userToken', userToken);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data.result;
  }

  public async getSalesManagesRoles(): Promise<ISalesmanagerRole[]> {
    const url = `${this.apiDomain}/salesperson/listsalesmanagersroles`;
    const data = new FormData();

    data.append('brandID', '');
    data.append('branchID', '');
    data.append('userToken', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getSalesPersonCustomerList(params: {
    searchText?: string;
    filter?: ISalespersonCustomerFilter;
    page?: number;
    perPage?: number;
  }): Promise<ApiResponse<ISalesPersonCustomer[]>> {
    const url = `${this.apiDomain}/salesperson/listcustomers/`;
    const data = new FormData();
    data.append('salesperson', params.filter?.salesperson ?? '');
    data.append('searchKeyword', params?.searchText ?? '');
    data.append('brandID', params.filter?.brandID ?? '');
    data.append('branchID', '');
    if (params.page) {
      data.append('page', params.page.toString());
    }
    if (params.perPage) {
      data.append('perPage', params.perPage.toString());
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async assignSalesPersons(
    userID: string,
    customerIDs: string
  ): Promise<ApiResponse<ISalesPersonCustomer[]>> {
    const url = `${this.apiDomain}/salesperson/customermapping`;
    const data = new FormData();
    data.append('userID', userID);
    data.append('customerIDs', customerIDs);

    data.append('brandID', '');
    data.append('branchID', '');
    data.append('userToken', '');

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async getUserPermissions(): Promise<any> {
    const url = `${this.apiDomain}/index/listPermissions/`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getStaffShipping(id?: string, userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/staffShipping/`;
    const data = new FormData();
    data.append('staffUserID', id || '');
    data.append('action', 'list');
    data.append('brandID', '');
    data.append('branchID', '');
    if (userToken) data.append('userToken', userToken);

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data?.result;
  }

  public async getStaffDetails(id?: string, userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/staffDetails/`;
    const data = new FormData();
    data.append('staffUserID', id || '');
    if (userToken) data.append('userToken', userToken);

    data.append('brandID', '');
    data.append('branchID', '');

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data?.result;
  }

  public async getUserLanguages(): Promise<any> {
    const url = `${this.apiDomain}/index/listLanguages/`;
    const data = new FormData();
    // data.append('userToken', this.token ? this.token : '');

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response?.data?.result;
  }

  public async addUserBrand(
    params: IAdduserBrandsParams,
    userToken?: string
  ): Promise<any> {
    const url = `${this.apiDomain}/user/addUserToBrand`;
    const data = new FormData();
    data.append('brandID', params.brandID);
    data.append('branches', JSON.stringify(params.branches));
    /* commented due to each baranch have these data, so its binded in branches*/
    // if (params.salesPersonID) {
    //   data.append('salesPersonID', params.salesPersonID);
    //   data.append('accountNumber', params.accountNumber);
    //   data.append('discountCode', params.discountCode);
    //   data.append('creditLimit', params.creditLimit);
    // }

    if (userToken) {
      data.append('userToken', userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response;
  }

  public async editUserBrand(params: any, userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/changeUserToBrandStatus`;
    const data = new FormData();
    data.append('brandToBranchID', params.id);
    data.append('status', params.status);
    if (userToken) {
      data.append('userToken', userToken);
    }

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response;
  }

  public async deleteUserBrand(
    userToWarehouseID: string,
    brandToBranchID: string,
    userToken?: string
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/user/changeUsersBrandStatus`;
    const data = new FormData();
    data.append('userToWarehouseID', userToWarehouseID);
    data.append('brandToBranchID', brandToBranchID);
    data.append('status', 'Deleted');
    data.append('brandID', '');
    data.append('branchID', '');
    if (userToken) {
      data.append('userToken', userToken);
    }

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response.data;
  }

  public async addContactPerson(params: any, userToken?: string): Promise<any> {
    const url = `${this.apiDomain}/user/addContactPerson`;

    const data = new FormData();
    data.append('subUserID', params.subUserID);
    data.append('contactTypeID', params.contactTypeID);
    if (params.contactPersonID) {
      data.append('contactPersonID', params.contactPersonID);
    }
    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('warehouseIDs', params.warehouseIDs);

    // data.append('userToken',  'ac3dd6d24b038d3896c9d747f8e352a7');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);

    return response;
  }

  public async getQuoteRequest(
    params: any
  ): Promise<ApiResponse<IQuotationResponse>> {
    const url = `${this.apiDomain}/order/quotationRequest/`;
    const data = new FormData();
    if (params?.status) data.append('status', params.status);
    if (params?.requestID) data.append('searchRequestID', params.requestID);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((brnch) => {
            if (brnch.value) val.push(brnch.value);
            return;
          });
          if (val.length > 0) {
            data.append('branchID', JSON.stringify(val));
          }
        }
      });
    }
    try {
      const response = await http.postAsFormData<
        ApiResponse<IQuotationResponse>
      >(url, data);
      if (response?.data?.response === 'Success') {
        response.data.errorMsg = '';
        return response.data;
      }
      return response?.data;
    } catch (err) {
      throw err;
    }
  }

  public async getHigherRequest(params: any): Promise<any> {
    const url = `${this.apiDomain}/order/listhighOrder/`;
    const data = new FormData();
    data.append('status', params);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getDocumentList(userToken?: string): Promise<IDocument> {
    const url = `${this.apiDomain}/user/userdocuments`;
    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async getDocumentTypeList(userToken?: string): Promise<IDocumentType> {
    const url = `${this.apiDomain}/user/listDocumentMaster`;
    const data = new FormData();

    if (userToken) {
      data.append('userToken', userToken);
    }
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async addDocumentFile(
    frontFile: File,
    backFile: File,
    type: string,
    expiryDate: string,
    userToken?: string
  ): Promise<any> {
    const url = `${this.apiDomain}/user/documentadd`;
    const formData = new FormData();
    formData.append('documentUrl', frontFile);
    formData.append('documentBackUrl', backFile);
    formData.append('documentMaster', type);
    if (expiryDate) {
      formData.append(
        'expiryDate',
        formatDate(new Date(expiryDate), DateFormats.YearMonthFormat)
      );
    }

    if (userToken) {
      formData.append('userToken', userToken);
    }
    // formData.append('userToken', '362e62776daf40ca2a8adc873ed4e4c8');
    const response = await http.upload(url, formData);
    return response as any;
  }

  public async uploadSalesPersonImage(
    userID: string,
    userProfilePicture: File
  ): Promise<ApiResponse<ISalesPerson>> {
    const url = `${this.apiDomain}/salesperson/editProfileImage`;
    const formData = new FormData();
    formData.append('userProfilePicture', userProfilePicture);
    formData.append('userID', userID);
    formData.append('action', 'update');
    formData.append('userToken', '');
    formData.append('brandID', '');
    formData.append('branchID', '');
    const response = await http.postAsFormData(url, formData);
    return response.data;
  }

  public async getSingleDocument(
    documentID: string,
    userToken?: string
  ): Promise<IDocumentList> {
    const url = `${this.apiDomain}/user/getDocumentDetails`;
    const data = new FormData();

    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('documentID', documentID);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data?.result;
  }

  public async deleteDocument(
    documentID: string,
    userToken?: string
  ): Promise<any> {
    const url = `${this.apiDomain}/user/documentdelete`;
    const data = new FormData();
    if (userToken) {
      data.append('userToken', userToken);
    }
    data.append('documentID', documentID);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response as any;
  }
  public async editDocument(param: IDocumentUpdateParam): Promise<any> {
    const url = `${this.apiDomain}/user/documentadd`;
    const data = new FormData();
    // data.append('userToken', this.token ? this.token : '');

    if (param?.userToken) {
      data.append('userToken', param.userToken);
    }
    param?.frontFile
      ? data.append('documentUrl', param.frontFile)
      : data.append('fileName', param.frontFileName);
    param.backFile
      ? data.append('documentBackUrl', param.backFile)
      : data.append('fileNameBack', param.backFileName);
    data.append('documentMaster', param.documentMaster);
    data.append('documentID', param.documentID);
    if (param.expiryDate) {
      data.append(
        'expiryDate',
        formatDate(new Date(param.expiryDate), DateFormats.YearMonthFormat)
      );
    }

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response as any;
  }

  public async getQuotationDetails(
    requestID: string
  ): Promise<ApiResponse<IQuotationDetails>> {
    const url = `${this.apiDomain}/cart/getQuotationDetails/`;
    const data = new FormData();
    data.append('quotationRequestID', requestID);
    const response = await http.postAsFormData<ApiResponse<IQuotationDetails>>(
      url,
      data
    );
    return response?.data;
  }

  public async rejectQuotationRequest(
    requestID: string,
    reason: string
  ): Promise<ApiResponse<IQuotationDetails>> {
    const url = `${this.apiDomain}/cart/rejectQuotation/`;
    const data = new FormData();
    data.append('quotationRequestID', requestID);
    data.append('rejectReason', reason);
    const response = await http.postAsFormData<ApiResponse<IQuotationDetails>>(
      url,
      data
    );
    return response?.data;
  }

  // clear quotation suggest
  public async clearQuotationSuggest(requestID: string) {
    const url = `${this.apiDomain}/cart/removeItemsEstimate/`;
    const data = new FormData();
    data.append('quotationRequestID', requestID);
    const response = await http.postAsFormData<ApiResponse<IQuotationDetails>>(
      url,
      data
    );
    return response?.data;
  }

  public async getQuotationSuggestList(
    optionSKU: string,
    quotationRequestID: string
  ): Promise<ISuggestList[]> {
    const url = `${this.apiDomain}/cart/productAutoSuggestion/`;
    const data = new FormData();
    data.append('optionSKU', optionSKU);
    data.append('quotationRequestID', quotationRequestID);
    data.append('brandID', '');
    data.append('branchID', '');
    const response = await http.postAsFormData<ApiResponse<ISuggestList[]>>(
      url,
      data
    );
    return response?.data?.result;
  }

  public async replaceQuotationItem(
    param: IReplaceQuotationParam
  ): Promise<ApiResponse<IReplaceQuoteResponse>> {
    const url = `${this.apiDomain}/cart/replaceQuotationItem/`;
    const data = new FormData();
    data.append('userToken', param?.userToken ?? '');
    data.append('quotationReqItemID', param?.quotationReqItemID ?? '');
    data.append('quantity', param?.quantity ?? '');
    data.append('productOptionID', param?.productOptionID ?? '');
    data.append('productID', param?.productID ?? '');
    data.append('estimatedQuantity', param?.estimatedQuantity ?? '');
    data.append('notAvailable', param?.notAvailable ?? '');
    data.append('immediateAvailable', param?.immediateAvailable ?? '');
    data.append('laterAvailable', param?.laterAvailable ?? '');
    data.append('backOrder', param?.backOrder ?? '0');
    data.append('discountPercentage', param?.discount ?? '');
    data.append('unitPrice', param.unitPrice ?? '');
    data.append('totalPrice', `${param?.totalPrice ?? ''}`);
    data.append('comment', param?.comment ?? '');
    data.append('brandID', '');
    data.append('branchID', '');
    const response = await http.postAsFormData<
      ApiResponse<IReplaceQuoteResponse>
    >(url, data);
    return response?.data;
  }

  public async removeQuotationItem(
    param: IReplaceQuotationParam
  ): Promise<ApiResponse<IReplaceQuoteResponse>> {
    const url = `${this.apiDomain}/cart/removeQuotationItem/`;
    const data = new FormData();
    data.append('userToken', param?.userToken ?? '');
    data.append('quotationReqItemID', param?.quotationReqItemID ?? '');
    data.append('productOptionID', param?.productOptionID ?? '');
    data.append('productID', param?.productID ?? '');
    const response = await http.postAsFormData<
      ApiResponse<IReplaceQuoteResponse>
    >(url, data);
    return response?.data;
  }

  public async getPreviewQuotationSuggestList(
    quotationRequestID: string
  ): Promise<ApiResponse<ISuggestPreviewResponse>> {
    const url = `${this.apiDomain}/cart/previewQuotationDetails/`;
    const data = new FormData();
    data.append('quotationRequestID', quotationRequestID);
    const response = await http.postAsFormData<
      ApiResponse<ISuggestPreviewResponse>
    >(url, data);
    return response?.data;
  }

  public async confirQuotationSuggestList(
    quotationRequestID: string
  ): Promise<ApiResponse<ISuggestPreviewResponse>> {
    const url = `${this.apiDomain}/cart/confirmQuotation/`;
    const data = new FormData();
    data.append('quotationRequestID', quotationRequestID);
    const response = await http.postAsFormData<
      ApiResponse<ISuggestPreviewResponse>
    >(url, data);
    return response?.data;
  }

  public async quotationbulkEstimatePreview(
    file: File,
    action: 'add' | 'view'
  ): Promise<IBulkEstimatePreviewResponse[]> {
    const url = `${this.apiDomain}/cart/bulkEstimateQuote/`;
    const formData = new FormData();
    formData.append('quoteFile', file);
    formData.append('action', action);
    const response = await http.postAsFormData(url, formData);
    return response?.data;
  }

  public async submitQuoteRequest(quotationRequestID: string): Promise<any> {
    const url = `${this.apiDomain}/cart/orderQuotation/`;
    const data = new FormData();
    data.append('quotationRequestID', quotationRequestID);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async uploadStaffImage(
    userID: string,
    userProfilePicture: File,
    userToken?: string
  ): Promise<ApiResponse<ISalesPerson>> {
    const url = `${this.apiDomain}/user/editStaffProfileImage`;
    const formData = new FormData();
    formData.append('userProfilePicture', userProfilePicture);
    formData.append('staffUserID', userID);
    if (userToken) formData.append('userToken', userToken);
    formData.append('brandID', '');
    formData.append('branchID', '');
    const response = await http.postAsFormData(url, formData);
    return response.data;
  }

  public async getOfferParts(): Promise<ApiResponse<Parts[]>> {
    const url = `${this.apiDomain}/fetchapi/offers/`;
    const data = new FormData();
    const apiResponse = await http.postAsFormData<ApiResponse<Parts[]>>(
      url,
      data
    );
    const { result, count, errorMsg, response, status } = apiResponse?.data;

    return {
      ...apiResponse?.data,
      count: result?.length,
      errorMsg,
    };
  }

  // get dash values
  public async getDashValues(
    params: any
  ): Promise<any> {
    const url = `${this.apiDomain}/index/dashboardvalues/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('page', params?.page);
    data.append('brandID', params?.brandID);
    data.append('branchID', params?.branchID);
    // if(salespersonToken) {
    //   data.append('salespersonToken', salespersonToken);
    // }

    const response = await http.postAsFormData<ApiResponse<SalesData>>(url, data);
    return response?.data?.result;
  }

  // list save to later
  public async listSaveToLater(params): Promise<any> {
    const url = `${this.apiDomain}/user/saveforlater`;
    const data = new FormData();

    data.append('userToken', this.userToken ? this.userToken : '');
    if (params?.requestID) data.append('title', params.requestID);
    if (params?.startDate) data.append('fromDate', params.startDate);
    if (params?.endDate) data.append('toDate', params.endDate);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);

    // if(salespersonToken) {
    //   data.append('salespersonToken', salespersonToken);
    // }


    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }


  // add save to later
  public async addSaveToLater(
    params: any
  ): Promise<any> {
    const url = `${this.apiDomain}/cart/saveforlater`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('cartID', params?.cartID);
    data.append('saveLaterName', params?.saveLaterName);
    // if(salespersonToken) {
    //   data.append('salespersonToken', salespersonToken);
    // }

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  // save to later detail
  public async saveToLaterDetail(
    saveId: any
  ): Promise<any> {
    const url = `${this.apiDomain}/user/saveforlaterdetails`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('saveLaterID', saveId);

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async saveLaterToCart(
    saveId: any
  ): Promise<any> {
    const url = `${this.apiDomain}/cart/addsaveforlater`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('saveLaterID', saveId);

    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async cartGetOtp(
    params: any
  ): Promise<any> {
    const url = `${this.apiDomain}/cart/sendOTP/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    if (params?.cartID) {
      data.append('cartID', params?.cartID);
    }
    if (params.orderRequestID) {
      data.append('orderRequestID', params.orderRequestID);
    }

    // if(salespersonToken) {
    //   data.append('salespersonToken', salespersonToken);
    // }

    const response = await http.postAsFormData<ApiResponse<SalesData>>(url, data);
    return response?.data;
  }
  public async cartVerifyOtp(
    params: any
  ): Promise<any> {
    const url = `${this.apiDomain}/cart/verifyOTP/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('cartID', params?.cartID);
    data.append('otp', params?.otp);
    data.append('orderRequestID', params.orderRequestID);
    // if(salespersonToken) {
    //   data.append('salespersonToken', salespersonToken);
    // }

    const response = await http.postAsFormData<ApiResponse<SalesData>>(url, data);
    return response?.data;
  }

  public async getBusinessHours(
    params: any
  ): Promise<any> {
    const url = `${this.apiDomain}/cart/businesshours/`;
    const data = new FormData();
    data.append('userToken', this.userToken ? this.userToken : '');
    data.append('cartID', params?.cartID);
    // if(salespersonToken) {
    //   data.append('salespersonToken', salespersonToken);
    // }

    const response = await http.postAsFormData<ApiResponse<SalesData>>(url, data);
    return response?.data;
  }
}
