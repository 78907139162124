import React, { Component } from 'react';
import HeaderCanvas from './HeaderCanvas';
import UserHeaderCanvas from './UserHeaderCanvas';

interface CustomProps {
  headerType?: 'logged-in' | 'pre-login';
  menuVisible?: boolean;
  mobHeader?: boolean;
  showBrand?: boolean;
  hideSticky?: boolean;
}
class Header extends Component<CustomProps> {
  render() {
    return this.props.headerType !== 'logged-in' ? (
      <HeaderCanvas />
    ) : (
      <UserHeaderCanvas
        menuVisible={this.props.menuVisible}
        mobHeader={this.props.mobHeader}
        showBrand={this.props.showBrand}
        hideSticky={this.props.hideSticky}
      />
    );
  }
}

export default Header;
