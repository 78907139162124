import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import { translate } from '../../utils/text-utils';
import brand1 from '../../assets/images/brands/img01.png';
import brand2 from '../../assets/images/brands/img02.png';
import brand3 from '../../assets/images/brands/img03.png';
import brand4 from '../../assets/images/brands/img04.png';

export const Footer = () => {
  return (
    <div className="footer bg-white d-lg-block d-none position-sticky bottom-0 left-0 px-4 mt-auto">
      <div className="row align-items-center">
        <div className="col minw-0">
          <ul className="list-unstyled row align-items-center font-xs font-weight-medium mb-0">
            <li className="col-auto text-gray-600">{translate('others.copyRight')}</li>
            <li className="col-auto">
              <Link to={'/terms-of-use'} className="text-gray-600">
                {translate('common.termsofUse')}
              </Link>
            </li>
            <li className="col-auto">
              <Link to={'/privacy-policy'} className="text-gray-600">
                {translate('common.PrivacyPolicy')}
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-auto">
          <div className="row gutter-10">
            <div className="col-auto">
              <div className="row align-items-center">
                <div className="col-auto">
                  <img
                    alt="brand"
                    src={brand1}
                    className="img-fluid img-cover w-100 px-1"
                  />
                </div>
                <div className="col-auto">
                  <img
                    alt="brand"
                    src={brand2}
                    className="img-fluid img-cover w-100 px-1"
                  />
                </div>
                <div className="col-auto">
                  <img
                    alt="brand"
                    src={brand3}
                    className="img-fluid img-cover w-100 px-1"
                  />
                </div>
                <div className="col-auto">
                  <img
                    alt="brand"
                    src={brand4}
                    className="img-fluid img-cover w-100 px-1"
                  />
                </div>
              </div>
            </div>
            <div className="col-auto py-2 my-1">
              <div className="py-px h-100">
                <div className="border-gray-left h-100"></div>
              </div>
            </div>
            <div className="col-auto align-self-center font-xs font-weight-medium text-gray-600">
              {translate('brandsList.ourBrands')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
