import React, { useState } from 'react';

import CopyClipboard from '../../../components/copy-to-clipboard';
import '../../../containers/search-tab/search-box.scss';
import { CartItem } from '../../../models/order-models';
import { CartCounter } from './cart-counter';
import { useCart } from '../../../core/hooks';
import { useConfirmationDialog } from '../../../components/confirm-popup/confirm-popup';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../../utils/text-utils';
import Currency from 'react-currency-formatter';
import { CurrencyFormatter } from '../../../components/currency-formatter';

export const CartItemDetail = (props: {
  item: CartItem;
  cartId: string;
  isViewOnly: boolean;
  onSelectionChange?: () => void;
  isChecked?: boolean;
  canEditCart?: boolean;
  cartUserStatus?: string;
  itemIndex: number;
}) => {
  const { getConfirmation } = useConfirmationDialog();
  const cartID = props.cartId;
  const cartItem = props.item;
  const [loading, setLoading] = useState(false);
  const { removeFromCart } = useCart();
  const removeItem = async () => {
    const granded = await getConfirmation({
      title: translate('common.areYouSureToDeleteThisItem'),
    });
    if (granded) {
      try {
        setLoading(true);
        await removeFromCart(cartItem, cartID);
      } catch (err) {
        toastr.error(translate('common.error'), 'Somethign went wrong');
      } finally {
        setLoading(false);
      }
    }
  };

  const getAvailabilityText = () => {
    let text = '';
    text += `${translate('orders.immediatelyAvailable')}: ${cartItem.branchStockQty
      }  `;
    text += ' / ';
    text += `${translate('orders.availableInDays')}: ${cartItem.centralStockQty
      }`;
    return text.trim();
  };
  return (
    <>
      <tr>
        <td className="pr-0 check">
          <div
            onClick={() => {
              if (!props?.canEditCart && props?.cartUserStatus !== 'Inactive') {
                props.onSelectionChange?.();
              }
            }}
            className="cursor-pointer d-flex"
          >
            {!props?.canEditCart && (
              <input
                type="checkbox"
                checked={props.isChecked}
                id={cartItem.cartItemID}
              />
            )}
            <label className="px-2">&nbsp;</label>
          </div>
        </td>
        <td className="d-none d-lg-table-cell text-center">
          {props.itemIndex + 1}
        </td>
        <td className="d-none d-lg-table-cell">
          <CopyClipboard value={cartItem.optionSKU}>
            <div className="copy px-2 d-inline-flex align-items-center cursor-pointer text-nowrap">
              {cartItem.optionSKU}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                  className="ml-2 cursor-pointer"
                >
                  <line x1="2.5" y1="2" x2="2.5" y2="11" stroke="#808080" />
                  <line x1="0.5" y1="1" x2="0.5" y2="8" stroke="#808080" />
                  <line x1="9.5" y1="2" x2="9.5" y2="11" stroke="#808080" />
                  <line x1="2" y1="2.5" x2="10" y2="2.5" stroke="#808080" />
                  <line y1="0.5" x2="7" y2="0.5" stroke="#808080" />
                  <line x1="2" y1="10.5" x2="10" y2="10.5" stroke="#808080" />
                </svg>
              </span>
            </div>
          </CopyClipboard>
        </td>
        <td>
          <div className="pl-4 pl-lg-0 ml-1 ml-lg-0">
            {cartItem?.itemDetails?.productTitle ?? cartItem?.productTitle}
            <div>
              {Number(cartItem?.productOptionQuantity) > 0 ? (
                <div className="text-green font-xxs text-uppercase font-weight-medium">
                  {cartItem?.productOptionQuantity}{' '}
                  {translate('common.inStock')}
                </div>
              ) : (
                <div className="text-danger font-xxs text-uppercase font-weight-medium">
                  {translate('common.outOfStock')}
                </div>
              )}
            </div>
            <div className="d-lg-none pt-2">
              <CopyClipboard value={cartItem.optionSKU}>
                <div className="copy copy--sm px-2 d-inline-flex align-items-center cursor-pointer">
                  <span className="font-weight-medium">
                    {cartItem.optionSKU}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    className="ml-2 cursor-pointer"
                  >
                    <line x1="2.5" y1="2" x2="2.5" y2="11" stroke="#808080" />
                    <line x1="0.5" y1="1" x2="0.5" y2="8" stroke="#808080" />
                    <line x1="9.5" y1="2" x2="9.5" y2="11" stroke="#808080" />
                    <line x1="2" y1="2.5" x2="10" y2="2.5" stroke="#808080" />
                    <line y1="0.5" x2="7" y2="0.5" stroke="#808080" />
                    <line x1="2" y1="10.5" x2="10" y2="10.5" stroke="#808080" />
                  </svg>
                </div>
              </CopyClipboard>
            </div>
          </div>
        </td>
        <td className="text-lg-center">
          <div className="d-lg-none text-nowrap text-right">
            <div className="font-md font-weight-medium line-height-normal">
              <CurrencyFormatter quantity={cartItem?.orderItemSubtotal} />
              {cartItem?.savedPriceVal?.toNumber() > 0 &&
                cartItem?.customerPrice?.toNumber() === 0 && (
                  <div className="font-xs text-gray-600 w-100">
                    {translate('cart.savedSAR')} {cartItem?.savedPriceVal}
                  </div>
                )}
              {/* {cartItem?.customerPrice?.toNumber() > 0 && (
                <div>
                  <del className="font-xs text-gray-600 font-weight-normal text-nowrap">
                    <CurrencyFormatter
                      quantity={
                        Number(cartItem?.actualPrice) *
                        cartItem.orderItemQuantity
                      }
                    />
                  </del>
                </div>
              )} */}
            </div>
            <div className="font-xxs font-weight-medium text-muted w-100 pb-px mb-2">
              <CurrencyFormatter
                quantity={
                  cartItem?.offerPrice?.toNumber() ??
                  cartItem?.itemDetails?.unitPrice ??
                  cartItem?.orderItemPrice?.toNumber() ??
                  0
                }
              />{' '}
              {translate('cart.EACH')}
            </div>
          </div>
          {!props.isViewOnly && (
            <div className="tooltip position-relative sm d-flex justify-content-end justify-content-lg-center">
              <CartCounter
                cartItem={cartItem}
                cartUserStatus={props?.cartUserStatus}
                commaSeparation
              ></CartCounter>
              {props?.cartUserStatus === 'Inactive' && (
                <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                  <p className="font-weight-medium my-1 text-gray-900 font-sm">
                    {translate('common.inactive_user_message')}
                  </p>
                </span>
              )}
            </div>
          )}
          {props.isViewOnly && <h6>{cartItem?.orderItemQuantity ?? 0}</h6>}
        </td>
        <td className="d-none d-lg-table-cell text-right text-nowrap">
          <CurrencyFormatter
            quantity={
              cartItem?.offerPrice?.toNumber() ??
              cartItem?.itemDetails?.unitPrice ??
              cartItem?.orderItemPrice?.toNumber() ??
              0
            }
          />
        </td>
        <td className="d-none d-lg-table-cell text-right text-nowrap">
          <CurrencyFormatter quantity={cartItem?.orderItemSubtotal} />
          {cartItem?.savedPriceVal?.toNumber() > 0 &&
            cartItem?.customerPrice?.toNumber() === 0 && (
              <div className="font-xs text-gray-600 w-100">
                {translate('cart.savedSAR')} {cartItem?.savedPriceVal}
              </div>
            )}
          {/* {cartItem?.customerPrice?.toNumber() > 0 && (
            <div>
              <del className="font-xs text-gray-600 font-weight-normal text-nowrap">
                <CurrencyFormatter
                  quantity={
                    Number(cartItem?.actualPrice) * cartItem.orderItemQuantity
                  }
                />
              </del>
            </div>
          )} */}
        </td>
        <td className="pl-lg-0">
          <div
            className="option cursor-pointer"
            onClick={() => {
              removeItem();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="currentColor"
              className="d-none d-lg-inline-flex"
            >
              <circle cx="18" cy="18" r="18" />
              <path
                d="M12.8333 25.0705V12.9871H12V11.7372H15.75V11H20.75V11.7372H24.5V12.9871H23.6666V25.0705H12.8333ZM14.0833 23.8205H22.4166V12.9871H14.0833V23.8205ZM16.0865 22.1538H17.3365V14.6538H16.0865V22.1538ZM19.1634 22.1538H20.4134V14.6538H19.1634V22.1538Z"
                fill="black"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="15"
              viewBox="0 0 13 15"
              fill="currentColor"
              className="d-lg-none mr-2"
            >
              <path d="M0.833333 14.0705V1.98715H0V0.737166H3.74998V0H8.74998V0.737166H12.5V1.98715H11.6666V14.0705H0.833333ZM2.08331 12.8205H10.4166V1.98715H2.08331V12.8205ZM4.08654 11.1538H5.33652V3.65381H4.08654V11.1538ZM7.16344 11.1538H8.41342V3.65381H7.16344V11.1538Z" />
            </svg>
            <span className="d-lg-none font-sm font-weight-semibold">
              {translate('cartDetail.remove')}
            </span>
          </div>
        </td>
      </tr>
      {/* <div className="cartItem border-bottom d-block">
        <div className="position-relative">
          <div className="row">
            <div className="col minw-0">
              <div
                className="px-4 py-1"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {cartItem?.fdNumber && (
                  <div className="font-md" style={{ marginRight: '10px' }}>
                    FD :{' '}
                    <span className="text-gray-600">{cartItem?.fdNumber}</span>
                  </div>
                )}
                {cartItem?.mcNumber && (
                  <div className="font-md">
                    MC :{' '}
                    <span className="text-gray-600">{cartItem?.mcNumber}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {getAvailabilityText() !== '' && (
            <div className="font-xs text-gray-600 w-100 mt-2">
              <div className="bg-body rounded px-lg-3 px-2 py-1 line-height-normal d-inline-block">
                {getAvailabilityText()}
              </div>
            </div>
          )}
        </div>
      </div> */}
      {loading && (
        <tr>
          <td colSpan={7}>
            <div className="numberField__loading">
              <img
                src={require('../../../components/counter/loading.gif')}
                alt="loading..."
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
