import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import { OrderService } from '../api';
import {
  IApproveRequestItem,
  IBulkImportResponse,
  ICartData,
  ICheckoutData,
  IOrderDetails,
  IOrderFilterData,
  IOrderListParams,
  IPreviewData,
  IRejectRequestItem,
  IReturnDetails,
  IReturnRequestItem,
  Reasons,
  RejectReason,
} from '../../models/order-models';
import {
  cartGetAction,
  onCheckoutDataSuccess,
  onFilterDataSuccess,
  onOrderDetailsSuccess,
  onPreviewDetailsSuccess,
  setOrderDetailSelectedTab,
  setOrderLoader,
} from '../store';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { TabFilters } from '../../containers/orders/order-listing-screen';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../../containers/routes/route-keys';
import { StatusTabs } from '../../containers/return/return-requests';
import { ApiResponse, UserType } from '../api/models';
import { translate } from '../../utils/text-utils';

export const useOrder = (orderService: OrderService) => {
  const history = useHistory();
  const orderState = useSelector((state: State) => state.order);
  const authState = useSelector((state: State) => state.authUser);
  const [loader, setLoader] = useState(false);
  const [progressEvent, setProgressEvent] = useState<ProgressEvent>();
  const dispatch = useDispatch();

  const getOrderDetails = async (orderId: string, onSuccess?: (response?: any) => void) => {
    try {
      dispatch(setOrderLoader(true));
      const response: IOrderDetails = await orderService.getOrderDetails(
        orderId
      );
      dispatch(onOrderDetailsSuccess(response));
      if (onSuccess) {
        onSuccess();
      }
      if (
        response.dynamicOrderStatus === TabFilters.CancelRequest &&
        response.prevOrderStatus
      ) {
        dispatch(
          setOrderDetailSelectedTab(
            response?.prevOrderStatus
              ? response?.prevOrderStatus
              : TabFilters.InternallyRequested
          )
        );
      }
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const loadCheckoutDetails = async (orderId: string, isOrder?: boolean, onSuccess?: () => void, onFailure?: (message: string) => void) => {
    try {
      dispatch(setOrderLoader(true));
      const response: ApiResponse<ICheckoutData> =
        await orderService.getCheckoutData(
          orderId,
          orderState?.selectedOrder?.details?.brandID,
          orderState?.selectedOrder?.details?.branchID,
          // authState?.userType === UserType.Salesperson
          //   ? orderState?.selectedOrder?.details?.userToken ?? ''
          //   : undefined,
          undefined,
          isOrder
        );
      if (response.result) {
        dispatch(onCheckoutDataSuccess(response.result));
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (response?.inactiveMsg) {
          onFailure?.(response?.inactiveMsg)
        } else {
          dispatch(onCheckoutDataSuccess(null));
          toastr.error(
            translate('common.error'),
            response?.errorMsg ?? 'Unable to load checkout details'
          );
        }
      }
    } catch (err) {
      toastr.error(
        translate('common.error'),
        'Unable to load checkout details'
      );
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  //arb verify payment
  // const verifyArbPay = async (orderId: string) => {
  //   try {
  //     dispatch(setOrderLoader(true));
  //     const response: any =
  //       await orderService.getArbVerifyPay(
  //         orderId,
  //         orderState?.selectedOrder?.details?.brandID,
  //         orderState?.selectedOrder?.details?.branchID,
  //         authState?.userType === UserType.Salesperson
  //           ? orderState?.selectedOrder?.details?.userToken ?? ''
  //           : undefined
  //       );
  //     if (response.response === "Success") {
  //       dispatch(onCheckoutDataSuccess(response.result));
  //     } else {
  //       dispatch(onCheckoutDataSuccess(null));
  //       toastr.error(
  //         translate('common.error'),
  //         response?.errorMsg ?? 'Unable to load checkout details'
  //       );
  //     }
  //   } catch (err) {
  //     toastr.error(translate('common.error'), 'Unable to load checkout details');
  //   } finally {
  //     dispatch(setOrderLoader(false));
  //   }
  // };

  const requestForPreview = async (
    requestId: string,
    items: IApproveRequestItem[],
    isPreview: boolean
  ) => {
    try {
      dispatch(setOrderLoader(true));
      const response = await orderService.getPreviewDetails(
        requestId,
        items,
        isPreview,
        authState?.userType === UserType.Salesperson
          ? orderState?.selectedOrder?.details?.userToken ?? ''
          : undefined
      );
      if (response.response === 'Success') {
        if (isPreview) {
          dispatch(onPreviewDetailsSuccess(response.result));
        }
      } else {
        toastr.error(
          translate('common.error'),
          'Unable to process the request'
        );
      }
      return response;
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const requestForRejectReason = async (type?: 'Decline' | 'Revoke') => {
    try {
      setLoader(true);
      // dispatch(setOrderLoader(true));
      const response: Reasons[] = await orderService.getRejectReason(type);

      return response;
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      setLoader(false);
      // dispatch(setOrderLoader(false));
    }
  };

  const loadFilterData = async () => {
    // if (!orderState?.filterData) {
    try {
      dispatch(setOrderLoader(true));
      const response: IOrderFilterData =
        await orderService.getOrderFilterData();
      dispatch(onFilterDataSuccess(response));

      return response;
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      dispatch(setOrderLoader(false));
    }
    // }
  };

  const requestForReject = async (
    requestId: string,
    items: IRejectRequestItem[],
    isPreview: boolean = false
  ) => {
    try {
      dispatch(setOrderLoader(true));
      const response = await orderService.rejectRequest(
        requestId,
        items,
        isPreview,
        authState?.userType === UserType.Salesperson
          ? orderState?.selectedOrder?.details?.userToken ?? ''
          : undefined
      );
      if (isPreview) {
        dispatch(onPreviewDetailsSuccess(response?.result));
      }
      return response;
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const bulkUploadOrders = async (
    file: File
  ): Promise<IBulkImportResponse | null> => {
    try {
      setLoader(true);
      const response = await api.order.bulkImportOrders(file);
      return response;
    } catch (e) {
      toastr.error(translate('common.error'), 'File upload failed');

      return null;
    } finally {
      setLoader(false);
    }
  };

  const submitBulkOrder = async (ids: string[]) => {
    try {
      setLoader(true);
      const response: ApiResponse<ICartData> = await api.order.submitBulkOrder(
        ids?.join(',')
      );
      if (response.response === 'Success') {
        dispatch(cartGetAction(response?.result));
      }
      return response;
    } catch (e) {
      toastr.error(
        translate('common.error'),
        'Unable to add the items to the cart'
      );
      return null;
    } finally {
      setLoader(false);
    }
  };

  const requestForCancel = async (
    requestId: string,
    items: IRejectRequestItem[],
    isPreview: boolean = false
  ) => {
    try {
      dispatch(setOrderLoader(true));
      const response = await orderService.cancelRequest(
        requestId,
        items,
        isPreview,
        authState?.userType === UserType.Salesperson
          ? orderState?.selectedOrder?.details?.userToken ?? ''
          : undefined
      );

      return response;
    } catch (err) {
      toastr.error(translate('common.error'), 'Unable to cancel');
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const requestForReturn = async (
    orderId: string,
    items: IReturnRequestItem[],
    isPreview: boolean
  ) => {
    try {
      dispatch(setOrderLoader(true));
      const response = await orderService.returnRequest(
        orderId,
        items,
        isPreview,
        authState?.userType === UserType.Salesperson
          ? orderState?.selectedOrder?.details?.userToken ?? ''
          : undefined
      );

      return response;
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const requestAgain = async (requestId: string) => {
    try {
      dispatch(setOrderLoader(true));
      const response = await orderService.requestAgain(
        requestId,
        authState?.userType === UserType.Salesperson
          ? orderState?.selectedOrder?.details?.userToken ?? ''
          : undefined
      );
      return response;
    } catch (err) {
      toastr.error(translate('common.error'), 'Unable to request');
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const rejectOrApprove = async (
    requestId: string,
    action: 'Approve' | 'Reject',
    reason?: string
  ) => {
    try {
      dispatch(setOrderLoader(true));
      const response = await orderService.salepersonRejectOrApprove(
        requestId,
        action,
        reason
      );
      if (response?.response === 'Success') {
        toastr.success(response?.response, response?.errorMsg ?? '');
        history.push(RouteKeys.Orders);
        // if (action === 'Approve') {
        //   dispatch(setOrderDetailSelectedTab(TabFilters.CancelledOrders));
        // } else {
        //   dispatch(setOrderDetailSelectedTab(TabFilters.CancelRequest));
        // }
        // getOrderDetails(requestId!);
      }
      return response;
    } catch (err) {
      toastr.error(translate('common.error'), 'Unable to request');
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const downlaodOrders = async (param: IOrderListParams, isExcel?: boolean) => {
    try {
      dispatch(setOrderLoader(true));
      const response: any = await orderService.getOrderRequestsDuplicate(param);
      if (response.response === 'Success') {
        if (response?.result?.exportUrl && !isExcel) {
          window.open(response?.result?.exportUrl);
          toastr.success(
            response.response,
            translate('toast.downloadCompleted')
          );
        } else if (response?.result?.exportExcelUrl && isExcel) {
          window.open(response?.result?.exportExcelUrl);
          toastr.success(
            response.response,
            translate('toast.downloadCompleted')
          );
        }
      }
      // return response;
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.unableToUpload')
      );
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const getReturnDetails = async (
    returnId: string
  ): Promise<IReturnDetails | undefined> => {
    try {
      dispatch(setOrderLoader(true));
      const response = await orderService.getReturnDetails(returnId);
      if (response.response === 'Success') {
        return response.result;
      }
      return undefined;
    } catch (err) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
      return undefined;
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  const returnRejectOrApprove = async (
    returnId: string,
    action: 'Approve' | 'Reject',
    reason?: string
  ) => {
    try {
      dispatch(setOrderLoader(true));
      const response = await orderService.returnRejectOrApprove(
        returnId,
        action,
        reason
      );
      if (response?.response === 'Success') {
        toastr.success(response?.response, response?.errorMsg ?? '');
        if (action === 'Approve') {
          history.push(`${RouteKeys.Returns}/${StatusTabs.Approved}`);
        }
        if (action === 'Reject') {
          history.push(`${RouteKeys.Returns}/${StatusTabs.Rejected}`);
        }
      }
      return response;
    } catch (err) {
      toastr.error(translate('common.error'), 'Unable to request');
    } finally {
      dispatch(setOrderLoader(false));
    }
  };

  return {
    orderState,
    loader,
    bulkUploadOrders,
    submitBulkOrder,
    getOrderDetails,
    requestForCancel,
    progressEvent,
    // verifyArbPay,
    loadFilterData,
    requestForReject,
    requestForPreview,
    loadCheckoutDetails,
    requestForRejectReason,
    requestForReturn,
    requestAgain,
    rejectOrApprove,
    downlaodOrders,
    getReturnDetails,
    returnRejectOrApprove,
  };
};
